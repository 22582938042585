import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    cropperCls: {
      height: 400,
      width: 293,
      objectFit: 'contain',
      borderRadius: 5,
      overflow: 'hidden',
    },
    sliderContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: 15,
      alignItems: 'center',
      flexWrap: 'nowrap',
      padding: '10px 20px',
      marginTop: 10,
      borderRadius: '8px',
      minHeight: '65px',
      '& > *:first-child': {
        marginRight: '20px',
      },
    },
    photoContainer: {
      background: '#1d232c',
      borderRadius: '20px',
      width: 293,
      height: 400,
      marginBottom: 5,
      marginLeft: -4,
    },
  }),
);
