import { Box, Button, Grid, InputLabel, useMediaQuery, useTheme } from '@material-ui/core';

import {
  Backdrop,
  Checkbox,
  CheckBox,
  ContactInput,
  Select,
  SelectArrowDownIcon,
  TextInput,
  Typography,
} from '@room-match/shared-ui-components';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import { useStyles } from './ContactInformation.styles';

const ContactInformation = () => {
  const classes = useStyles();
  const [isChecked, setIsChecked] = useState<boolean>(false);

  return (
    <Grid item className={classes.root}>
      <Grid item>
        <Typography fontSize={25}>Contact Information</Typography>
      </Grid>
      <Grid item>
        <Typography className={classes.heading}>My Details</Typography>
        <Grid item className={classes.column}>
          <Grid item lg={4} md={4} xs={12}>
            <TextInput label={'First Name'} placeholder="Enter first name…" />
          </Grid>
          <Grid item lg={4} md={4} xs={12}>
            <TextInput label={'Last Name'} placeholder="Enter last name…" />
          </Grid>
          <Grid item lg={4} md={4} xs={12}>
            <InputLabel id="representationStatus">Select Gender</InputLabel>
            <Select
              variant="outlined"
              fullWidth
              data={[{ key: 'Male', value: 'Male' }]}
              value="Male"
              IconComponent={SelectArrowDownIcon}
              name="representation_status"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Typography className={classes.heading}>Contact Details</Typography>
        <Grid item className={classes.column}>
          <Grid item lg={6} md={6} xs={12}>
            <TextInput label={'Email Address'} placeholder="Email…" />
          </Grid>
          <Grid item lg={6} md={6} xs={12}>
            <InputLabel id="representationStatus" style={{ marginBottom: '-15px' }}>
              Cell Number
            </InputLabel>
            <ContactInput
              country={'us'}
              name="contact_number"
              variant="outlined"
              placeholder="Type here..."
              dropdownStyle={{ height: '50px' }}
              showLabel={false}
            />
          </Grid>
          <Grid item lg={6} md={6} xs={12}>
            <InputLabel id="representationStatus">Country of Residence</InputLabel>
            <Select
              variant="outlined"
              fullWidth
              data={[{ key: 'United States', value: 'United States' }]}
              value="United States"
              IconComponent={SelectArrowDownIcon}
              name="representation_status"
            />
          </Grid>
          <Grid item lg={6} md={6} xs={12}>
            <InputLabel id="representationStatus">State / Region</InputLabel>
            <Select
              variant="outlined"
              fullWidth
              data={[{ key: 'New Jersey', value: 'New Jersey' }]}
              value="New Jersey"
              IconComponent={SelectArrowDownIcon}
              name="representation_status"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Typography className={classes.heading}>Talent Details</Typography>
        <Grid item className={classes.column}>
          <Grid item lg={6} md={6} xs={12}>
            <InputLabel id="representationStatus">Primary Talent Type</InputLabel>
            <Select
              variant="outlined"
              fullWidth
              data={[{ key: 'Actor', value: 'Actor' }]}
              value="Actor"
              IconComponent={SelectArrowDownIcon}
              name="representation_status"
            />
          </Grid>
          <Grid item lg={6} md={6} xs={12}>
            <InputLabel id="representationStatus">Age Range From</InputLabel>
            <Select
              variant="outlined"
              fullWidth
              data={[{ key: 'Adult', value: 'Adult' }]}
              value="Adult"
              IconComponent={SelectArrowDownIcon}
              name="representation_status"
            />
          </Grid>
          <Grid item lg={6} md={6} xs={12}>
            <InputLabel id="representationStatus">Country of Residence</InputLabel>
            <Select
              variant="outlined"
              fullWidth
              data={[{ key: '32 years old', value: '32 years old' }]}
              value="32 years old"
              IconComponent={SelectArrowDownIcon}
              name="representation_status"
            />
          </Grid>
          <Grid item lg={6} md={6} xs={12}>
            <InputLabel id="representationStatus">Age Range To</InputLabel>
            <Select
              variant="outlined"
              fullWidth
              data={[{ key: '32 years old', value: '32 years old' }]}
              value="32 years old"
              IconComponent={SelectArrowDownIcon}
              name="representation_status"
            />
          </Grid>
        </Grid>
        <Grid item lg={12} md={12} xs={12} className={classes.checkbox}>
          <Checkbox
            value={isChecked}
            onChange={(e) => setIsChecked(e.target.checked)}
            label="I am seeking representation and would like to be contacted by Agents and Managers"
            name="representation"
            labelPlacement="end"
          />
        </Grid>
        <Grid item className={`${isChecked ? classes.disabled : ''} ${classes.independentRow}`}>
          <InputLabel id="representationStatus">Preferred Method of Contact</InputLabel>
          <Select
            variant="outlined"
            data={[{ key: 'Either', value: 'Either' }]}
            value="Either"
            IconComponent={SelectArrowDownIcon}
            name="representation_status"
            disabled={isChecked}
          />
        </Grid>
        <Grid item>
          <Button color="primary" variant="contained" disableElevation className={classes.buttonCls}>
            Save Changes
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ContactInformation;
