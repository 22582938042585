import { Box, Button, Grid, useMediaQuery, useTheme } from '@material-ui/core';

import { Backdrop, Typography } from '@room-match/shared-ui-components';
import { Link } from 'react-router-dom';

import { useStyles } from './Success.styles';

type Props = {
  setActiveStep(step: number): void;
  activeStep: number;
};

const Success: React.FC<Props> = ({ setActiveStep, activeStep }) => {
  const classes = useStyles();

  return (
    <>
      <Grid item className={classes.root}>
        <img className={classes.logoCls} src="casting-logo-no-text.svg" alt="File Logo" />
        <Typography fontSize={40}>Congratulations!</Typography>
        <Typography fontSize={16}>You have succesfully completed the registration process.</Typography>
        <Link to="/talent/dashboard" className={classes.link}>
          <Button color="primary" variant="contained" disableElevation fullWidth className={classes.buttonCls}>
            Go To Dashboard
          </Button>
        </Link>
      </Grid>
    </>
  );
};

export default Success;
