import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      '& > div:last-child': {
        marginTop: 15,
      },
      height: '73vh',
      overflow: 'auto',
      paddingRight: 10,
    },
    termsContainer: {
      maxHeight: 390,
      background: '#18212e',
      padding: '20px 25px',
      borderRadius: 8,
    },
    termsWrap: {
      overflow: 'auto',
      maxHeight: 350,
    },
    sigCanvas: {
      backgroundColor: '#fff',
      marginTop: 12,
      border: '1px solid #282E36',
      cursor: 'crosshair',
      borderRadius: 8,
    },
    signatureContainer: {
      marginTop: 10,
      maxWidth: 320,
      width: '100%',
      background: '#fff',
      height: 110,
      borderRadius: 8,

      [theme.breakpoints.down('xs')]: {
        maxWidth: 'unset',
      },
    },
    buttonCls: {
      borderRadius: 8,
      textTransform: 'unset',
      fontSize: 15,
      width: 220,
      height: 40,
    },
    backBtn: {
      background: '#303946',
      color: '#fff',
      fontSize: 15,
      width: 220,
      height: 40,
      '&:hover': {
        background: '#303946',
      },
    },
    buttonsContainer: {
      display: 'flex',
      gap: 20,
    },
  }),
);
