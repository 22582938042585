import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function BiographyIcon(props: SvgIconProps) {
  return (
    <SvgIcon width="23" height="22" viewBox="0 0 23 22" xmlns="http://www.w3.org/2000/svg" {...props}>
      <defs>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="esrzckmgsa">
          <stop stopColor="#3E8BFF" offset="0%" />
          <stop stopColor="#3E8BFF" offset="100%" />
        </linearGradient>
      </defs>
      <path
        d="M11 0a5.503 5.503 0 0 0-5.5 5.5C5.5 8.535 7.964 11 11 11c3.034 0 5.499-2.465 5.499-5.5S14.034 0 10.999 0zm0 1.571A3.93 3.93 0 0 1 14.927 5.5a3.93 3.93 0 0 1-3.929 3.928A3.93 3.93 0 0 1 7.071 5.5a3.93 3.93 0 0 1 3.928-3.929zM1.57 20.427h9.821a.786.786 0 0 1 0 1.571H.786A.786.786 0 0 1 0 21.213V19.64a7.07 7.07 0 0 1 7.07-7.07h4.322a.786.786 0 0 1 0 1.57H7.071a5.5 5.5 0 0 0-5.5 5.5v.786zm20.676-5.818a.786.786 0 0 0 0-1.111l-1.963-1.964a.786.786 0 0 0-1.11 0l-4.609 4.608a.787.787 0 0 0-.23.522l-.089 2.052a.786.786 0 0 0 .82.819l2.052-.09a.787.787 0 0 0 .522-.229l4.607-4.607zm-6.355 2.428-.038.89.89-.038 3.836-3.836-.851-.852-3.837 3.836zM13.75 22h7.07a.786.786 0 0 0 0-1.571h-7.07a.786.786 0 0 0 0 1.571z"
        fill="url(#esrzckmgsa)"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
}

export default BiographyIcon;
