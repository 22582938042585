import { Grid, styled } from '@material-ui/core';
import { Typography } from '@room-match/shared-ui-components';

export const Container = styled(Grid)({
  background: '#0d1117',
  height: '100%',
  minHeight: '100vh',
  maxWidth: 600,
  margin: 'auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  textAlign: 'center',
  lineHeight: 1,
  gap: 30,
  padding: '60px 30px',
});

export const Logo = styled(Grid)({
  display: 'flex',
  justifyContent: 'center',
  marginBottom: 30,

  '& img': {
    height: 185,
    width: 'auto',
  },
});

export const Text = styled(Typography)({
  lineHeight: 1.2,
});

export const DownloadContainer = styled(Grid)({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  gap: 20,
  '& img': {
    height: 50,
    width: 'auto',
  },
});

export const StepsHeader = styled(Typography)({
  margin: '10px 0',
});
