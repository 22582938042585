import { Box, Grid, InputLabel, TextField } from '@material-ui/core';

import { MinusCircleIcon, Select, SelectArrowDownIcon, TextInput, Typography } from '@room-match/shared-ui-components';
import { useState } from 'react';
import { agencyService } from 'shared/services/agencyService';

import { useStyles } from './ContactInformation.styles';
import { useDebounce } from 'use-debounce';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { getErrorMessage } from '@room-match/shared-utils';
import { ContactInput } from 'shared/elements';

type Props = {
  form: any;
  representatives: any[];
  setRepresentatives(representatives: any[]): void;
  validate: () => void;
};

const { searchAgency } = agencyService();
const ContactInformation: React.FC<Props> = ({ form, representatives, setRepresentatives, validate }) => {
  const [query, setQuery] = useState<string>('');

  const [queryValue] = useDebounce(query, 100);
  const [representativesLabel, setRepresentativesLabel] = useState<string[]>([]);

  const { data: agencies, isLoading: isAgencyLoading } = searchAgency('United States', queryValue);
  const classes = useStyles();

  const handleRemoveRepresentative = (v: string) => {
    const filteredRepresentatives = representatives.filter((k) => k != v);
    setRepresentatives(filteredRepresentatives);
    const filteredRepresentativesLabel = representativesLabel.filter((k) => k != v);
    setRepresentativesLabel(filteredRepresentativesLabel);
  };

  const agenciesData = agencies?.data.map((i) => i);

  return (
    <Box component="div" className={classes.root}>
      <Grid item className={classes.header}>
        <Typography fontSize={24}>Contact Information</Typography>
      </Grid>
      <Grid item className={classes.form}>
        <Grid item style={{ marginBottom: 5 }}>
          <InputLabel id="representationStatus" style={{ marginBottom: '-15px' }}>
            Cell Number
          </InputLabel>
          <ContactInput
            handleCodeChange={(val: any) => {
              form.setFieldValue('country_code', val.countryCode);
            }}
            country={(form.values.country_code || 'us').toLowerCase()}
            name="contact_no"
            variant="outlined"
            placeholder="Type here..."
            dropdownStyle={{ height: '50px' }}
            showLabel={false}
            errorMessage={getErrorMessage(form.touched.contact_no, form.errors.contact_no)}
            onChange={(e) => {
              if (form.errors.contact_no && !form.touched.contact_no) {
                form.setFieldTouched('contact_no');
                form.validateField('contact_no');
              }
              return form.handleChange(e);
            }}
            onBlur={() => {
              validate();
              return form.handleBlur;
            }}
            value={form.values.contact_no}
          />
        </Grid>
        <Grid item>
          <InputLabel id="representationStatus">Representation Status</InputLabel>
          <Select
            variant="outlined"
            fullWidth
            data={[
              { key: 'Agency Representation', value: 'agency_representation' },
              { key: 'Non-Represented', value: 'Non-Represented' },
            ]}
            value={form.values.representation}
            onChange={(e: any) => {
              form.setFieldValue('representation', e.target.value);
              if (e.target.value === 'Non-Represented') setRepresentatives([]);
            }}
            IconComponent={SelectArrowDownIcon}
            name="representation"
          />
        </Grid>
        {form.values.representation === 'Non-Represented' ? (
          ''
        ) : (
          <Grid item>
            <InputLabel>Add Representatives</InputLabel>
            <Autocomplete
              id="multiple-tags"
              multiple
              value={representatives}
              className={classes.autocompleteFix}
              options={agenciesData || []}
              getOptionLabel={(agenciesData) => agenciesData.attributes.name}
              getOptionSelected={(option, value) => option === value}
              fullWidth
              renderTags={() => null}
              onChange={(e, newValue: any[]) => {
                if (newValue) {
                  setRepresentatives(newValue);
                }
              }}
              renderOption={(option: any) => {
                const { name, company_type, country, company_logo } = option.attributes;

                return (
                  <Grid
                    container
                    spacing={2}
                    style={{ margin: 5, display: 'flex', alignItems: 'center', gap: '.5em', flexWrap: 'nowrap' }}
                  >
                    <Grid item xs={1} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <img
                        style={{ borderRadius: 10 }}
                        loading="lazy"
                        width="37"
                        src={company_logo || `https://ui-avatars.com/api/?background=random&name=${name}&length=1`}
                        alt=""
                      />
                    </Grid>
                    <Grid item xs={11}>
                      <Grid item xs={12} style={{ fontSize: 15 }}>
                        {name}
                      </Grid>
                      <Grid item xs={12} style={{ color: '#93a1b6', fontSize: 14 }}>
                        {company_type ? `${company_type} - ${country}` : country}
                      </Grid>
                    </Grid>
                  </Grid>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={'Type / Search Agency…'}
                  variant="outlined"
                  margin="normal"
                  onChange={(e) => {
                    setQuery(e.target.value);
                    form.handleChange();
                  }}
                />
              )}
            />
            {/* <TextInput label={'Add Representatives'} placeholder="Type / Search Agency…" /> */}
          </Grid>
        )}

        <Grid item>
          {representatives.map((i) => (
            <Grid item className={classes.chips}>
              <Typography fontSize={14}>{i.attributes.name}</Typography>
              <MinusCircleIcon onClick={() => handleRemoveRepresentative(i)} />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
};

export default ContactInformation;
