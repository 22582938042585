import { combineReducers } from '@reduxjs/toolkit';
import appReducer from './slicers/app.slicer';
import themeReducer from './slicers/theme.slicer';
import userReducer from './slicers/user.slicer';

const rootReducer = combineReducers({
  theme: themeReducer,
  user: userReducer,
  app: appReducer,
});

export default rootReducer;
