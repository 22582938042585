import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

const GoogleOutlinedIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fillRule="nonzero" fill="none">
        <path
          d="M19.245 8.261h-8.158a.652.652 0 0 0-.652.652v2.606c0 .36.292.652.652.652h4.594a6.133 6.133 0 0 1-2.64 3.094L15 18.655c3.142-1.816 5-5.005 5-8.575a6.61 6.61 0 0 0-.112-1.28.655.655 0 0 0-.643-.539z"
          fill="#167EE6"
        />
        <path
          d="M10 16.087a6.086 6.086 0 0 1-5.265-3.046l-3.39 1.954A9.987 9.987 0 0 0 15 18.66v-.004l-1.959-3.391a6.041 6.041 0 0 1-3.041.822z"
          fill="#12B347"
        />
        <path
          d="M15 18.66v-.004l-1.959-3.391a6.042 6.042 0 0 1-3.041.822V20a9.983 9.983 0 0 0 5-1.34z"
          fill="#0F993E"
        />
        <path
          d="M3.913 10c0-1.108.302-2.145.822-3.04l-3.39-1.955A9.896 9.896 0 0 0 0 10c0 1.819.488 3.526 1.344 4.995l3.391-1.954A6.042 6.042 0 0 1 3.913 10z"
          fill="#FFD500"
        />
        <path
          d="M10 3.913c1.466 0 2.813.521 3.865 1.388a.65.65 0 0 0 .874-.04l1.846-1.846a.657.657 0 0 0-.037-.961A9.97 9.97 0 0 0 10 0a9.987 9.987 0 0 0-8.656 5.005l3.391 1.954A6.086 6.086 0 0 1 10 3.913z"
          fill="#FF4B26"
        />
        <path
          d="M13.865 5.3a.65.65 0 0 0 .874-.039l1.846-1.846a.657.657 0 0 0-.037-.961A9.97 9.97 0 0 0 10 0v3.913c1.466 0 2.813.521 3.865 1.388z"
          fill="#D93F21"
        />
      </g>
    </SvgIcon>
  );
};

export default GoogleOutlinedIcon;
