import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      '& > div:not(:first-child)': {
        marginTop: 10,
      },
      '& .MuiOutlinedInput-root': {
        fontSize: 14,
        background: '#0b0f14',
        borderRadius: 8,
        overflowY: 'auto',
      },

      '& .MuiFormLabel-root': {
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: '13px',
        letterSpacing: '1px',
        alignItems: 'center',
        color: '#c2ccd8',
        marginBottom: 5,

        '& .MuiBox-root': {
          padding: '2px 4px 1px 5px',
          border: '1px solid',
          borderRadius: '100%',
          fontSize: '12px',
        },
      },

      '& .MuiSelect-iconOutlined': {
        right: '0',
        top: '20px',
      },

      '& .MuiSelect-iconOpen': {
        top: 0,
        right: '13px',
      },

      '& textarea': {
        borderRadius: 8,
      },
    },

    buttonCls: {
      borderRadius: 8,
      textTransform: 'unset',

      fontSize: 15,
      width: 220,
      height: 40,
    },
    backBtn: {
      background: '#303946',
      color: '#fff',
      fontSize: 15,
      width: 220,
      height: 40,
      '&:hover': {
        background: '#303946',
      },
    },
    checkboxContainer: {
      background: '#0e1319',
      border: '1px solid #2d343e',
      padding: 20,
      borderRadius: 8,
      marginTop: 7,
      marginBottom: 20,
      flexDirection: 'column',
      '& .MuiTypography-root': {
        fontSize: 14,
        marginTop: -2,
      },
    },
    buttonsContainer: {
      display: 'flex',
      gap: 20,
    },
  }),
);
