import { Box, Button, Grid, InputAdornment, InputLabel, useMediaQuery, useTheme } from '@material-ui/core';

import {
  Backdrop,
  InputPassword,
  PasswordIconTwoTone,
  PasswordStrength,
  TextInput,
  Typography,
} from '@room-match/shared-ui-components';
import { Link } from 'react-router-dom';

import { useStyles } from './ChangePassword.styles';

type Props = {
  tab?: string;
};

const ChangePassword: React.FC<Props> = ({ tab }) => {
  const classes = useStyles();

  return (
    <Grid item className={classes.root}>
      <Grid item style={{ marginBottom: 15 }}>
        <Typography fontSize={25}>Change Password</Typography>
      </Grid>
      <Grid item className={classes.row}>
        <Grid item>
          <Grid item>
            <InputLabel>Current Password</InputLabel>
            <InputPassword
              className={classes.input}
              fullWidth
              name="user.password"
              variant="outlined"
              placeholder="Enter current password…"
              InputLabelProps={{ shrink: true }}
              inputProps={{ tabIndex: 2 }}
              style={{ marginTop: 10 }}
              onCopy={(e) => {
                e.preventDefault();
                return false;
              }}
              onPaste={(e) => {
                e.preventDefault();
                return false;
              }}
            />
          </Grid>
          <Grid item style={{ marginTop: 10 }}>
            <InputLabel>New Password</InputLabel>
            <InputPassword
              className={classes.input}
              fullWidth
              name="user.password"
              variant="outlined"
              placeholder="Enter new password…"
              InputLabelProps={{ shrink: true }}
              inputProps={{ tabIndex: 2 }}
              style={{ marginTop: 10 }}
              onCopy={(e) => {
                e.preventDefault();
                return false;
              }}
              onPaste={(e) => {
                e.preventDefault();
                return false;
              }}
            />
          </Grid>
          <Grid item style={{ marginTop: 10 }}>
            <InputLabel>Confirm New Password</InputLabel>
            <InputPassword
              className={classes.input}
              fullWidth
              name="user.password"
              variant="outlined"
              placeholder="Enter new password…"
              InputLabelProps={{ shrink: true }}
              inputProps={{ tabIndex: 2 }}
              style={{ marginTop: 10 }}
              onCopy={(e) => {
                e.preventDefault();
                return false;
              }}
              onPaste={(e) => {
                e.preventDefault();
                return false;
              }}
            />
          </Grid>
        </Grid>
        <Grid item className={classes.password__strength}>
          <PasswordStrength password="" />
        </Grid>
      </Grid>
      <Grid item>
        <Button color="primary" variant="contained" disableElevation className={classes.buttonCls}>
          Save Changes
        </Button>
      </Grid>
    </Grid>
  );
};

export default ChangePassword;
