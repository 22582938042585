import { useMutation, useQuery } from 'react-query';
import { talentDao } from 'shared/dao/talentDao';
import {
  ITalentResumeResponsePayload,
  ITalentSkillResponsePayload,
  ITalentStatisticsResponsePayload,
  ITalentUpdatePayload,
} from 'shared/interfaces/ITalent';
// import { ITalentUpdatePayload } from 'shared/interfaces/ITalent';
import { IAxiosErrorResponse } from 'shared/interfaces/utils/IAxios';

const {
  updateTalent: updateTalentDao,
  getStatistics: getStatisticsDao,
  getResume: getResumeDao,
  getSkills: getSkillsDao,
} = talentDao();
export const talentService = () => {
  const updateTalent = () => {
    return useMutation((payload: ITalentUpdatePayload) => updateTalentDao(payload));
  };

  const getStatistics = () => {
    return useQuery<ITalentStatisticsResponsePayload>(['talents/statistics'], () => getStatisticsDao());
  };

  const getResume = () => {
    return useQuery<ITalentResumeResponsePayload>(['talents/resume'], () => getResumeDao());
  };

  const getSkills = () => {
    return useQuery<ITalentSkillResponsePayload>(['talents/skills'], () => getSkillsDao());
  };

  return {
    getSkills,
    getResume,
    updateTalent,
    getStatistics,
  };
};
