import { Box, Button, Grid, InputLabel, useMediaQuery, useTheme } from '@material-ui/core';

import {
  Backdrop,
  Checkbox,
  CheckBox,
  Select,
  SelectArrowDownIcon,
  TextInput,
  Typography,
} from '@room-match/shared-ui-components';
import { Stepper } from 'components';
import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import SignatureCanvas from 'react-signature-canvas';
import { ICheckInMarkPayload } from 'shared/interfaces/ICheckIn';
import { uploadService } from 'shared/services/uploadService';
import { FormikProps, useFormik } from 'formik';
import { base64ToBlob, blobToBase64 } from 'base64-blob';
import { getImageMetaData } from 'shared/utils/getImageMetaData';

import { useStyles } from './Terms.styles';
import { checkInService } from 'shared/services/checkInService';
import { MdOpenInFull } from 'react-icons/md';

type Props = {
  data?: any;
  setActiveStep(steps: number): void;
  activeStep: number;
  sessionId: string;
  nda?: string;
};

const { getSingleMediaAsBlob, uploadMedia } = uploadService();
const { markAsCheckIn } = checkInService();

const Terms: React.FC<Props> = ({ data, setActiveStep, activeStep, sessionId, nda }) => {
  const classes = useStyles();
  const [signature, setSignature] = useState<string | undefined>(undefined);
  const { mutateAsync: mutateAsyncUploadMedia } = uploadMedia();
  const { mutate: mutateMarkCheckIn } = markAsCheckIn();
  const [disable, setdisable] = useState(true);
  const [saving, setSaving] = useState(false);

  const sigRef = useRef<SignatureCanvas | null>(null);

  const handleSubmit = async (values: ICheckInMarkPayload) => {
    setSaving(true);
    if (sigRef.current) {
      if (signature) {
        const convertedFile = await base64ToBlob(signature ? signature : '');
        const { width, height } = await getImageMetaData(convertedFile);

        const formData = new FormData();
        formData.append('attachment', convertedFile);
        formData.append('file_name', 'signature');
        formData.append('file_width', width.toString());
        formData.append('file_height', height.toString());
        formData.append('file_type', 'image');
        formData.append('file_size', convertedFile.size.toString());

        const uploadedSignature = await mutateAsyncUploadMedia({ formData });

        if (uploadedSignature) {
          const mediaId = uploadedSignature.data.id;
          mutateMarkCheckIn(
            { payload: { signature_medium_id: mediaId }, sessionId: sessionId },
            {
              onSuccess: (values) => {
                setActiveStep(activeStep + 1);
              },
              onError: (e) => {
                console.error(e);
              },
              onSettled: () => setSaving(false),
            },
          );
        }
      }
    }
  };

  const initialValues: ICheckInMarkPayload = {
    signature_medium_id: '',
  };

  const form: FormikProps<ICheckInMarkPayload> = useFormik({
    initialValues,
    onSubmit: (values) => {
      handleSubmit(values);
    },
    enableReinitialize: true,
  });

  const { data: signatureData } = getSingleMediaAsBlob(form.values.signature_medium_id);

  useEffect(() => {
    let unmounted = false;
    if (signatureData) {
      (async () => {
        if (!unmounted) {
          const base64Signature = await blobToBase64(signatureData);
          setSignature(base64Signature);
        }
      })();
    }

    return () => {
      unmounted = true;
    };
  }, [signatureData]);

  const handleClearSignature = () => {
    if (sigRef.current) {
      setdisable(true);
      sigRef.current.clear();
    }
  };

  useEffect(() => {
    if (sigRef.current) {
      sigRef.current.fromDataURL(sigRef.current.toDataURL());
    }
  }, [sigRef, data]);

  const handlEnd = () => {
    if (!!!sigRef.current) return;
    setdisable(false);
    setSignature(sigRef.current.toDataURL());
  };
  return (
    <>
      <Grid item className={classes.root}>
        <Grid item className={classes.termsContainer}>
          <Box component="div" className={classes.termsWrap}>
            <Typography style={{ marginBottom: 15 }}>Terms of Audition</Typography>
            <Typography fontSize={14}>{nda}</Typography>
          </Box>
        </Grid>
        <Grid item style={{ marginTop: 20 }}>
          <Typography color="primary" fontSize={15}>
            Please sign below
          </Typography>
          <SignatureCanvas
            onEnd={handlEnd}
            ref={(ref) => (sigRef.current = ref)}
            penColor="#000"
            canvasProps={{ width: 380, height: 180, className: classes.sigCanvas }}
          />
        </Grid>
        <Grid item>
          <Button onClick={() => handleClearSignature()}>Clear Signature</Button>
        </Grid>
        <Grid item className={classes.buttonsContainer}>
          <Button
            variant="contained"
            fullWidth
            className={`${classes.buttonCls} ${classes.backBtn}`}
            onClick={() => setActiveStep(activeStep - 1)}
          >
            Go Back
          </Button>
          <Button
            disabled={disable || saving}
            variant="contained"
            color="primary"
            fullWidth
            className={classes.buttonCls}
            onClick={() => form.handleSubmit()}
          >
            {saving ? 'Saving...' : 'Finish'}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default Terms;
