import { Box, InputLabel, TextareaAutosize, TextFieldProps, Tooltip } from '@material-ui/core';
import React from 'react';
import { Input } from '../Input';

import { useStyles } from './TextInput.styles';

type Props = {
  errorMessage?: string;
  label?: string;
  labelId?: any;
  tooltip?: boolean;
  tooltipTitle?: any;
  name?: string;
  placeholder?: string;
  textArea?: boolean;
  textAreaMinRows?: number;
} & TextFieldProps;
const FormInput: React.FC<Props> = ({
  errorMessage,
  label,
  labelId,
  tooltip,
  tooltipTitle,
  name,
  placeholder,
  type,
  textArea,
  textAreaMinRows,
  ...props
}) => {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <InputLabel id={labelId}>
        {label}
        {tooltip ? (
          <Tooltip title={tooltipTitle?.toString()}>
            <Box component="span">?</Box>
          </Tooltip>
        ) : (
          ''
        )}
      </InputLabel>
      {textArea ? (
        <TextareaAutosize
          minRows={textAreaMinRows}
          placeholder="Type here..."
          className={`${classes.textAreaCls} ${label ? classes.withLabel : ''}`}
        />
      ) : (
        <Input
          variant="outlined"
          // autoFocus
          name={name}
          type={type ? type : 'text'}
          fullWidth
          margin={'normal'}
          InputLabelProps={{ shrink: true }}
          // inputProps={{ tabIndex: 1 }}
          placeholder={placeholder}
          errorMessage={errorMessage}
          {...props}
        />
      )}
    </Box>
  );
};

export default FormInput;
