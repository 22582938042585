import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',

      '& .MuiOutlinedInput-root': {
        height: '50px!important',
        fontSize: 14,
        background: '#0e1319',
        maxWidth: 390,
      },

      '& .MuiFormLabel-root': {
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: '13px',
        letterSpacing: '1px',
        alignItems: 'center',
        color: '#c2ccd8',

        '& .MuiBox-root': {
          padding: '2px 4px 1px 5px',
          border: '1px solid',
          borderRadius: '100%',
          fontSize: '12px',
        },
      },

      '& .MuiSelect-iconOutlined': {
        right: '0',
        top: '22px',
      },

      '& .MuiSelect-iconOpen': {
        top: '2px',
        right: '13px',
      },
    },
    noLabelFix: {
      marginTop: 0,
    },
    selectNoLabelFix: {
      marginTop: '-17px',
    },
    header: {
      marginBottom: 60,
      textAlign: 'center',
    },
    typeSelection: {
      display: 'flex',
      gap: 15,
      marginTop: 20,
      flexWrap: 'wrap',
      alignItems: 'center',
      justifyContent: 'center',
    },
    selection: {
      background: '#181f2a',
      width: 200,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      height: 62,
      borderRadius: 8,
      cursor: 'pointer',
      opacity: 0.5,
      border: '1px solid #181f2a',

      '&.active': {
        opacity: 1,
        border: '1px solid #3e8bff',
      },

      '&:hover': {
        opacity: 1,
        border: '1px solid #3e8bff',
      },
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      gap: 15,
      maxWidth: 329,
      margin: '0 auto',
    },
    chips: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      background: '#28313f',
      height: 50,
      borderRadius: 5,
      padding: '0 20px',

      '& .MuiSvgIcon-root': {
        cursor: 'pointer',
        fontSize: 18,
        '& path': {
          fill: '#d83032',
        },
      },
    },
    threeColumns: {
      display: 'flex',
      gap: '0.5em',
      '& > div': {
        width: 105,
      },
    },
    checkboxContainer: {
      '& .MuiTypography-root': {
        fontSize: 14,
        marginTop: '-2px',
      },
      '& .MuiFormControlLabel-root': {
        marginTop: -4,
      },
    },
  }),
);
