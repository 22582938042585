import { Grid, MobileStepper } from '@material-ui/core';
import { mergeClasses } from '@material-ui/styles';
import { Button } from '@material-ui/core';
import React, { useState } from 'react';
import { useStyles } from './Stepper.styles';

type Props = {
  steps: number;
  backButton?: React.ReactNode;
  nextButton?: React.ReactNode;
  active?: any;
};
const Stepper: React.FC<Props> = ({ steps, backButton, nextButton, active }) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(active);

  const handleNext = () => {
    setActiveStep((prevActiveStep: number) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep: number) => prevActiveStep - 1);
  };
  return (
    <MobileStepper
      className={classes.root}
      variant="progress"
      position="static"
      activeStep={active}
      backButton={backButton}
      nextButton={nextButton}
      steps={steps}
    />
  );
};

export default Stepper;
