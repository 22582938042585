import { FormikProps, useFormik } from 'formik';
import { IStatistics, ITalentUpdatePayload } from 'shared/interfaces/ITalent';
import * as yup from 'yup';

type FormProps = {
  onSubmit: (values: ITalentUpdatePayload) => void;
  data: any;
};
export const usePersonalForm = ({ onSubmit, data }: FormProps) => {
  const initialValues: ITalentUpdatePayload = {
    sag_id: data ? data.attributes.sag_id : '',
    union: data ? data.attributes.union : '',
    statistics: {
      region: data && data?.attributes.statistics ? data?.attributes.statistics.region : 'United States',
      adult_minor: data && data?.attributes.statistics ? data?.attributes.statistics.adult_minor : 'Adult',
      metric_system: data && data?.attributes.statistics ? data?.attributes.statistics.metric_system : 'Imperial',
      gender: data && data?.attributes.statistics ? data?.attributes.statistics.gender : '',
      hair_color: data && data?.attributes.statistics ? data?.attributes.statistics.hair_color : '',
      hair_length: data && data?.attributes.statistics ? data?.attributes.statistics.hair_length : '',
      eye_color: data && data?.attributes.statistics ? data?.attributes.statistics.eye_color : '',
      height: data && data?.attributes.statistics ? data?.attributes.statistics.height : '',
      hat: data && data?.attributes.statistics ? data?.attributes.statistics.hat : '',
      gloves: data && data?.attributes.statistics ? data?.attributes.statistics.gloves : '',
      weight: data && data?.attributes.statistics ? data?.attributes.statistics.weight : '',
      waist: data && data?.attributes.statistics ? data?.attributes.statistics.waist : '',
      coat_size: data && data?.attributes.statistics ? data?.attributes.statistics.coat_size : '',
      dress_size: data && data?.attributes.statistics ? data?.attributes.statistics.dress_size : '',
      pants_size: data && data?.attributes.statistics ? data?.attributes.statistics.pants_size : '',
      neck_size: data && data?.attributes.statistics ? data?.attributes.statistics.neck_size : '',
      sleeves: data && data?.attributes.statistics ? data?.attributes.statistics.sleeves : '',
      chest_size: data && data?.attributes.statistics ? data?.attributes.statistics.chest_size : '',
      bust_size: data && data?.attributes.statistics ? data?.attributes.statistics.bust_size : '',
      bra_size: data && data?.attributes.statistics ? data?.attributes.statistics.bra_size : '',
      collar_size: data && data?.attributes.statistics ? data?.attributes.statistics.collar_size : '',
      inside_leg: data && data?.attributes.statistics ? data?.attributes.statistics.inside_leg : '',
      outside_leg: data && data?.attributes.statistics ? data?.attributes.statistics.outside_leg : '',
      shoe_size: data && data?.attributes.statistics ? data?.attributes.statistics.shoe_size : '',
      hip_size: data && data?.attributes.statistics ? data?.attributes.statistics.hip_size : '',
      ethnicity: data && data?.attributes.statistics ? data?.attributes.statistics.ethnicity : [],
      other_talent_types: data && data?.attributes.statistics ? data?.attributes.statistics.other_talent_types : [],
    },
  };

  const form: FormikProps<ITalentUpdatePayload> = useFormik({
    initialValues,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });

  return {
    form,
  };
};
