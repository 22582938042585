import { Box, CircularProgress } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { selectUserState } from 'shared/redux/slicers/user.slicer';
import { tokenService } from 'shared/services/tokenService';

function CheckInRedirect() {
  const { projectId } = useParams() as { projectId: string };
  const { isAuthenticated } = tokenService();
  const history = useHistory();

  useEffect(() => {
    if (isAuthenticated()) {
      history.push(`/talent/check_in/${projectId}`);
    } else {
      history.push(`/new_talent/${projectId}`);
    }
  }, [isAuthenticated, projectId, history]);

  return (
    <Box style={{ width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <CircularProgress style={{ width: 30, height: 30, marginRight: 10 }} />
      Redirecting...
    </Box>
  );
}

export default CheckInRedirect;
