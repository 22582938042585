import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',

      '& > div:not(:last-child)': {
        marginBottom: 20,
      },

      '& .MuiOutlinedInput-root': {
        height: '50px!important',
        fontSize: 14,
        background: '#0e1319',
      },

      '& .MuiFormLabel-root': {
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: '13px',
        letterSpacing: '1px',
        alignItems: 'center',
        color: '#c2ccd8',

        '& .MuiBox-root': {
          padding: '2px 4px 1px 5px',
          border: '1px solid',
          borderRadius: '100%',
          fontSize: '12px',
        },
      },
    },
    input: {
      background: '#0e1319',
      borderRadius: 8,
      fontWeight: 400,
      '& input': {
        fontSize: 14,
      },
      '& .MuiInputBase-root': {
        paddingLeft: 5,
        borderRadius: 8,
      },
    },
    startIcon: {
      fontSize: 20,
      marginRight: 5,
    },
    row: {
      display: 'flex',
      gap: '3.5em',

      '& > div:first-child > div': {
        width: 424,
        [theme.breakpoints.down('md')]: {
          width: '100%',
        },
      },

      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        gap: 0,
      },
    },

    password__strength: {
      '& *': {
        fontSize: '17px!important',
      },
      '& h6': {
        marginTop: '17px!important',
      },
      '& p': {
        marginTop: 20,
      },
    },
    buttonCls: {
      marginTop: -10,
      height: '50px !important',
      borderRadius: 8,
      textTransform: 'unset',
      textDecoration: 'none!important',
      maxWidth: 424,
      width: '100%',

      [theme.breakpoints.down('md')]: {
        marginTop: 10,
      },
    },
  }),
);
