import { Login } from 'pages/Login';
import { Signup } from 'pages/Signup';
import { SendVerification } from 'pages/SendVerification';
import { Registration } from 'pages/Registration';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ROUTES } from 'shared/constants/ROUTES';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import Download from 'pages/Download';
import NewTalent from 'pages/NewTalent/NewTalent';
import CheckInRedirect from 'pages/CheckInRedirect/CheckInRedirect';

const AsyncDashboard = React.lazy(() => import('pages/Dashboard'));
const AsyncSettings = React.lazy(() => import('pages/Settings'));
const AsyncProfiles = React.lazy(() => import('pages/Profiles'));
const AsyncCheckIn = React.lazy(() => import('pages/CheckIn'));
const AsyncSelfTapes = React.lazy(() => import('pages/SelfTapes'));
const AsyncMyInfo = React.lazy(() => import('pages/MyInfo'));
const AsyncMedia = React.lazy(() => import('pages/Media'));

const Routes = () => {
  return (
    <>
      <Switch>
        <Route exact path={ROUTES.ROOT} component={() => <Redirect to={ROUTES.LOGIN} />} />
        <PublicRoute exact path={ROUTES.LOGIN} component={Login} />
        <PublicRoute exact path={ROUTES.SIGNUP} component={Signup} />
        <PrivateRoute exact path={ROUTES.TALENT.DASHBOARD} component={AsyncDashboard} />
        <PrivateRoute exact path={`${ROUTES.TALENT.SETTINGS}/:tab`} component={AsyncSettings} />
        <PrivateRoute exact path={`${ROUTES.TALENT.PROFILES}/:profileId?/:tab?`} component={AsyncProfiles} />
        <PrivateRoute exact path={`${ROUTES.TALENT.CHECKIN}/:projectId?`} component={AsyncCheckIn} />
        <PrivateRoute exact path={ROUTES.TALENT.SELFTAPES} component={AsyncSelfTapes} />
        <PrivateRoute exact path={`${ROUTES.TALENT.MYINFO}/:tab`} component={AsyncMyInfo} />
        <PrivateRoute exact path={`${ROUTES.TALENT.MEDIA}/:tab`} component={AsyncMedia} />
        <Route exact path={ROUTES.DOWNLOAD} component={Download} />
        <Route path={ROUTES.SEND_VERIFICATION} component={SendVerification} />
        <Route path={ROUTES.REGISTRATION} component={Registration} />
        <Route path={`${ROUTES.NEW_TALENT}/:projectId`} component={NewTalent} />
        <Route path={`${ROUTES.CHECKIN_REDIRECT}/:projectId`} component={CheckInRedirect} />
        <Route path="*" exact component={() => <h1>Not Found</h1>} />
      </Switch>
    </>
  );
};

export default Routes;
