import { Box, Grid, InputLabel } from '@material-ui/core';

import {
  ContactInput,
  MinusCircleIcon,
  Select,
  SelectArrowDownIcon,
  TextInput,
  Typography,
} from '@room-match/shared-ui-components';

import { useStyles } from './GuardianContactInformation.styles';

const GuardianContactInformation = () => {
  const classes = useStyles();

  const chips = ['Smith Agency, Los Angeles'];

  return (
    <Box component="div" className={classes.root}>
      <Grid item className={classes.header}>
        <Typography fontSize={24}>Guardian Contact Information</Typography>
      </Grid>
      <Grid item className={classes.form}>
        <Grid item>
          <TextInput label={'First name'} placeholder="Enter first name…" />
        </Grid>
        <Grid item>
          <TextInput label={'Last name'} placeholder="Enter last name…" />
        </Grid>
        <Grid item>
          <TextInput label={'Guardin Email Address'} placeholder="Enter email…" />
        </Grid>
        <Grid item style={{ marginBottom: 5 }}>
          <InputLabel id="representationStatus" style={{ marginBottom: '-15px' }}>
            Cell Number
          </InputLabel>
          <ContactInput
            country={'us'}
            name="contact_number"
            variant="outlined"
            placeholder="Type here..."
            dropdownStyle={{ height: '50px' }}
            showLabel={false}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default GuardianContactInformation;
