import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function ProjectsIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg width="24" height="22" viewBox="0 0 24 22" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M21.923 4.616V3A2.08 2.08 0 0 0 19.846.923h-9.72L9.763.331A.692.692 0 0 0 9.173 0H2.077A2.08 2.08 0 0 0 0 2.077v17.538a2.08 2.08 0 0 0 2.077 2.077h19.846A2.08 2.08 0 0 0 24 19.615V6.693a2.08 2.08 0 0 0-2.077-2.077zm-2.077-2.308c.382 0 .692.31.692.692v1.615h-8.15l-.927-1.513-.487-.794h8.872zm2.77 17.307c0 .382-.311.693-.693.693H2.077a.693.693 0 0 1-.692-.693V2.077c0-.382.31-.692.692-.692h6.708l.363.592 1.335 2.18.927 1.513c.126.205.35.33.59.33h9.923c.382 0 .692.311.692.693v12.922z"
          fill="#838D9C"
          fillRule="nonzero"
        />
      </svg>
    </SvgIcon>
  );
}

export default ProjectsIcon;
