import { Box, Button, Grid, InputLabel } from '@material-ui/core';

import {
  ClickIcon,
  ContactInput,
  MinusCircleIcon,
  Select,
  SelectArrowDownIcon,
  TextInput,
  Typography,
} from '@room-match/shared-ui-components';
import { useState, useRef, useEffect } from 'react';

import FileUpload from 'components/FileUpload/FileUpload';

import { useStyles } from './ProfileImageAdult.styles';
import { ProfileImageCropper } from './ProfileImageCropper';

const imageMimeType = /image\/(png|jpg|jpeg)/i;

type Props = {
  onFileSelected: (
    name: string,
    type: string,
    image: string,
    file?: File,
    imageAttr?: { width: number; height: number },
  ) => void;
};

const ProfileImageAdult: React.FC<Props> = ({ onFileSelected }) => {
  const classes = useStyles();
  const [isFileDialogOpen, setIsFileDialogOpen] = useState<boolean>(false);
  const [file, setFile] = useState<any>(null);
  const [fileDataURL, setFileDataURL] = useState(null);
  const [cropper, setCropper] = useState<any>(null);
  const [cropData, setCropData] = useState<string>('/no-image-placeholder.svg');

  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);

  const inputFile = useRef<HTMLInputElement>(null);

  const handleOpenFileWindow = () => {
    if (inputFile.current) {
      inputFile.current.click();
    }
  };

  const changeHandler = (e: any) => {
    const file = e.target.files[0];
    if (!file.type.match(imageMimeType)) {
      alert('Image mime type is not valid');
      return;
    }
    setFile(file);

    let fileReader: any,
      isCancel = false;
    if (file) {
      fileReader = new FileReader();
      const image = new Image();
      fileReader.onload = (e: any) => {
        const { result } = e.target;

        // image.onload = function () {
        onFileSelected(file.name, file.type, fileReader.result as string, file, {
          width: image.width,
          height: image.height,
        });
        image.src = fileReader.result as string;
        // };
        setFileDataURL(result);
      };
      fileReader.readAsDataURL(file);
    }
    return () => {
      isCancel = true;
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort();
      }
    };
  };

  // useEffect(() => {
  //   let fileReader: any,
  //     isCancel = false;
  //   if (file) {
  //     fileReader = new FileReader();
  //     fileReader.onload = (e: any) => {
  //       const { result } = e.target;
  //       if (result && !isCancel) {
  //         const image = new Image();

  //         image.onload = function () {
  //           onFileSelected(file.name, file.type, fileReader.result as string, file, {
  //             width: image.width,
  //             height: image.height,
  //           });
  //         };
  //         setFileDataURL(result);
  //       }
  //     };
  //     fileReader.readAsDataURL(file);
  //   }
  //   return () => {
  //     isCancel = true;
  //     if (fileReader && fileReader.readyState === 1) {
  //       fileReader.abort();
  //     }
  //   };
  // }, [file, onFileSelected]);

  const getCropData = () => {
    if (typeof cropper !== 'undefined') {
      setCropData(cropper.getCroppedCanvas().toDataURL('image/jpeg', 0.9));
    }
  };

  useEffect(() => {
    setCropData(fileDataURL ? fileDataURL : '');
  }, [fileDataURL]);

  return (
    <Box component="div" className={classes.root}>
      <Grid item className={classes.header}>
        <Typography fontSize={24}>Profile Image</Typography>
      </Grid>
      <Grid item className={classes.form}>
        <div style={{ height: 0, width: 0, overflow: 'hidden' }}>
          <input
            ref={inputFile}
            id="contained-input-file"
            type="file"
            accept="image/*"
            onClick={(e) => {
              e.stopPropagation();
            }}
            onChange={(e) => {
              changeHandler(e);
            }}
          />
        </div>
        <Box component="div" className={classes.imgContainer}>
          {fileDataURL === null ? (
            ''
          ) : (
            <ProfileImageCropper
              src={fileDataURL ? fileDataURL : ''}
              cropper={cropper}
              setCropper={setCropper}
              handleCrop={getCropData}
              setHeight={setHeight}
              setWidth={setWidth}
            />
          )}
          {/* <img className={classes.img} src={fileDataURL ? fileDataURL : ''}></img> */}
        </Box>
        {fileDataURL === null ? (
          ''
        ) : (
          <Box component="div" className={classes.tooltipContainer}>
            <ClickIcon />
            Use your cursor / hand to drag & reposition inside the frame
          </Box>
        )}

        <Button className={classes.btn} onClick={handleOpenFileWindow}>
          Upload Image
        </Button>
      </Grid>
    </Box>
  );
};

export default ProfileImageAdult;
