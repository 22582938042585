import { Box, Button, CircularProgress, Grid, InputLabel, TextField, useMediaQuery, useTheme } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import { Backdrop, Select, SelectArrowDownIcon, Typography } from '@room-match/shared-ui-components';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { IAuditionScheduleCreatePayload } from 'shared/interfaces/IAuditionSchedule';
import { ICastingRole, ICastingRoleAttributes } from 'shared/interfaces/ICastingRole';
import { IProfile } from 'shared/interfaces/IProfile';
import { auditionScheduleService } from 'shared/services/auditionScheduleService';
import { profileService } from 'shared/services/profileService';
import { useQueryClient } from 'react-query';

import { useStyles } from './Information.styles';
import { checkInService } from 'shared/services/checkInService';
import moment from 'moment';

type Props = {
  data: any;
  projectName?: string;
  pData: any;
  form: any;
  createIsLoading: boolean;
};

const { getProfiles } = profileService();

const { getCheckInDetails } = checkInService();

const Information: React.FC<Props> = ({ data, projectName, pData, form, createIsLoading }) => {
  const classes = useStyles();
  const { data: profileList } = getProfiles();

  const [roleName, setRoleName] = useState('');
  const [projectCastingRole, setprojectCastingRole] = useState<any>();

  const queryClient = useQueryClient();
  const { data: cData, isLoading } = getCheckInDetails(
    pData && pData?.data.attributes.ongoing_session.data.id ? pData?.data.attributes.ongoing_session.data.id : '',
  );

  const auditionData = data && data.attributes.audition.data.attributes ? data.attributes.audition.data.attributes : '';
  const scheduleData = data && data.attributes.schedule.data.attributes ? data.attributes.schedule.data.attributes : '';
  const profileListMapped = profileList?.data.map((i: IProfile) => i);
  const roleList = pData.data.attributes.ongoing_session.data.attributes.casting_roles.map((i: ICastingRole) => i);

  useEffect(() => {
    const newData = pData.data.attributes.ongoing_session?.data.attributes.casting_roles?.filter(
      (c: any) => c.name === roleName,
    )[0];
    setprojectCastingRole(newData);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleName, pData]);

  return (
    <Grid className={classes.root}>
      {!createIsLoading ? (
        <>
          <Grid item>
            <Typography className={classes.heading} fontSize={18}>
              Check-In Information
            </Typography>

            {data ? (
              ''
            ) : (
              <Grid item className={classes.selection}>
                <Grid item>
                  <Typography>Select Your Representation For This Audition:</Typography>
                  <Autocomplete
                    className={classes.autocomplete}
                    onChange={(i, newValue) => {
                      if (newValue) {
                        form.setFieldValue('profile_id', newValue.id);
                      } else {
                        form.setFieldValue('profile_id', '');
                      }
                    }}
                    // value={form.values.statistics?.region}
                    options={profileListMapped ? profileListMapped : []}
                    getOptionLabel={(profileListMapped) =>
                      profileListMapped.attributes.representation_type === 'Non-Represented'
                        ? 'Non-Represented'
                        : profileListMapped.attributes.agency_name
                    }
                    getOptionSelected={(option, value) => option === value}
                    fullWidth
                    renderInput={(params) => (
                      <TextField {...params} placeholder={'Select Representation'} variant="outlined" margin="normal" />
                    )}
                  />
                </Grid>
                <Grid item>
                  <Typography>Select The Role You Are Auditioning For:</Typography>
                  <Autocomplete
                    onChange={(i, newValue) => {
                      if (newValue) {
                        form.setFieldValue('role_id', newValue.id);
                        setRoleName(newValue.name);
                      } else {
                        form.setFieldValue('role_id', '');
                        setRoleName('');
                      }
                    }}
                    className={classes.autocomplete}
                    // value={form.values.statistics?.region}
                    options={roleList ? roleList : []}
                    getOptionLabel={(roleList: ICastingRoleAttributes) => roleList.name}
                    getOptionSelected={(option, value) => option === value}
                    fullWidth
                    renderInput={(params) => (
                      <TextField {...params} placeholder={'Select Representation'} variant="outlined" margin="normal" />
                    )}
                  />
                </Grid>
                {/* <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    className={classes.buttonCls}
                    onClick={() => form.handleSubmit()}
                    disabled={form.values.profile_id === '' || form.values.role_id === ''}
                  >
                    Confirm
                  </Button>
                </Grid> */}
              </Grid>
            )}

            <Grid item>
              <Typography>Project:</Typography>
              <Typography>{projectName ? projectName : 'N/A'}</Typography>
            </Grid>
            <Grid item>
              <Typography>Role:</Typography>
              <Typography>
                {data && auditionData && auditionData.casting_role.data.attributes?.name
                  ? auditionData.casting_role.data.attributes?.name
                  : roleName
                  ? roleName
                  : 'N/A'}
              </Typography>
            </Grid>
            {!data ? (
              ''
            ) : (
              <Grid item>
                <Typography>Audition Time:</Typography>
                <Typography color="primary">
                  {data && scheduleData && scheduleData?.time_in
                    ? moment.utc(data.attributes?.checked_in_at || scheduleData?.time_in).format('hh:mmA')
                    : '-'}
                </Typography>
              </Grid>
            )}
          </Grid>
          <Grid item>
            <Grid item>
              <Typography>Union:</Typography>
              <Typography>{pData && pData.data.attributes?.union ? pData.data.attributes?.union : 'N/A'}</Typography>
            </Grid>
            <Grid item>
              <Typography>Callback:</Typography>
              <Typography>
                {data && auditionData && auditionData.casting_role.data.attributes?.callback
                  ? auditionData.casting_role.data.attributes.callback.dates
                  : projectCastingRole?.callback?.dates[0] || '-'}
              </Typography>
            </Grid>
            <Grid item>
              <Typography>Shooting:</Typography>
              <Typography>
                {data && auditionData && auditionData.casting_role.data.attributes.working
                  ? auditionData.casting_role.data.attributes.working.dates.toString()
                  : projectCastingRole?.working?.dates.toString() || '-'}
              </Typography>
            </Grid>
            <Grid item>
              <Typography>Location:</Typography>
              <Typography>
                {data && auditionData && auditionData.casting_role.data.attributes?.audition
                  ? auditionData.casting_role.data.attributes.audition?.location
                  : projectCastingRole?.audition?.location || '-'}
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Grid item>
              <Typography className={classes.yellow}>Conflicts:</Typography>
              <Typography>
                {pData && pData.data.attributes.conflicts ? pData.data.attributes?.conflicts : '-'}
              </Typography>
            </Grid>
            <Grid item>
              <Typography color="primary">Talent Notes:</Typography>
              <Typography>
                {data && auditionData && auditionData.casting_role.data.attributes?.talent_description
                  ? auditionData.casting_role.data.attributes?.talent_description
                  : 'N/A'}
              </Typography>
            </Grid>
          </Grid>
        </>
      ) : (
        <CircularProgress style={{ alignSelf: 'center' }} />
      )}
    </Grid>
  );
};

export default Information;
