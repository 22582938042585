import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  Radio,
  RadioGroup,
  TextareaAutosize,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';

import {
  Backdrop,
  Checkbox,
  CheckBox,
  Select,
  SelectArrowDownIcon,
  TextInput,
  Typography,
} from '@room-match/shared-ui-components';
import { Stepper } from 'components';
import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';
import { checkInService } from 'shared/services/checkInService';
import { studioSessionService } from 'shared/services/studioSessionService';

import { useStyles } from './Additional.styles';

type Props = {
  sessionId: string;
  setActiveStep(steps: number): void;
  activeStep: number;
  auditionID: string;
};

interface IAnswer {
  answers: {
    question_id: string;
    answer: string;
  }[];
}

// interface ICombinedAnswers {}
interface IQuestion {
  question: string;
  question_id: string;
  question_type: 'Single Selection' | 'Multiple Options' | 'Text Response';
  selections: any[];
  answer: string | string[];
}

const { getSessionQuestions } = checkInService();

const { saveAnswers, getAnswers } = studioSessionService();

const Additional: React.FC<Props> = ({ sessionId, setActiveStep, activeStep, auditionID }) => {
  const classes = useStyles();
  const { data, isLoading } = getSessionQuestions(sessionId);
  const { data: aData, isLoading: answerLoading } = getAnswers(auditionID);
  const queryClient = useQueryClient();
  const [questionArr, setquestionArr] = useState<IQuestion[]>([]);
  const { mutate, isLoading: updateLoading } = saveAnswers();
  const [complete, setcomplete] = useState(false);

  const handleTextChange = (value: string, question_id: string) => {
    const newArr = questionArr.map((q) => {
      if (q.question_id === question_id) {
        return {
          ...q,
          answer: value,
        };
      }
      return q;
    });

    setquestionArr(newArr);
  };

  useEffect(() => {
    const questions = data?.data;

    if (questions || aData) {
      const questionARR = (questions || []).map((q) => {
        let answer;
        const ansData =
          (aData?.data || []).filter((a) => a.attributes?.question_id === q.id)[0]?.attributes.answer || '';
        if (q.attributes.question_type === 'Single Selection' && ansData === '') {
          answer = q.attributes.selections[0].text;
        } else {
          answer = ansData;
        }

        return {
          question: q.attributes.inquiry,
          question_id: q.id,
          question_type: q.attributes.question_type,
          selections: q.attributes.selections,
          answer: ansData,
        };
      });

      setquestionArr(questionARR);
    }
  }, [data, aData]);

  const handleCheckChange = (text: string, question_id: string) => {
    const newArr = questionArr.map((q) => {
      const arrAns = q.answer as [];
      if (q.question_id === question_id) {
        const arrIdx = q.answer.indexOf(text);
        let answer;

        if (arrIdx > -1) {
          answer = arrAns.filter((f) => f !== text);
        } else {
          answer = [...q.answer, text];
        }
        return {
          ...q,
          answer,
        };
      }
      return q;
    });

    setquestionArr(newArr);
  };

  const handleSaveAnswer = () => {
    const answers = questionArr.map((q) => {
      return {
        question_id: q.question_id,
        answer: q.answer,
      };
    });

    mutate(
      { auditionScheduleId: auditionID, payload: { answers } },
      {
        onSuccess: () => {
          queryClient.invalidateQueries('audition_answers');
          setActiveStep(activeStep + 1);
        },
      },
    );
  };

  const radioChange = (value: any, question_id: string) => {
    const newArr = questionArr.map((q) => {
      if (q.question_id === question_id) {
        return {
          ...q,
          answer: value,
        };
      }
      return q;
    });

    setquestionArr(newArr);
  };

  const renderQuestion = (q: IQuestion) => {
    switch (q.question_type) {
      case 'Multiple Options':
        return q.selections.map((s: any) => (
          <Box style={{ flexDirection: 'column', marginLeft: 10 }} key={s.text}>
            <Checkbox
              value={q.answer.indexOf(s.text) > -1}
              onChange={() => handleCheckChange(s.text, q.question_id)}
              label={s.text}
              labelPlacement="end"
            />
          </Box>
        ));

      case 'Single Selection':
        return (
          <FormControl>
            {q.selections.map((s: any) => (
              <FormControlLabel
                onChange={(e: any) => radioChange(s.text, q.question_id)}
                style={{ marginLeft: 0 }}
                key={s.text}
                checked={q.answer === s.text}
                control={<Radio color="primary" />}
                label={s.text}
              />
            ))}
            {/* </RadioGroup> */}
          </FormControl>
        );

      case 'Text Response':
        return (
          <TextareaAutosize
            style={{
              width: '100%',
              resize: 'none',
              background: '#0b0f14',
              color: 'white',
              padding: 10,
              border: 'solid 1px #2d343e',
              fontSize: 16,
            }}
            placeholder={`${q.selections[0].text}`}
            onChange={(e) => handleTextChange(e.target.value, q.question_id)}
            value={q.answer}
            maxRows={4}
          />
        );
      default:
        return <></>;
    }
  };

  useEffect(() => {
    let unanswered = 0;
    questionArr.map((q) => {
      if (q.answer === '' || q.answer.length === 0) unanswered++;
    });

    setcomplete(unanswered === 0);
  }, [questionArr]);

  return (
    <>
      {!isLoading || !answerLoading ? (
        <Grid item className={classes.root}>
          {questionArr ? (
            <Box style={{ height: '60vh', overflow: 'auto', paddingRight: 20 }}>
              {questionArr.map((q) => (
                <Box component="div" className={classes.checkboxContainer} key={q.question_id}>
                  <InputLabel id="representationStatus" style={{ marginBottom: 15, fontSize: 17 }}>
                    {q.question}
                  </InputLabel>
                  {renderQuestion(q)}
                </Box>
              ))}
            </Box>
          ) : (
            'No Questions Available'
          )}
          <Typography style={{ color: '#e34343', marginLeft: 10, opacity: complete ? 0 : 1 }}>
            Please answer all the questions
          </Typography>
          <Grid item className={classes.buttonsContainer}>
            <Button
              variant="contained"
              fullWidth
              className={`${classes.buttonCls} ${classes.backBtn}`}
              onClick={() => setActiveStep(activeStep - 1)}
            >
              Go Back
            </Button>
            <Button
              disabled={updateLoading || !complete}
              variant="contained"
              color="primary"
              fullWidth
              className={classes.buttonCls}
              onClick={handleSaveAnswer}
              // onClick={() => form.handleSubmit()}
            >
              {updateLoading ? 'Saving...' : 'Next'}
            </Button>
          </Grid>
        </Grid>
      ) : (
        <Backdrop isLoading={isLoading || answerLoading} />
      )}
    </>
  );
};

export default Additional;
