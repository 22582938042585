import { Box, Grid, Button, useMediaQuery, useTheme } from '@material-ui/core';

import { Backdrop, Typography } from '@room-match/shared-ui-components';
import { Stepper } from 'components';
import { useEffect, useState } from 'react';
import { checkInService } from 'shared/services/checkInService';
import { projectService } from 'shared/services/projectService';
import { talentService } from 'shared/services/talentService';

import { useStyles } from './CheckInForm.styles';
import { Complete } from './Complete';
import { Additional, Personal, Terms } from './Form';
import { Information } from './Information';

import { FormikProps, useFormik } from 'formik';
import * as yup from 'yup';
import { IAuditionScheduleCreatePayload } from 'shared/interfaces/IAuditionSchedule';
import { auditionScheduleService } from 'shared/services/auditionScheduleService';
import { useQueryClient } from 'react-query';

const { getSingleProject } = projectService();
const { getCheckInDetails } = checkInService();
const { createAuditionSchedule } = auditionScheduleService();

type Props = {
  setActiveStep(step: number): void;
  activeStep: number;
  projectId: string;
};

const CheckInForm: React.FC<Props> = ({ setActiveStep, activeStep, projectId }) => {
  const [sessionID, setsessionID] = useState('');
  const classes = useStyles();
  const { mutateAsync, isLoading: createIsLoading } = createAuditionSchedule();
  const queryClient = useQueryClient();

  const { data: pData } = getSingleProject(projectId);
  const { data, isLoading } = getCheckInDetails(sessionID);

  const next = () => {
    setActiveStep(activeStep + 1);
  };

  const prev = () => {
    setActiveStep(activeStep - 1);
  };

  useEffect(() => {
    if (pData?.data.attributes.ongoing_session) {
      setsessionID(pData?.data.attributes?.ongoing_session.data.id);
    } else {
      console.error('Error', 'no ongoing session ID');
    }
  }, [pData]);

  const initialValues: IAuditionScheduleCreatePayload = {
    role_id: '',
    profile_id: '',
  };

  const handleSubmit = async (values: IAuditionScheduleCreatePayload) => {
    await mutateAsync(
      { sessionId: pData?.data.attributes.ongoing_session.data.id as string, payload: values },
      {
        onSuccess: () => {
          queryClient.invalidateQueries('sessions/check_in').then(() => setActiveStep(activeStep + 1));
        },
      },
    );
  };

  const createResumeValidationSchema: yup.SchemaOf<IAuditionScheduleCreatePayload> = yup.object().shape({
    // representation_type: yup.mixed<RepresentationType>().oneOf(Object.values(RepresentationType)).required(),
    role_id: yup.string().required(),
    profile_id: yup.string().required(),
  });

  const form: FormikProps<IAuditionScheduleCreatePayload> = useFormik({
    initialValues,
    // validateOnBlur: true,
    // enableReinitialize: true,
    validationSchema: createResumeValidationSchema,
    onSubmit: (values) => {
      if (data?.data) {
        setActiveStep(activeStep + 1);
      } else {
        handleSubmit(values);
      }
    },
  });

  useEffect(() => {
    if (data?.data) {
      if (data?.data.attributes?.status === 'Checked In') setActiveStep(4);
      form.setFieldValue('role_id', '123');
      form.setFieldValue('profile_id', '123');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      {!isLoading ? (
        <Grid item className={classes.root}>
          <Grid item className={`${classes.container} ${classes.information}`}>
            <Information
              createIsLoading={createIsLoading}
              form={form}
              data={data && data !== null ? data?.data : ''}
              projectName={pData?.data.attributes.name}
              pData={pData}
            />
          </Grid>
          <Grid
            item
            className={`${classes.container} ${activeStep > 3 ? classes.completeBody : classes.body}`}
            style={{ opacity: form.values.profile_id === '' || form.values.role_id === '' ? 0.5 : 1 }}
          >
            {activeStep > 3 ? (
              <Complete
                info={data?.data.attributes.audition.data.attributes.profile.data}
                setActiveStep={setActiveStep}
                activeStep={activeStep}
              />
            ) : (
              <Box style={{ position: 'relative' }}>
                {(form.values.profile_id === '' || form.values.role_id === '') && (
                  <Box style={{ width: '100%', height: '100%', position: 'absolute', zIndex: 99 }}></Box>
                )}
                <Grid item className={classes.header}>
                  <Grid item>
                    <Typography fontSize={16}>Please complete the following steps to check-in</Typography>
                  </Grid>
                  <Grid item className={classes.stepperContainer}>
                    <Stepper steps={4} active={activeStep} />
                  </Grid>
                </Grid>
                <Grid item>
                  {activeStep === 1 ? (
                    <Personal
                      personalDone={() => form.handleSubmit()}
                      data={data}
                      setActiveStep={setActiveStep}
                      activeStep={activeStep}
                    />
                  ) : activeStep === 2 ? (
                    <Additional
                      auditionID={data?.data.id || ''}
                      activeStep={activeStep}
                      setActiveStep={setActiveStep}
                      sessionId={
                        pData && pData?.data.attributes.ongoing_session.data.id
                          ? pData?.data.attributes.ongoing_session.data.id
                          : ''
                      }
                    />
                  ) : activeStep === 3 ? (
                    <Terms
                      nda={pData?.data.attributes.nda_content}
                      data={data?.data}
                      activeStep={activeStep}
                      setActiveStep={setActiveStep}
                      sessionId={
                        pData && pData?.data.attributes.ongoing_session.data.id
                          ? pData?.data.attributes.ongoing_session.data.id
                          : ''
                      }
                    />
                  ) : (
                    'N/A'
                  )}
                </Grid>
              </Box>
            )}
          </Grid>
        </Grid>
      ) : (
        <Backdrop isLoading={isLoading} />
      )}
    </>
  );
};

export default CheckInForm;
