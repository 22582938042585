import { useMutation, useQuery } from 'react-query';
import { projectDao } from 'shared/dao/projectDao';
import {
  IProjectCreatePayload,
  IProjectCreateResponsePayload,
  IProjectGetCastingSessionRequestPayload,
  IProjectGetCastingSessionResponsePayload,
  IProjectImportResponsePayload,
  IProjectRequestPayload,
  IProjectResponsePayload,
  IProjectSearchCastingRoleRequestPayload,
  IProjectSearchCastingRoleResponsePayload,
  IProjectSearchResponsePayload,
  ISingleProjectResponsePayload,
} from 'shared/interfaces/IProject';
import { IStudioSessionGetQuestionListResponsePayload } from 'shared/interfaces/IStudioSession';
import { IAxiosErrorResponse, IOnProgress } from 'shared/interfaces/utils/IAxios';

const {
  getProjects: getProjectsDao,
  getSingleProject: getSingleProjectDao,
  createProject: createProjectDao,
  updateProject: updateProjectDao,
  importProject: importProjectDao,
  searchProjects: searchProjectsDao,
  searchCastingRolesByProject: searchCastingRolesByProjectDao,
  getCastingSessionsByProject: getCastingSessionsByProjectDao,
  getProjectQuestions: getProjectQuestionsDao,
} = projectDao();
export const projectService = () => {
  const getProjects = (payload?: IProjectRequestPayload) => {
    return useQuery<IProjectResponsePayload, IAxiosErrorResponse>(['projects', payload], () => getProjectsDao(payload));
  };

  const getSingleProject = (projectId: string) => {
    return useQuery<ISingleProjectResponsePayload, IAxiosErrorResponse>(['projects', projectId], () =>
      getSingleProjectDao(projectId),
    );
  };

  const createProject = () => {
    return useMutation<IProjectCreateResponsePayload, IAxiosErrorResponse, IProjectCreatePayload>(
      (payload: IProjectCreatePayload) => createProjectDao(payload),
    );
  };

  const updateProject = () => {
    return useMutation<
      IProjectCreateResponsePayload,
      IAxiosErrorResponse,
      { projectId: string; payload: IProjectCreatePayload }
    >(({ projectId, payload }) => updateProjectDao(projectId, payload));
  };

  const importProject = () => {
    return useMutation<
      IProjectImportResponsePayload,
      IAxiosErrorResponse,
      { formData: FormData; onProgress?: IOnProgress }
    >(({ formData, onProgress }) => importProjectDao(formData, onProgress));
  };

  const searchProjects = (query: string) => {
    return useQuery<IProjectSearchResponsePayload, IAxiosErrorResponse>(['projects', query], () =>
      searchProjectsDao(query),
    );
  };

  const searchCastingRolesByProject = (payload: IProjectSearchCastingRoleRequestPayload) => {
    return useQuery<
      IProjectSearchCastingRoleResponsePayload,
      IAxiosErrorResponse,
      IProjectSearchCastingRoleRequestPayload
    >(['projects/casting_role', payload], () => searchCastingRolesByProjectDao(payload));
  };

  const getCastingSessionsByProject = (payload: IProjectGetCastingSessionRequestPayload) => {
    return useQuery<
      IProjectGetCastingSessionResponsePayload,
      IAxiosErrorResponse,
      IProjectGetCastingSessionRequestPayload
    >(['projects/casting_session', payload], () => getCastingSessionsByProjectDao(payload));
  };

  const getProjectQuestions = (projectId: string, roleId: string) => {
    return useQuery<IStudioSessionGetQuestionListResponsePayload, IAxiosErrorResponse>(
      ['sessions/questions', projectId],
      () => getProjectQuestionsDao(projectId, roleId),
    );
  };

  return {
    getProjectQuestions,
    getProjects,
    getSingleProject,
    createProject,
    updateProject,
    importProject,
    searchProjects,
    searchCastingRolesByProject,
    getCastingSessionsByProject,
  };
};
