import { parse } from 'query-string';
import React from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { ROUTES } from 'shared/constants/ROUTES';
import { tokenService } from 'shared/services/tokenService';

type Props = {
  component: React.FC;
  exact?: boolean;
  path: string;
};

const { isAuthenticated } = tokenService();
const PublicRoute: React.FC<Props> = ({ component: Component, path, exact }) => {
  const location = useLocation();
  const queryToken = parse(location.search)?.token as string;
  return (
    <Route
      component={() => (!isAuthenticated() || queryToken ? <Component /> : <Redirect to={ROUTES.TALENT.DASHBOARD} />)}
      exact={exact}
      path={path}
    />
  );
};

export default PublicRoute;
