import { useMutation, useQuery } from 'react-query';
import { checkInDao } from 'shared/dao/checkInDao';

import { ICheckInMarkPayload, ICheckInMarkResponsePayload, ICheckInResponsePayload } from 'shared/interfaces/ICheckIn';
import { IStudioSessionGetQuestionListResponsePayload } from 'shared/interfaces/IStudioSession';
import { IAxiosErrorResponse } from 'shared/interfaces/utils/IAxios';

const {
  getCheckInDetails: getCheckInDetailsDao,
  getSessionQuestions: getSessionQuestionsDao,
  markAsCheckIn: markAsCheckInDao,
} = checkInDao();
export const checkInService = () => {
  const getCheckInDetails = (sessionId: string) => {
    return useQuery<ICheckInResponsePayload, IAxiosErrorResponse>(['sessions/check_in', sessionId], () =>
      getCheckInDetailsDao(sessionId),
    );
  };

  const getSessionQuestions = (sessionId: string) => {
    return useQuery<IStudioSessionGetQuestionListResponsePayload, IAxiosErrorResponse>(
      ['sessions/questions', sessionId],
      () => getSessionQuestionsDao(sessionId),
    );
  };

  const markAsCheckIn = () => {
    return useMutation<
      ICheckInMarkResponsePayload,
      IAxiosErrorResponse,
      { sessionId: string; payload: ICheckInMarkPayload }
    >(({ sessionId, payload }) => markAsCheckInDao(sessionId, payload));
  };

  return {
    getCheckInDetails,
    getSessionQuestions,
    markAsCheckIn,
  };
};
