import { Theme, createStyles, makeStyles, styled } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawerContainer: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    menuList: {
      flex: 1,
      margin: '0',
      '& .MuiListItem-root': {
        opacity: 1,
        whiteSpace: 'break-spaces',
        textAlign: 'center',

        '& svg path': {
          fill: '#FFFFFF',
        },
      },
      '& .MuiListItem-root:hover svg path': {
        fill: '#FFFFFF',
      },
      '& .MuiListItem-root svg': {
        width: '21px',
      },
      ' & .MuiListItem-root:hover span': {
        color: '#FFFFFF',
      },
    },
    headShot: {
      // width: '64px !important',
      // height: '64px !important'
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: 'flex-start',
      height: '80px',
    },
    drawer: {
      width: 100,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      '& .MuiList-root': {
        overflow: 'auto',
      },
    },
    drawerPaper: {
      width: 100,
      backgroundColor: '#0d1117',
    },
    drawerOpen: {
      backgroundColor: '#0d1117',
      width: 100,
      overflowY: 'visible',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowY: 'visible',
      width: 100,
      backgroundColor: '#0d1117',
      '& .MuiListItemText-root': {
        display: 'none',
      },
    },

    collapseContainerSmall: {},
    hidden: {
      display: 'none !important',
    },

    collapseContainer: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'flex-end',
      marginBottom: -20,
    },
    collapseButton: {
      position: 'absolute',
      top: -24,
      right: -24,
    },
    switchContent: {
      display: 'flex',
      color: '#FFFFFF',
      padding: '10px',
      cursor: 'pointer',
      textDecoration: 'none',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      '& .MuiFormControlLabel-root': {
        marginRight: '0px',
        marginLeft: '0px',
      },
    },
    myAccountContainer: {
      height: '100px',
    },
    accountMenuOpenButton: {
      color: '#A4A4A4',
    },
    accountMenuActionItem: {
      fontSize: '12px',
    },
  }),
);

export const CustomSwitch = styled(Switch)(() => ({
  width: 90,
  height: 38,
  padding: 7,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    top: '9px',
    transform: 'translateX(44px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(9px)',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#151a21',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: '#6a788f',
    width: 35,
    height: 18,
    borderRadius: '5px',
    '&:before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
    },
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: '#151a21',
    borderRadius: '5px',
  },
}));

export const useMainListeItemStyle = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      padding: '15px 16px !important',
      '& *': {
        margin: '0 !important',
      },
      '& svg': {
        marginBottom: '5px !important',
      },
      '& > div:first-child': {
        display: 'flex',
        justifyContent: 'center',
      },
      '& > div:last-child *': {
        fontSize: '12px',
      },
      '& > div > span': {
        visibility: 'hidden',
      },
      '&$selected': {
        backgroundColor: 'transparent',
        borderRight: 'solid 1px #3f8aff',
        '& > div > span': {
          color: '#FFFFFF',
          visibility: 'visible',
        },
        '& > div > svg path': {
          fill: '#3f8aff',
        },
      },
      '&$selected:hover': {
        backgroundColor: '#151a21',
        '& > div > span': {
          visibility: 'visible',
        },
      },
      '&:hover': {
        backgroundColor: '#151a21',
        '& > div > span': {
          visibility: 'visible',
        },
      },
      '& span': {
        fontSize: '12px',
        color: '#838d9c',
      },
      '& .MuiListItemIcon-root': {
        minWidth: '46px',
      },
    },
    selected: {},
  });
});
