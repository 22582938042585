import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function LogoIcon(props: SvgIconProps) {
  return (
    <SvgIcon width="25" height="24" viewBox="0 0 25 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <defs>
        <linearGradient x1="40.313%" y1="95.922%" x2="70.395%" y2="5.978%" id="07hmowf39a">
          <stop stopColor="#D51BFF" offset="0%" />
          <stop stopColor="#00D4FF" offset="100%" />
        </linearGradient>
        <linearGradient x1="28.126%" y1="115.422%" x2="58.187%" y2="25.501%" id="lvraqn2heb">
          <stop stopColor="#D51BFF" offset="0%" />
          <stop stopColor="#00D4FF" offset="100%" />
        </linearGradient>
        <linearGradient x1="-59.392%" y1="193.063%" x2="127.904%" y2="-36.855%" id="iqww5y7soc">
          <stop stopColor="#D51BFF" offset="0%" />
          <stop stopColor="#00D4FF" offset="100%" />
        </linearGradient>
        <linearGradient x1="-62.042%" y1="178.684%" x2="127.306%" y2="-50.37%" id="6y0maln97d">
          <stop stopColor="#D51BFF" offset="0%" />
          <stop stopColor="#00D4FF" offset="100%" />
        </linearGradient>
        <linearGradient x1="-23.364%" y1="228.84%" x2="61.518%" y2="-.243%" id="y9xevr40we">
          <stop stopColor="#D51BFF" offset="0%" />
          <stop stopColor="#00D4FF" offset="100%" />
        </linearGradient>
        <linearGradient x1="-36.053%" y1="132.875%" x2="145.364%" y2="-58.89%" id="nyhntiy1ef">
          <stop stopColor="#D51BFF" offset="0%" />
          <stop stopColor="#00D4FF" offset="100%" />
        </linearGradient>
        <linearGradient x1="-81.163%" y1="198.079%" x2="104.634%" y2="-1.046%" id="m6n4cn9csg">
          <stop stopColor="#D51BFF" offset="0%" />
          <stop stopColor="#00D4FF" offset="100%" />
        </linearGradient>
        <linearGradient x1="24.887%" y1="137.686%" x2="105.795%" y2="-83.537%" id="720aw8wc3h">
          <stop stopColor="#D51BFF" offset="0%" />
          <stop stopColor="#00D4FF" offset="100%" />
        </linearGradient>
      </defs>
      <g fillRule="nonzero" fill="none" opacity="1">
        <path
          d="M1.583 11.997c0-5.491 4.26-10.006 9.652-10.411V0C4.961.407 0 5.622 0 11.997 0 18.37 4.96 23.59 11.232 23.994v-1.586c-5.388-.405-9.649-4.92-9.649-10.411z"
          fill="url(#07hmowf39a)"
        />
        <path
          d="M24.047 11.997c0-6.375-4.96-11.59-11.232-11.997v1.586c5.389.405 9.65 4.92 9.65 10.414s-4.261 10.006-9.65 10.414V24a12.022 12.022 0 0 0 9.65-6.032V24h1.582V11.997z"
          fill="url(#lvraqn2heb)"
        />
        <path
          d="M14.051 15.137a18.39 18.39 0 0 1-1.743.616 18.525 18.525 0 0 0 1.926 1.023c1.22.557 2.497.977 3.809 1.252a8.572 8.572 0 0 0 2.415-7.216 19.272 19.272 0 0 1-3.243 2.663 19.014 19.014 0 0 1-3.164 1.662z"
          fill="url(#iqww5y7soc)"
        />
        <path
          d="M9.9 8.623a20.441 20.441 0 0 1 1.706-.582 18.692 18.692 0 0 0-1.913-1.04 18.157 18.157 0 0 0-3.537-1.227 8.473 8.473 0 0 0-2.598 4.973c-.1.683-.12 1.375-.056 2.062a19.31 19.31 0 0 1 3.115-2.499 18.978 18.978 0 0 1 3.282-1.687z"
          fill="url(#6y0maln97d)"
        />
        <path
          d="M15.798 11.621a19.043 19.043 0 0 1-.419 2.004 17.748 17.748 0 0 0 2.041-1.263 18.598 18.598 0 0 0 2.62-2.273c.077-.08.151-.161.226-.242a8.44 8.44 0 0 0-1.41-2.94 8.485 8.485 0 0 0-3.358-2.703 19.311 19.311 0 0 1 .515 3.913c0 .044.003.089.005.131a19.453 19.453 0 0 1-.22 3.373z"
          fill="url(#y9xevr40we)"
        />
        <path
          d="M17.302 18.685a18.864 18.864 0 0 1-4.15-1.537 18.936 18.936 0 0 1-2.788-1.742 19.534 19.534 0 0 1-1.543-1.291c-.016.393-.02.787-.012 1.184 0 .113.008.228.012.341a18.387 18.387 0 0 0 .755 4.538c.385.114.777.2 1.174.259l.177.024a8.482 8.482 0 0 0 6.157-1.615c.077-.048.148-.105.218-.16z"
          fill="url(#nyhntiy1ef)"
        />
        <path
          d="M10.039 6.275a18.748 18.748 0 0 1 3.508 2.133 19.177 19.177 0 0 1 1.635 1.4c.03-.51.042-1.023.03-1.54v-.094a18.331 18.331 0 0 0-.629-4.318 8.67 8.67 0 0 0-2.788-.397h-.009A8.476 8.476 0 0 0 6.92 5.136c1.071.288 2.115.669 3.119 1.139z"
          fill="url(#m6n4cn9csg)"
        />
        <path
          d="M8.173 12.308c.1-.746.244-1.486.43-2.216a18.435 18.435 0 0 0-4.909 3.663l-.042.044a8.53 8.53 0 0 0 1.493 3.283 8.49 8.49 0 0 0 3.502 2.765 19.181 19.181 0 0 1-.628-4.137 19.471 19.471 0 0 1 .154-3.402z"
          fill="url(#720aw8wc3h)"
        />
        <circle fill="#E30613" cx="23.019" cy="1.028" r="1.028" />
      </g>
    </SvgIcon>
  );
}

export default LogoIcon;
