import { Box, Grid, useMediaQuery, useTheme } from '@material-ui/core';
import { Sidebar } from 'components/Sidebar';
import { Header } from 'components/Header';
import React, { useState, useCallback } from 'react';
import { Backdrop, Typography } from '@room-match/shared-ui-components';

import { useStyles } from './ProfileSelection.styles';

type Props = {
  setSteps(steps: number): void;
  setProfileType(profileType: string): void;
  profileType: string;
};
const ProfileSelection: React.FC<Props> = ({ setSteps, setProfileType, profileType }) => {
  const classes = useStyles();

  return (
    <>
      <Grid item>
        <Typography fontSize={24} className={classes.heading}>
          Please let us know which type of profile fits you better
        </Typography>
      </Grid>
      <Grid item className={classes.selectionContainer}>
        <Box
          component="div"
          className={`${classes.box} ${profileType === 'adult' ? classes.boxActive : ''}`}
          onClick={() => {
            setProfileType('adult');
            setSteps(4);
          }}
        >
          <Typography fontSize={24}>Adult Profile</Typography>
          <Typography fontSize={18} className={classes.helpText} style={{ marginTop: 2 }}>
            18 Years+
          </Typography>
          <Typography color="primary" style={{ marginTop: 5 }} className={classes.primaryText}>
            I am an individual adult talent
          </Typography>
        </Box>
        <Box
          component="div"
          className={`${classes.box} ${profileType === 'minor' ? classes.boxActive : ''}`}
          onClick={() => {
            setProfileType('minor');
            setSteps(5);
          }}
        >
          <Typography fontSize={24}>Minor Profile</Typography>
          <Typography fontSize={18} className={classes.helpText} style={{ marginTop: 2 }}>
            Under 18 Years
          </Typography>
          <Typography color="primary" style={{ marginTop: 5 }} className={classes.primaryText}>
            I am the parent/guardian adding one or multiple child talent
          </Typography>
        </Box>
      </Grid>
    </>
  );
};

export default ProfileSelection;
