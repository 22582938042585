import { createStyles, makeStyles, styled, CardContent as CC } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      gap: '1.5em',

      '& .MuiOutlinedInput-root': {
        height: '44px!important',
        fontSize: 14,
        background: '#0e1319',
        borderRadius: 8,
      },

      '& .MuiFormLabel-root': {
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: '13px',
        letterSpacing: '1px',
        alignItems: 'center',
        color: '#c2ccd8',

        '& .MuiBox-root': {
          padding: '2px 4px 1px 5px',
          border: '1px solid',
          borderRadius: '100%',
          fontSize: '12px',
        },
      },

      '& .MuiSelect-iconOutlined': {
        right: '0',
        top: '20px',
      },

      '& .MuiSelect-iconOpen': {
        top: '0',
        right: '13px',
      },

      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },

    buttonCls: {
      height: '50px !important',
      borderRadius: 8,
      textTransform: 'unset',
      width: '100%',
      fontSize: 15,
      marginTop: 20,
      maxWidth: 320,
    },

    autocomplete: {
      '& input': {
        marginTop: '-4px',
      },
    },
    column: {
      maxWidth: 320,
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: 10,

      [theme.breakpoints.down('xs')]: {
        maxWidth: 'unset',
      },
    },
    autocompleteMultiple: {
      '& .MuiChip-root': {
        height: 27,
        marginTop: -3,
      },
    },

    individualField: {
      maxWidth: 320,
      width: '100%',
    },

    row: {
      marginBottom: 20,
    },

    twoColumnItem: {
      maxWidth: 491,
      width: '100%',
      marginTop: 20,
    },

    autoCompleteGrid: {
      textAlign: 'right',
      position: 'relative',
      '& .MuiTypography-root': {
        cursor: 'pointer',
        position: 'absolute',
        zIndex: '2',
        color: '#212121',
        top: '-25px',
        right: '0',
      },
    },

    chipContainer: {
      margin: '10px 0 0',
      '& .MuiChip-root': {
        margin: '0 5px 5px 0',
      },
    },

    autocompleteContainer: {
      marginTop: '10px',
      '& > * + *': {
        marginTop: 2,
      },
      '& .MuiAutocomplete-tag': {
        height: '23px',
      },
      '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
        padding: '5px',
      },
      '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input': {
        padding: 0,
      },
    },
    profileItemsContainer: {
      [theme.breakpoints.up('md')]: {
        '& .MuiGrid-grid-lg-2': {
          maxWidth: 'initial%',
          flexBasis: 'initial',
        },
      },
    },
  }),
);

export const CardContent = styled(CC)(({ theme }) => ({
  backgroundColor: theme.palette.type === 'dark' ? '#0b0f14' : '#edf1f6',
}));
