import { useMutation, useQuery } from 'react-query';
import { auditionScheduleDao } from 'shared/dao/auditionScheduleDao';
import { projectDao } from 'shared/dao/projectDao';
import {
  IAuditionScheduleCreatePayload,
  IAuditionScheduleResponsePayload,
  ISelfTapesScheduleResponse,
} from 'shared/interfaces/IAuditionSchedule';
import { IAxiosErrorResponse } from 'shared/interfaces/utils/IAxios';

const {
  createAuditionSchedule: createAuditionScheduleDao,
  createAuditionScheduleSelfTapes: createAuditionScheduleSelfTapesDao,
  markAsDoneSelfTapes: markAsDoneSelfTapesDao,
} = auditionScheduleDao();
export const auditionScheduleService = () => {
  const createAuditionSchedule = () => {
    return useMutation<
      IAuditionScheduleResponsePayload,
      IAxiosErrorResponse,
      { sessionId: string; payload: IAuditionScheduleCreatePayload }
    >(({ sessionId, payload }) => createAuditionScheduleDao(sessionId, payload));
  };

  const createAuditionScheduleSelfTapes = () => {
    return useMutation<ISelfTapesScheduleResponse, IAxiosErrorResponse, { payload: IAuditionScheduleCreatePayload }>(
      ({ payload }) => createAuditionScheduleSelfTapesDao(payload),
    );
  };

  const markAsDoneSelfTapes = () => {
    return useMutation<
      ISelfTapesScheduleResponse,
      IAxiosErrorResponse,
      { auditionId: string; payload: IAuditionScheduleCreatePayload }
    >(({ auditionId, payload }) => markAsDoneSelfTapesDao(auditionId, payload));
  };

  return {
    markAsDoneSelfTapes,
    createAuditionSchedule,
    createAuditionScheduleSelfTapes,
  };
};
