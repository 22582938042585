import { Box } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import { useStyles } from './CustomVideoPlayer.styles';

import clsx from 'clsx';
import VideoPlayer from './VideoPlayer/VideoPlayer';

type Props = {
  url: string;
  startTime?: number;
  endTime?: number;
  thumbnail?: string;
  playIcon?: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
  classes?: {
    root: string;
    videoPlayer: string;
    icon: string;
  };
  key?: string;
};
const CustomVideoPlayer: React.FC<Props> = ({
  url,
  startTime,
  endTime,
  thumbnail,
  playIcon,
  classes: customClass,
  key,
}) => {
  const classes = useStyles();
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);
  const onEscape = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      e.stopPropagation();
      e.stopImmediatePropagation();
      setIsFullScreen(false);
      document.exitFullscreen();
    }
  };
  useEffect(() => {
    if (isFullScreen) {
      window.addEventListener('keyup', onEscape);
    }

    return () => window.removeEventListener('keyup', onEscape);
  }, [isFullScreen]);

  return (
    <Box
      className={clsx(
        classes.videoWrapper,
        {
          [classes.videoWrapperFullScreen]: isFullScreen,
        },
        customClass?.root,
      )}
      onClick={(e) => e.stopPropagation()}
    >
      <VideoPlayer
        url={url}
        startTime={startTime}
        endTime={endTime}
        setIsFullScreen={setIsFullScreen}
        isFullScreen={isFullScreen}
        thumbnail={thumbnail}
        playIcon={playIcon}
        key={key}
      />
    </Box>
  );
};

export default CustomVideoPlayer;
