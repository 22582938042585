import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',

      '& > div:not(:last-child)': {
        marginBottom: 5,
      },

      '& .MuiOutlinedInput-root': {
        height: '50px!important',
        fontSize: 14,
        background: '#0e1319',
      },

      '& .MuiFormLabel-root': {
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: '13px',
        letterSpacing: '1px',
        alignItems: 'center',
        color: '#c2ccd8',

        '& .MuiBox-root': {
          padding: '2px 4px 1px 5px',
          border: '1px solid',
          borderRadius: '100%',
          fontSize: '12px',
        },
      },

      '& .MuiSelect-iconOutlined': {
        right: '0',
        top: '22px',
      },

      '& .MuiSelect-iconOpen': {
        top: '2px',
        right: '13px',
      },
    },
    heading: {
      position: 'relative',
      borderBottom: '1px solid #2d343e',
      alignItems: 'center',
      paddingBottom: 20,
      margin: '10px 0',

      '&::before': {
        content: '""',
        background: '#3e8bff',
        height: '1px',
        position: 'absolute',
        width: 42,
        bottom: '-1px',
      },
    },
    row: {
      display: 'flex',
      gap: '2em',

      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        gap: 0,
      },
    },
    one__row: {
      display: 'flex',
      gap: '2em',
    },
  }),
);
