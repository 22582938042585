import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      maxWidth: 320,
      width: 320,
      padding: 0,
      borderRadius: 50,
      background: '#384558',
      height: 4,
      '& .MuiLinearProgress-root': {
        borderRadius: 50,
        background: '#384558',
        width: 320,
      },

      '& .MuiLinearProgress-bar ': {
        borderRadius: 50,
        backgroundImage: 'linear-gradient(266deg, #d51bff 1%, #00d4ff 50%, #00d4ff)',
      },
    },
  }),
);
