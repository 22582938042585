import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

const PrevImageIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="31" height="34" viewBox="0 0 31 34" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="#FFF" fill-rule="nonzero">
        <path d="M.5 17c0 .384.147.768.44 1.06l15 15a1.499 1.499 0 1 0 2.12-2.12L4.121 17l13.94-13.94A1.499 1.499 0 1 0 15.938.94l-15 15C.648 16.232.5 16.616.5 17zm12.44-1.06 15-15a1.499 1.499 0 1 1 2.12 2.12L16.121 17l13.94 13.94a1.499 1.499 0 1 1-2.122 2.12l-15-15a1.496 1.496 0 0 1 0-2.12z" />
        <path d="M.5 17c0 .384.147.768.44 1.06l15 15a1.499 1.499 0 1 0 2.12-2.12L4.121 17l13.94-13.94A1.499 1.499 0 1 0 15.938.94l-15 15C.648 16.232.5 16.616.5 17zm12.44-1.06 15-15a1.499 1.499 0 1 1 2.12 2.12L16.121 17l13.94 13.94a1.499 1.499 0 1 1-2.122 2.12l-15-15a1.496 1.496 0 0 1 0-2.12z" />
      </g>
    </SvgIcon>
  );
};

export default PrevImageIcon;
