import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function ClickIcon(props: SvgIconProps) {
  return (
    <SvgIcon width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M10 20a.667.667 0 0 1-.639-.475l-4-13.334a.667.667 0 0 1 .83-.83l13.334 4a.668.668 0 0 1 .178 1.194L16.38 12.77l3.425 3.425c.26.26.26.683 0 .943l-2.667 2.666a.666.666 0 0 1-.942 0L12.77 16.38l-2.215 3.323A.667.667 0 0 1 10 20zm2.667-5.333c.176 0 .345.07.471.195l3.529 3.529 1.723-1.724-3.528-3.529a.666.666 0 0 1 .102-1.026l2.82-1.88-10.79-3.238 3.238 10.79 1.88-2.82a.666.666 0 0 1 .555-.297zM3.805 3.805a.666.666 0 0 0 0-.943L2.47 1.529a.666.666 0 1 0-.942.942l1.333 1.334a.664.664 0 0 0 .943 0zM3.333 6a.667.667 0 0 0-.666-.667h-2a.667.667 0 0 0 0 1.334h2A.667.667 0 0 0 3.333 6zm-.862 4.471 1.334-1.333a.666.666 0 1 0-.943-.943L1.529 9.53a.666.666 0 1 0 .942.942zm6.667-6.666 1.333-1.334a.666.666 0 1 0-.942-.942L8.195 2.862a.666.666 0 1 0 .943.943zM6.667 2.667v-2a.667.667 0 0 0-1.334 0v2a.667.667 0 0 0 1.334 0z"
        fill="#FFF"
        fillRule="nonzero"
      />
    </SvgIcon>
  );
}

export default ClickIcon;
