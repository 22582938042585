import React, { useState, Fragment } from 'react';
import { Box, Typography } from '@material-ui/core';
import { PrettoSlider } from 'components';
import { Cropper } from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { useStyles } from './ProfileImageCropper.styles';

const standardHeightPixel = 1500;
const standardWidthPixel = 1125;
const CROP_BOX_HEIGHT = 485;

type Props = {
  src: string;
  cropper: any;
  setCropper: (instance: any) => void;
  handleCrop: () => void;
  setHeight: (height: number) => void;
  setWidth: (width: number) => void;
};

const ProfileImageCropper: React.FC<Props> = ({ src, cropper, setCropper, handleCrop, setHeight, setWidth }) => {
  const classes = useStyles();
  const [minZoomRatio, setMinZoomRatio] = useState(0);
  const [maxZoomRatio, setMaxZoomRatio] = useState(1);
  const [imageData, setImageData] = useState<Cropper.ImageData | undefined>(undefined);

  const [sliderDefaultValue, setSliderDefaultValue] = useState<number>(0);

  const handleSliderChange = (event: any, newValue: number | number[]) => {
    const valueNumber = newValue as number;
    setSliderDefaultValue(valueNumber);
    if (valueNumber > 0) {
      cropper.zoomTo(valueNumber);
    } else {
      cropper.zoomTo(0);
    }

    cropper.setCropBoxData({ height: CROP_BOX_HEIGHT });
  };

  const handleImageReady = (e: Cropper.ReadyEvent<HTMLImageElement>) => {
    const imageData = e.currentTarget.cropper.getImageData();

    const minSliderZoom = imageData.width / imageData.naturalWidth;
    const maxSliderZoom =
      Math.min.apply([imageData.naturalWidth / standardWidthPixel, imageData.naturalHeight / standardHeightPixel]) || 1;

    setMaxZoomRatio(maxSliderZoom);
    setMinZoomRatio(minSliderZoom);
    setImageData(imageData);
    setHeight(imageData.height);
    setWidth(imageData.width);
  };

  const handleReset = () => {
    cropper.reset();
  };

  const handleFlip = () => {
    const cropperData = cropper.getData();
    cropper.scaleX(-cropperData.scaleX);
  };

  const handleRotateRight = () => {
    cropper.rotate(45);
    cropper.zoomTo(minZoomRatio);
    setSliderDefaultValue(0.2);
  };

  const handleRotateLeft = () => {
    cropper.rotate(-45);

    cropper.zoomTo(minZoomRatio);
    setSliderDefaultValue(0.2);
  };

  return (
    <Fragment>
      <Box className={classes.photoContainer}>
        <Cropper
          className={classes.cropperCls}
          zoomTo={0}
          preview=".img-preview"
          src={src}
          minCropBoxHeight={485}
          minCropBoxWidth={485}
          aspectRatio={1}
          viewMode={3}
          guides={false}
          center={false}
          autoCrop={true}
          responsive={true}
          background={false}
          wheelZoomRatio={0.1}
          modal={true}
          cropBoxResizable={false}
          ready={handleImageReady}
          zoomable={true}
          dragMode="move"
          checkCrossOrigin={true}
          zoomOnWheel={true}
          zoom={(event) => {
            event.currentTarget.cropper.setCropBoxData({ height: CROP_BOX_HEIGHT });
          }}
          onInitialized={(instance) => {
            setCropper(instance);
          }}
          cropBoxMovable={false}
        />
      </Box>
      <Box className={classes.sliderContainer}>
        <Typography variant="body2">Scale: </Typography>
        <PrettoSlider
          color="#3e8bff"
          min={minZoomRatio}
          step={0.0001}
          value={sliderDefaultValue}
          max={1}
          disabled={maxZoomRatio <= minZoomRatio || !src}
          onChange={handleSliderChange}
        />
      </Box>
    </Fragment>
  );
};

export default ProfileImageCropper;
