import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function Move(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        opacity="0.4"
        d="M2.63835 7.33274C2.27016 7.33274 1.97168 7.63122 1.97168 7.99941C1.97168 8.3676 2.27016 8.66607 2.63835 8.66607L7.46677 8.66607V14.5043C7.46677 14.8725 7.76525 15.171 8.13344 15.171C8.50163 15.171 8.80011 14.8725 8.80011 14.5043V8.66607L14.6383 8.66607C15.0065 8.66607 15.305 8.3676 15.305 7.99941C15.305 7.63122 15.0065 7.33274 14.6383 7.33274H8.80011V2.50431C8.80011 2.13612 8.50163 1.83765 8.13344 1.83765C7.76525 1.83765 7.46677 2.13612 7.46677 2.50431V7.33274H2.63835Z"
        fill={`${props.fill || 'white'}`}
      />
      <path
        d="M6.71908 3.75662L8.13329 2.34241L9.54751 3.75662C9.54751 3.75662 10.0189 4.22802 10.4903 3.75662C10.9617 3.28521 10.4903 2.81381 10.4903 2.81381L8.13329 0.456787L5.77627 2.81381C5.77627 2.81381 5.38575 3.36609 5.77627 3.75662C6.16679 4.14714 6.71908 3.75662 6.71908 3.75662Z"
        fill={`${props.fill || 'white'}`}
      />
      <path
        d="M3.89065 9.41347L2.47644 7.99926L3.89065 6.58505C3.89065 6.58505 4.36206 6.11364 3.89065 5.64224C3.41925 5.17083 2.94784 5.64224 2.94784 5.64224L0.59082 7.99926L2.94784 10.3563C2.94784 10.3563 3.50013 10.7468 3.89065 10.3563C4.28118 9.96576 3.89065 9.41347 3.89065 9.41347Z"
        fill={`${props.fill || 'white'}`}
      />
      <path
        d="M9.54751 12.2419L8.13329 13.6561L6.71908 12.2419C6.71908 12.2419 6.24767 11.7705 5.77627 12.2419C5.30487 12.7133 5.77627 13.1847 5.77627 13.1847L8.13329 15.5417L10.4903 13.1847C10.4903 13.1847 10.8808 12.6324 10.4903 12.2419C10.0998 11.8514 9.54751 12.2419 9.54751 12.2419Z"
        fill={`${props.fill || 'white'}`}
      />
      <path
        d="M13.7901 7.99926L12.3759 6.58505C12.3759 6.58505 11.9854 6.03276 12.3759 5.64224C12.7665 5.25171 13.3187 5.64224 13.3187 5.64224L15.6758 7.99926L13.3187 10.3563C13.3187 10.3563 12.8473 10.8277 12.3759 10.3563C11.9045 9.88488 12.3759 9.41347 12.3759 9.41347L13.7901 7.99926Z"
        fill={`${props.fill || 'white'}`}
      />
    </SvgIcon>
  );
}

export default Move;
