import { ResponseType } from 'axios';
import { useMutation, useQuery } from 'react-query';
import { uploadDao } from 'shared/dao/uploadDao';
import { IUploadCreateResponsePayload } from 'shared/interfaces/IUpload';
import { IAxiosErrorResponse, IOnProgress } from 'shared/interfaces/utils/IAxios';

const {
  getSingleMedia: getSingleMediaDao,
  getSingleMediaAsBlob: getSingleMediaAsBlobDao,
  uploadMedia: uploadMediaDao,
} = uploadDao();
export const uploadService = () => {
  const getSingleMedia = (mediaId: string) => {
    return useQuery<string, IAxiosErrorResponse, string>(['media', mediaId], () => getSingleMediaDao(mediaId));
  };
  const getSingleMediaAsBlob = (mediaId: string) => {
    return useQuery<Blob, IAxiosErrorResponse, Blob>(['media', mediaId], () => getSingleMediaAsBlobDao(mediaId), {
      enabled: !!mediaId,
    });
  };
  const uploadMedia = () => {
    return useMutation<
      IUploadCreateResponsePayload,
      IAxiosErrorResponse,
      { formData: FormData; onProgres?: IOnProgress }
    >(({ formData, onProgres }) => uploadMediaDao(formData, onProgres));
  };

  return {
    getSingleMedia,
    getSingleMediaAsBlob,
    uploadMedia,
  };
};
