import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',

      '& .MuiOutlinedInput-root': {
        height: '50px!important',
        fontSize: 14,
        background: '#0e1319',
      },

      '& .MuiFormLabel-root': {
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: '13px',
        letterSpacing: '1px',
        alignItems: 'center',
        color: '#c2ccd8',

        '& .MuiBox-root': {
          padding: '2px 4px 1px 5px',
          border: '1px solid',
          borderRadius: '100%',
          fontSize: '12px',
        },
      },

      '& .MuiSelect-iconOutlined': {
        right: '0',
        top: '22px',
      },

      '& .MuiSelect-iconOpen': {
        top: '2px',
        right: '13px',
      },
    },
    header: {
      marginBottom: 80,
      textAlign: 'center',
      maxWidth: 350,
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      gap: 15,
      alignItems: 'center',
    },
    boxContainer: {
      background: '#1f2733',
      height: 336,
      borderRadius: 5,
      width: 224,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',

      '&:hover': {
        opacity: 0.8,
      },
    },
    icon: {
      width: 38,
      height: 38,
      marginBottom: 20,
    },
  }),
);
