import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    selectionContainer: {
      display: 'flex',
      gap: 40,
      marginTop: 50,
      flexWrap: 'wrap',
      overflowY: 'auto',

      [theme.breakpoints.down('md')]: {
        justifyContent: 'center',
      },
    },
    heading: {
      textAlign: 'center',
    },
    helpText: {
      color: '#60718b',
    },
    primaryText: {
      width: 250,
      textAlign: 'center',
    },
    box: {
      height: 300,
      width: 300,
      border: '1px solid #2d343e',
      borderRadius: '8px !important',
      background: '#0c0f14',
      cursor: 'pointer',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      opacity: 0.5,

      '&:hover': {
        borderStyle: 'solid',
        borderWidth: '1px',
        borderImageSource: 'linear-gradient(253deg, #d51bff, #00d4ff)',
        borderImageSlice: '1',
        backgroundImage: 'linear-gradient(to bottom, #0c0f14, #0c0f14), linear-gradient(253deg, #d51bff, #00d4ff)',
        backgroundOrigin: 'border-box',
        backgroundClip: 'content-box, border-box',
        borderRadius: '8px !important',
      },
    },
    boxActive: {
      opacity: 1,
      borderStyle: 'solid',
      borderWidth: '1px',
      borderImageSource: 'linear-gradient(253deg, #d51bff, #00d4ff)',
      borderImageSlice: '1',
      backgroundImage: 'linear-gradient(to bottom, #0c0f14, #0c0f14), linear-gradient(253deg, #d51bff, #00d4ff)',
      backgroundOrigin: 'border-box',
      backgroundClip: 'content-box, border-box',
      borderRadius: '8px !important',
    },
  }),
);
