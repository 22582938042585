import { Box, Grid, InputLabel } from '@material-ui/core';

import {
  Checkbox,
  ContactInput,
  MinusCircleIcon,
  Select,
  SelectArrowDownIcon,
  TextInput,
  Typography,
} from '@room-match/shared-ui-components';
import { useState } from 'react';

import { useStyles } from './AddNewMember.styles';

const AddNewMember = () => {
  const classes = useStyles();
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [type, setType] = useState('minor');

  const chips = ['Smith Agency, Los Angeles'];

  return (
    <Box component="div" className={classes.root}>
      <Grid item className={classes.header}>
        <Typography fontSize={24}>Add New Member</Typography>
        <Grid item className={classes.typeSelection}>
          <Box
            component="div"
            onClick={() => setType('minor')}
            className={`${classes.selection} ${type === 'minor' ? 'active' : ''}`}
          >
            <Typography>Minor Member</Typography>
            <Typography color="primary" fontSize={14}>
              Under 18 Years
            </Typography>
          </Box>
          <Box
            component="div"
            onClick={() => setType('adult')}
            className={`${classes.selection} ${type === 'adult' ? 'active' : ''}`}
          >
            <Typography>Adult Member</Typography>
            <Typography color="primary" fontSize={14}>
              18 Years+
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid item className={classes.form}>
        {type === 'minor' ? (
          <>
            <Grid item>
              <InputLabel id="representationStatus">Date of Birth</InputLabel>
              <Grid item className={classes.threeColumns}>
                <Grid item>
                  <Select
                    variant="outlined"
                    fullWidth
                    data={[{ key: 'Day', value: 'Day' }]}
                    value="Day"
                    IconComponent={SelectArrowDownIcon}
                    name="representation_status"
                  />
                </Grid>
                <Grid item>
                  <Select
                    variant="outlined"
                    fullWidth
                    data={[{ key: 'Month', value: 'Month' }]}
                    value="Month"
                    IconComponent={SelectArrowDownIcon}
                    name="representation_status"
                  />
                </Grid>
                <Grid item>
                  <Select
                    variant="outlined"
                    fullWidth
                    data={[{ key: 'Year', value: 'Year' }]}
                    value="Year"
                    IconComponent={SelectArrowDownIcon}
                    name="representation_status"
                  />
                </Grid>
              </Grid>
              <Grid item className={classes.checkboxContainer}>
                <Checkbox
                  value={isChecked}
                  onChange={(e) => setIsChecked(e.target.checked)}
                  label="This profile will be managed by the minor"
                  name="minor"
                  labelPlacement="end"
                />
              </Grid>
            </Grid>
            {isChecked ? (
              <Grid item>
                <Grid item style={{ marginBottom: 5 }}>
                  <InputLabel id="representationStatus" style={{ marginBottom: '-15px' }}>
                    Minor Cell Number
                  </InputLabel>
                  <ContactInput
                    country={'us'}
                    name="contact_number"
                    variant="outlined"
                    placeholder="Type here..."
                    dropdownStyle={{ height: '50px' }}
                    showLabel={false}
                  />
                </Grid>
                <Grid item>
                  <TextInput label={'Minor Email Address'} placeholder="Enter email for audition notifications…" />
                </Grid>
              </Grid>
            ) : (
              ''
            )}
            <Grid item>
              <InputLabel id="representationStatus">Does the minor have a current work permit?</InputLabel>
              <Select
                variant="outlined"
                fullWidth
                data={[
                  { key: 'Yes', value: 'Yes' },
                  { key: 'No', value: 'No' },
                ]}
                value="Yes"
                IconComponent={SelectArrowDownIcon}
                name="representation_status"
              />
            </Grid>
            <Grid item>
              <InputLabel id="representationStatus">Work permit expiration date</InputLabel>
              <Select
                variant="outlined"
                fullWidth
                data={[{ key: '24 June 2024', value: '24 June 2024' }]}
                value="24 June 2024"
                IconComponent={SelectArrowDownIcon}
                name="representation_status"
              />
            </Grid>
          </>
        ) : (
          <>
            <Grid item style={{ marginBottom: 5 }}>
              <InputLabel id="representationStatus" style={{ marginBottom: '-10px' }}>
                Cell Number
              </InputLabel>
              <ContactInput
                country={'us'}
                name="contact_number"
                variant="outlined"
                placeholder="Type here..."
                dropdownStyle={{ height: '50px' }}
                showLabel={false}
              />
            </Grid>
            <Grid item>
              <TextInput label={'Email Address'} placeholder="Enter email for audition notifications…" />
            </Grid>
          </>
        )}
        <Grid item>
          <InputLabel id="representationStatus">Representation Status</InputLabel>
          <Select
            variant="outlined"
            fullWidth
            data={[{ key: 'Represented', value: 'Represented' }]}
            value="Represented"
            IconComponent={SelectArrowDownIcon}
            name="representation_status"
          />
        </Grid>
        <Grid item>
          <TextInput label={'Add Representatives'} placeholder="Type / Search Agency…" />
        </Grid>
        <Grid item>
          {chips.map((i) => (
            <Grid item className={classes.chips}>
              <Typography fontSize={14}>{i}</Typography>
              <MinusCircleIcon />
            </Grid>
          ))}
        </Grid>
        {type === 'adult' ? (
          <>
            <Grid item>
              <TextInput label={'Stage Name'} placeholder="Enter stage name…" />
            </Grid>
            <Grid item style={{ marginBottom: 5 }}>
              <TextInput label={'Legal Name'} placeholder="First name…" />
              <TextInput className={classes.noLabelFix} placeholder="Middle name…" />
              <TextInput className={classes.noLabelFix} placeholder="Last name…" />
            </Grid>
            <Grid item>
              <InputLabel id="representationStatus">Gender / Identity</InputLabel>
              <Select
                variant="outlined"
                fullWidth
                data={[{ key: 'Select Gender', value: 'Select Gender' }]}
                value="Select Gender"
                IconComponent={SelectArrowDownIcon}
                name="representation_status"
              />
              <Select
                className={classes.selectNoLabelFix}
                variant="outlined"
                fullWidth
                data={[{ key: 'Identifies As', value: 'Identifies As' }]}
                value="Identifies As"
                IconComponent={SelectArrowDownIcon}
                name="representation_status"
              />
            </Grid>
            <Grid item>
              <InputLabel id="representationStatus">Your SAG Status</InputLabel>
              <Select
                variant="outlined"
                fullWidth
                data={[{ key: 'Select', value: 'Select' }]}
                value="Select"
                IconComponent={SelectArrowDownIcon}
                name="representation_status"
              />
              <TextInput className={classes.noLabelFix} placeholder="SAG ID name…" />
            </Grid>
            <Grid item>
              <InputLabel id="representationStatus">Select Other Unions You Belong To</InputLabel>
              <Select
                variant="outlined"
                fullWidth
                data={[{ key: 'Select', value: 'Select' }]}
                value="Select"
                IconComponent={SelectArrowDownIcon}
                name="representation_status"
              />
            </Grid>
          </>
        ) : (
          ''
        )}
      </Grid>
    </Box>
  );
};

export default AddNewMember;
