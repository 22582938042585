import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    bodyContainer: {
      backgroundSize: 'cover',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '15px',
      minHeight: '100vh',
      '& .MuiOutlinedInput-input:-webkit-autofill': {
        '-webkit-box-shadow': '0 0 0 100px #0d1117 inset',
      },
    },
    backgroundContainer: {
      top: 0,
      width: '100%',
      height: 'calc(100vh - 60%)',
      display: 'flex',
      zIndex: -1,
      overflow: 'hidden',
      position: 'fixed',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundImage: 'url(/casting-background.png)',
      backgroundSize: 'auto 100%',
      backgroundRepeat: 'repeat',
      backgroundPosition: 'center',
    },
    bgImage: {
      height: 'calc(100vh - 60%)',
      width: 'auto',
    },
    bgImageOverlay: {
      width: '100%',
      height: 'calc(100vh - 69%)',
      position: 'absolute',
      backgroundImage: 'url(/background-overlay.svg)',
      backgroundRepeat: 'repeat',
      backgroundSize: 'auto calc(100vh - 60%)',
    },
    mainContainer: {
      width: '420px',
      padding: '15px 30px',
      background: 'none !important',
      boxShadow: 'none !important',
      [theme.breakpoints.down(500)]: {
        width: '100%',
      },
      '& fieldset': {
        border: 'solid 1px #2d343e',
      },
    },
    header: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
    },
    logoContainer: {
      padding: '0px 20px 15px 20px',
    },
    logoContainerWithoutHeading: {
      padding: '0px 20px 25px 20px',
    },
    logoContainerForSendVerification: {
      padding: '0px 20px 50px 20px',
    },
    logoCls: {
      height: 'auto',
      width: '100%',
      maxWidth: '210px',
    },
    heading: {
      fontSize: 40,
      marginBottom: 10,
    },
    subHeading: {
      fontSize: 16,
    },
  }),
);
