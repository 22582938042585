import { Box, Button, Grid, InputLabel, useMediaQuery, useTheme } from '@material-ui/core';

import { Backdrop, Select, SelectArrowDownIcon, Typography } from '@room-match/shared-ui-components';
import { Link } from 'react-router-dom';

import { useStyles } from './JobPreferences.styles';

const JobPreferences = () => {
  const classes = useStyles();

  return (
    <Grid item className={classes.root}>
      <Grid item style={{ marginBottom: 15 }}>
        <Typography fontSize={25}>Job Preferences</Typography>
        <Typography>Show me projects that suit the following criteria:</Typography>
      </Grid>
      <Grid className={classes.row}>
        <Grid item lg={6} md={12} xs={12}>
          <Typography className={classes.heading}>Category</Typography>
          <Grid item>
            <Select
              variant="outlined"
              fullWidth
              data={[{ key: 'All', value: 'All' }]}
              value="All"
              IconComponent={SelectArrowDownIcon}
              name="representation_status"
            />
          </Grid>
        </Grid>
        <Grid item lg={6} md={12} xs={12}>
          <Typography className={classes.heading}>Job Type</Typography>
          <Grid item>
            <Select
              variant="outlined"
              fullWidth
              data={[{ key: 'Commercial, Film, Television', value: 'Commercial, Film, Television' }]}
              value="Commercial, Film, Television"
              IconComponent={SelectArrowDownIcon}
              name="representation_status"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid className={classes.row}>
        <Grid item lg={6} md={12} xs={12}>
          <Typography className={classes.heading}>Gender</Typography>
          <Grid item>
            <Select
              variant="outlined"
              fullWidth
              data={[{ key: 'All', value: 'All' }]}
              value="All"
              IconComponent={SelectArrowDownIcon}
              name="representation_status"
            />
          </Grid>
        </Grid>
        <Grid item lg={6} md={12} xs={12}>
          <Typography className={classes.heading}>Paid</Typography>
          <Grid item>
            <Select
              variant="outlined"
              fullWidth
              data={[{ key: 'All', value: 'All' }]}
              value="All"
              IconComponent={SelectArrowDownIcon}
              name="representation_status"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid className={classes.row}>
        <Grid item lg={6} md={12} xs={12}>
          <Typography className={classes.heading}>Age Range</Typography>
          <Grid className={classes.one__row}>
            <Grid item lg={6} md={6} xs={6}>
              <Select
                variant="outlined"
                fullWidth
                data={[{ key: '20', value: '20' }]}
                value="20"
                IconComponent={SelectArrowDownIcon}
                name="representation_status"
              />
            </Grid>
            <Grid item lg={6} md={6} xs={6}>
              <Select
                variant="outlined"
                fullWidth
                data={[{ key: '20', value: '20' }]}
                value="20"
                IconComponent={SelectArrowDownIcon}
                name="representation_status"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={6} md={12} xs={12}>
          <Typography className={classes.heading}>Location</Typography>
          <Grid item>
            <Select
              variant="outlined"
              fullWidth
              data={[{ key: 'United States', value: 'United States' }]}
              value="United States"
              IconComponent={SelectArrowDownIcon}
              name="representation_status"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid className={classes.row}>
        <Grid item lg={12} md={12} xs={12}>
          <Typography className={classes.heading}>Area</Typography>
          <Grid item>
            <Select
              variant="outlined"
              fullWidth
              data={[{ key: 'All', value: 'All' }]}
              value="All"
              IconComponent={SelectArrowDownIcon}
              name="representation_status"
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default JobPreferences;
