import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: 10,
    },
    buttonCls: {
      marginTop: 30,
      height: '60px !important',
      borderRadius: 8,
      textTransform: 'unset',
      textDecoration: 'none!important',
      width: 390,
      fontSize: 16,
    },
    logoCls: {
      height: 'auto',
      width: '100%',
      maxWidth: 160,
      marginBottom: 20,
    },
    clearButton: {
      background: 'none',

      '&:hover': {
        background: 'none',
        opacity: 0.7,
      },
    },
    link: {
      textDecoration: 'none',
    },
  }),
);
