import { ENDPOINTS } from 'shared/constants/ENDPOINTS';
import { useAxios } from 'shared/hooks/useAxios';
import { ICheckInMarkPayload, ICheckInResponsePayload } from 'shared/interfaces/ICheckIn';
import { IStudioSessionGetQuestionListResponsePayload } from 'shared/interfaces/IStudioSession';
import { authToken } from 'shared/utils/authToken';

const { GET, PATCH } = useAxios();
export const checkInDao = () => {
  const { getAuthToken } = authToken();
  const getCheckInDetails = async (sessionId: string) => {
    const response = await GET<ICheckInResponsePayload>({
      url: `${ENDPOINTS.SESSION}/${sessionId}/check_in`,
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
      },
    });
    return response.data;
  };

  const getSessionQuestions = async (sessionId: string) => {
    const response = await GET<IStudioSessionGetQuestionListResponsePayload>({
      url: `${ENDPOINTS.SESSION}/${sessionId}/questions`,
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
      },
    });
    return response.data;
  };

  const markAsCheckIn = async (sessionId: string, payload: ICheckInMarkPayload) => {
    const response = await PATCH({
      url: `${ENDPOINTS.SESSION}/${sessionId}/check_in`,
      data: payload,
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
      },
    });

    return response.data;
  };

  return {
    getCheckInDetails,
    getSessionQuestions,
    markAsCheckIn,
  };
};
