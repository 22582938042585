import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function PropsIcon(props: SvgIconProps) {
  return (
    <SvgIcon width="22" height="18" viewBox="0 0 22 18" xmlns="http://www.w3.org/2000/svg" {...props}>
      <defs>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="62ptwl9i3a">
          <stop stopColor="#3E8BFF" offset="0%" />
          <stop stopColor="#3E8BFF" offset="100%" />
        </linearGradient>
      </defs>
      <path
        d="m21.942 13.986-2.23-4.016c.074-1.447 1.562-2.22 1.576-2.227a.458.458 0 0 0 .24-.298l.458-1.834a.458.458 0 0 0-.24-.52l-2.75-1.376a.458.458 0 0 0-.204-.048h-1.375V2.292h1.46a1.375 1.375 0 1 0 0-.917h-1.46a1.375 1.375 0 1 0-2.75 0h-5.5a1.375 1.375 0 0 0-2.75 0H3.124a1.375 1.375 0 1 0 0 .917H8.25v1.375H6.875c-.03 0-.06.003-.09.009l-2.292.458a.458.458 0 0 0-.315.235L.053 12.16a.458.458 0 0 0 .2.624l1.834.917c.22.11.489.026.607-.19l1.89-3.464v6.91c0 .254.205.459.458.459h7.333a.458.458 0 0 0 .458-.459v-6.91l1.89 3.463c.118.217.386.301.607.19l1.833-.916a.458.458 0 0 0 .2-.624l-4.125-7.792a.458.458 0 0 0-.315-.235l-2.291-.458a.457.457 0 0 0-.09-.01H9.167V2.293H16.5v1.375h-1.375a.458.458 0 0 0-.205.048l-.458.23a.458.458 0 0 0-.205.614c.097.19.314.286.52.23a2.276 2.276 0 0 0 4.357.02l1.874.936-.322 1.287c-.471.286-1.645 1.14-1.858 2.593h-1.182a.458.458 0 1 0 0 .917h1.334l2.034 3.661c-.25.393-.552.752-.897 1.066-.896.817-1.959 1.23-3.159 1.23-.932 0-1.789-.19-2.546-.567a.458.458 0 0 0-.408.82c.885.441 1.88.664 2.954.664 1.43 0 2.745-.517 3.806-1.496.797-.736 1.172-1.476 1.187-1.507a.458.458 0 0 0-.009-.427zM20.167 1.375a.458.458 0 1 1 0 .917.458.458 0 0 1 0-.917zm-18.334.917a.458.458 0 1 1 0-.917.458.458 0 0 1 0 .917zm8.385 4.449.592.592-1.34 1.34.833-3.996a.436.436 0 0 0 0-.083l.566.848-.651.65a.458.458 0 0 0 0 .649zm-3.019-.648-.65-.651.564-.848a.436.436 0 0 0 0 .083l.833 3.996-1.34-1.34.593-.592a.458.458 0 0 0 0-.648zm.832-1.51h1.355L8.71 7.836 8.03 4.583zM5.5 16.5V8.25a.458.458 0 0 0-.86-.22L2.1 12.685l-1.018-.51L4.886 4.99l.994-.198-.303.454a.458.458 0 0 0 .057.578l.593.593-.593.592a.458.458 0 0 0 0 .648l2.616 2.616V16.5H5.5zm10.834-4.325-1.019.509-2.538-4.654a.458.458 0 0 0-.86.22v8.25h-2.75v-6.227l2.615-2.616a.458.458 0 0 0 0-.648l-.592-.592.592-.593a.458.458 0 0 0 .058-.578l-.303-.455.993.2 3.804 7.184zm-9-10.8a.458.458 0 0 1 .916 0h-.917zm8.25 0a.458.458 0 1 1 .916 0h-.917zM16.957 5.5a1.373 1.373 0 0 1-1.29-.917h2.58c-.192.548-.709.915-1.29.917z"
        fill="url(#62ptwl9i3a)"
        fillRule="nonzero"
      />
    </SvgIcon>
  );
}

export default PropsIcon;
