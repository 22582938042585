import { Box, Button, Grid, InputLabel } from '@material-ui/core';

import {
  ContactInput,
  FileUpload,
  MinusCircleIcon,
  PlusIconTwoTone,
  Select,
  SelectArrowDownIcon,
  TextInput,
  Typography,
} from '@room-match/shared-ui-components';
import { useState } from 'react';

import { useStyles } from './AddMembers.styles';

type Props = {
  setActiveStep(step: number): void;
};

const AddMembers: React.FC<Props> = ({ setActiveStep }) => {
  const classes = useStyles();

  return (
    <Box component="div" className={classes.root}>
      <Grid item className={classes.header}>
        <Typography fontSize={24}>Add Family / Group Members</Typography>
      </Grid>
      <Grid item className={classes.form}>
        <Box component="div" className={classes.boxContainer} onClick={() => setActiveStep(3)}>
          <PlusIconTwoTone className={classes.icon} />
          <Typography fontSize={15}>Add New Member</Typography>
        </Box>
      </Grid>
    </Box>
  );
};

export default AddMembers;
