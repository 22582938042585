import { Box, Grid, InputLabel, TextField, Button } from '@material-ui/core';

import {
  Backdrop,
  MinusCircleIcon,
  Select,
  SelectArrowDownIcon,
  TextInput,
  Typography,
} from '@room-match/shared-ui-components';
import { useEffect, useState } from 'react';
import { IAgency } from 'shared/interfaces/utils/IAgency';

import { useStyles } from './ProfileClaim.styles';
import { useDebounce } from 'use-debounce';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { getErrorMessage } from '@room-match/shared-utils';
import { ContactInput } from 'shared/elements';
import { profileService } from 'shared/services/profileService';

type Props = {
  data?: any;
  representatives: any[];
  setRepresentatives(representatives: any[]): void;
  setActiveStep(activeStep: number): void;
  setIsProfileMatch(isProfileMatch: boolean): void;
};

const { claimGhostedProfile } = profileService();

const ProfileClaim: React.FC<Props> = ({
  data,
  representatives,
  setRepresentatives,
  setActiveStep,
  setIsProfileMatch,
}) => {
  const classes = useStyles();

  const { mutateAsync, isLoading } = claimGhostedProfile();

  const handleClaim = async () => {
    if (data) {
      const filteredRepresentatives = representatives.filter((k) => k != data.data);
      await mutateAsync(
        { profileId: data.data.id },
        {
          onSuccess: () => {
            setRepresentatives(filteredRepresentatives);
            setActiveStep(3);
            setIsProfileMatch(false);
          },
        },
      );
    }
  };

  return (
    <>
      {!isLoading ? (
        <Box component="div" className={classes.root}>
          <Grid item className={classes.header}>
            <Typography fontSize={24}>Profile Found</Typography>
            <Typography>A profile matching your details has been found in our database.</Typography>
          </Grid>
          <Grid item className={classes.body}>
            <Grid item style={{ marginBottom: 5 }} className={classes.imgContainer}>
              <img className={classes.img} src="assets/no-image.svg" />
              <Typography fontSize={18}>
                {data && data.data.attributes.talent ? data.data.attributes.talent?.data.attributes.full_name : 'N/A'}
              </Typography>
              <Typography color={'primary'} fontSize={15}>
                {data && data.data.attributes.agency_name ? data.data.attributes.agency_name : 'N/A'}
              </Typography>
            </Grid>
            <Grid item style={{ margin: 'auto' }}>
              <Button
                color="primary"
                variant="contained"
                disableElevation
                fullWidth
                className={classes.buttonCls}
                onClick={handleClaim}
              >
                This is me, Proceed!
              </Button>
              <Button
                color="primary"
                variant="contained"
                disableElevation
                fullWidth
                className={`${classes.buttonCls} ${classes.confirmButton}`}
                onClick={() => {
                  setActiveStep(3);
                  setIsProfileMatch(false);
                }}
              >
                This is not me
              </Button>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Backdrop isLoading={isLoading} />
      )}
    </>
  );
};

export default ProfileClaim;
