import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    chipContainer: {
      width: '40vw',
      '& .MuiChip-root': {
        margin: '0 5px 10px 0',
      },
    },
  }),
);
