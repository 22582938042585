import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      gap: '2.5em',
      '& > div:first-child': {
        maxWidth: 293,
        textAlign: 'center',
        width: '100%',

        [theme.breakpoints.down(800)]: {
          margin: '0 auto',
        },
      },
      '& > div:last-child': {
        width: '100%',
      },
      '& > div:last-child > div:first-child': {
        marginBottom: 20,
      },

      [theme.breakpoints.down(800)]: {
        flexDirection: 'column',
      },
    },
    imgContainer: {
      maxWidth: 293,
      height: 403,
      borderStyle: 'solid',
      borderWidth: '2px',
      borderImageSource: 'linear-gradient(253deg, #d51bff, #00d4ff)',
      borderImageSlice: '1',
      backgroundImage: 'linear-gradient(to bottom, #0c0f14, #0c0f14), linear-gradient(253deg, #d51bff, #00d4ff)',
      backgroundOrigin: 'border-box',
      backgroundClip: 'content-box, border-box',
      borderRadius: '8px !important',
      marginBottom: 20,
    },
    img: {
      margin: 12,
      width: '92%',
      height: '94%',
      objectFit: 'cover',
      borderRadius: 5,
    },
    yellow: {
      color: '#e5bd2c',
    },
    primary: {
      color: '#3e8bff',
    },
    slashes: {
      color: '#333e4e',
    },
    dataContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: 10,
      marginBottom: 20,

      '& > div': {
        display: 'flex',
        justifyContent: 'space-between',
      },
    },
    dataHeading: {
      marginBottom: 5,
    },
    btnContainer: {
      display: 'flex',
      gap: '1em',
    },
    primaryBtn: {
      height: 50,
      border: '1px solid #3e8bff',
      fontSize: 15,
      background: 'none',
    },
    secondaryBtn: {
      height: 50,
      border: '1px solid #e5bd2c',
      fontSize: 15,
      background: 'none',
    },
    dashboard: {
      marginTop: 20,

      '& .MuiButtonBase-root': {
        height: 50,
        fontSize: 15,
      },
    },
    link: {
      textDecoration: 'none',
    },
    clearButton: {
      background: 'none',

      '&:hover': {
        background: 'none',
        opacity: 0.7,
      },
    },
  }),
);
