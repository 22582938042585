import { ENDPOINTS } from 'shared/constants/ENDPOINTS';
import { AuditionType } from 'shared/enums/AuditionType';
import { useAxios } from 'shared/hooks/useAxios';
import {
  ITalentMutateResponsePayload,
  ITalentResumeResponsePayload,
  ITalentSkillResponsePayload,
  ITalentStatisticsResponsePayload,
  ITalentUpdatePayload,
} from 'shared/interfaces/ITalent';
import { authToken } from 'shared/utils/authToken';

const { GET, PATCH } = useAxios();
export const talentDao = () => {
  const { getAuthToken } = authToken();
  const updateTalent = async (payload: ITalentUpdatePayload) => {
    const response = await PATCH({
      url: `${ENDPOINTS.TALENTS}`,
      data: payload,
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
      },
    });

    return response.data;
  };

  const getStatistics = async () => {
    const response = await GET<ITalentStatisticsResponsePayload>({
      url: `${ENDPOINTS.TALENTS}/statistics`,
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
      },
    });
    return response.data;
  };

  const getResume = async () => {
    const response = await GET<ITalentResumeResponsePayload>({
      url: `${ENDPOINTS.TALENTS}/resume`,
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
      },
    });
    return response.data;
  };

  const getSkills = async () => {
    const response = await GET<ITalentSkillResponsePayload>({
      url: `${ENDPOINTS.TALENTS}/skills`,
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
      },
    });
    return response.data;
  };

  return {
    getSkills,
    getResume,
    updateTalent,
    getStatistics,
  };
};
