import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',

      '& .MuiOutlinedInput-root': {
        height: '50px!important',
        fontSize: 14,
        background: '#0e1319',
      },

      '& .MuiFormLabel-root': {
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: '13px',
        letterSpacing: '1px',
        alignItems: 'center',
        color: '#c2ccd8',

        '& .MuiBox-root': {
          padding: '2px 4px 1px 5px',
          border: '1px solid',
          borderRadius: '100%',
          fontSize: '12px',
        },
      },

      '& .MuiSelect-iconOutlined': {
        right: '0',
        top: '22px',
      },

      '& .MuiSelect-iconOpen': {
        top: '2px',
        right: '13px',
      },
    },
    header: {
      marginBottom: 80,
      textAlign: 'center',
      maxWidth: 283,
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      gap: 15,
      alignItems: 'center',
    },
    imgContainer: {
      background: '#1f2733',
      height: 400,
      borderRadius: 5,
      width: 283,
    },
    btn: {
      height: 44,
      maxWidth: 283,
      width: '100%',
      marginTop: 10,
      border: '1px solid #3e8bff',
      background: '#0c0f14',
      borderRadius: 8,
    },
  }),
);
