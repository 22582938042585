import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

const NextImageIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="31" height="34" viewBox="0 0 31 34" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="#FFF" fill-rule="nonzero">
        <path d="M30.5 17c0 .384-.147.768-.44 1.06l-15 15a1.499 1.499 0 1 1-2.12-2.12L26.88 17 12.94 3.06A1.499 1.499 0 1 1 15.06.94l15 15c.293.292.44.676.44 1.06zm-12.44-1.06-15-15A1.499 1.499 0 1 0 .94 3.06L14.88 17 .94 30.94a1.499 1.499 0 1 0 2.12 2.12l15-15a1.496 1.496 0 0 0 0-2.12z" />
        <path d="M30.5 17c0 .384-.147.768-.44 1.06l-15 15a1.499 1.499 0 1 1-2.12-2.12L26.88 17 12.94 3.06A1.499 1.499 0 1 1 15.06.94l15 15c.293.292.44.676.44 1.06zm-12.44-1.06-15-15A1.499 1.499 0 1 0 .94 3.06L14.88 17 .94 30.94a1.499 1.499 0 1 0 2.12 2.12l15-15a1.496 1.496 0 0 0 0-2.12z" />
      </g>
    </SvgIcon>
  );
};

export default NextImageIcon;
