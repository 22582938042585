import { createStyles, makeStyles } from '@material-ui/core';
import { NoEncryption } from '@material-ui/icons';

export const useStyles = makeStyles((theme) =>
  createStyles({
    chipContainer: {
      width: '40vw',
      padding: 30,
      display: 'flex',
      flexDirection: 'column',
      '& .MuiChip-root': {
        margin: '0 5px 10px 0',
      },
    },
    dialogContentContainer: {
      padding: '8px 24px 24px',
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      gap: '10px',
      marginTop: '20px',
    },
    subHeading: {
      // fontWeight: '',
    },
    groupAccordion: {
      backgroundColor: 'transparent',
      '&.MuiPaper-root': {
        boxShadow: 'none',
        margin: '5px 0 0',
        '&:before': {
          display: 'none',
        },
      },
      '& .MuiAccordionSummary-content': {
        margin: '0',
      },
      '& .MuiAccordionSummary-root.Mui-expanded': {
        minHeight: 'unset',
      },
      '& .MuiAccordionSummary-root': {
        padding: '0',
        borderBottom: '1px solid #2d343e',
        minHeight: 'unset',
        marginBottom: 10,
      },
      '& .MuiIconButton-edgeEnd': {
        marginRight: '0',
      },
      '& .MuiAccordionDetails-root': {
        padding: '10px 0 0',
      },
      '& .MuiIconButton-root': {
        padding: '0',
      },
    },
  }),
);
