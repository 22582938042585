import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      gap: '1.5em',
      [theme.breakpoints.down(1260)]: {
        flexDirection: 'column',
      },
    },
    buttonCls: {
      height: '50px !important',
      borderRadius: 8,
      textTransform: 'unset',
      width: '100%',
      fontSize: 15,
    },
    backBtn: {
      background: '#303946',
      color: '#fff',
      fontSize: 15,
      '&:hover': {
        background: '#303946',
      },
    },
    logoCls: {
      height: 'auto',
      width: '100%',
      maxWidth: 160,
      marginBottom: 20,
    },
    clearButton: {
      background: 'none',

      '&:hover': {
        background: 'none',
        opacity: 0.7,
      },
    },
    container: {
      background: '#0b0f14',
      border: '1px solid #2d343e',
      borderRadius: 8,
      padding: 20,
      width: '100%',
      position: 'relative',
    },
    information: {
      maxWidth: 500,
      height: '100%',
      width: 'auto',
      minWidth: 500,
      [theme.breakpoints.down(1260)]: {
        maxWidth: 'unset',
        minWidth: 'unset',
      },
    },
    body: {
      height: 'auto',
      // paddingBottom: 100,
      [theme.breakpoints.down('md')]: {
        height: 'unset',
      },
    },
    completeBody: {
      padding: 35,
    },
    header: {
      height: 50,
      display: 'flex',
      justifyContent: 'space-between',
      position: 'relative',
      borderBottom: '1px solid #2d343e',
      alignItems: 'center',
      paddingBottom: 10,
      margin: '-10px 0 30px',

      [theme.breakpoints.down(950)]: {
        flexDirection: 'column',
        paddingBottom: 70,
        // textAlign: 'center',
        alignItems: 'flex-start',
      },

      [theme.breakpoints.down(692)]: {
        paddingBottom: 100,
      },

      '&::before': {
        content: '""',
        background: '#3e8bff',
        height: '1px',
        position: 'absolute',
        width: '50px',
        bottom: '-1px',
      },
    },
    stepperContainer: {
      [theme.breakpoints.down(950)]: {
        marginTop: 15,
      },
    },
    footer: {
      position: 'absolute',
      display: 'flex',
      bottom: 30,
      width: '100%',
      gap: '1.5em',
      '& > div': {
        maxWidth: 320,
        width: '100%',
      },

      [theme.breakpoints.down('md')]: {
        position: 'unset',
        marginTop: 57,
      },

      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        gap: '0.5em',

        '& > div': {
          maxWidth: 'unset',
        },
      },
    },
  }),
);
