import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    menuItem: {
      '&:hover': {
        color: '#fff !important',
        '& span': {
          color: '#fff !important',
        },
      },
    },
    select__value: {
      color: theme.palette.primary.main,
    },
    select: {
      '& .MuiSelect-iconOutlined': {
        right: '0',
        top: '22px',
      },

      '& .MuiSelect-iconOpen': {
        top: '2px',
        right: '13px',
      },
    },
  }),
);
