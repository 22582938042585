import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function OutlinedUserIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12.1 0c3.232 0 6.27 1.259 8.556 3.544A12.022 12.022 0 0 1 24.2 12.1c0 3.232-1.259 6.27-3.544 8.556A12.022 12.022 0 0 1 12.1 24.2c-3.232 0-6.27-1.259-8.556-3.544A12.022 12.022 0 0 1 0 12.1c0-3.232 1.259-6.27 3.544-8.556A12.022 12.022 0 0 1 12.1 0zm0 14.282c-3.404 0-6.301 2.584-6.728 5.865a10.443 10.443 0 0 0 6.728 2.447c2.491 0 4.852-.876 6.693-2.36a6.733 6.733 0 0 0-1.928-3.979 6.695 6.695 0 0 0-4.765-1.973zm0-12.676C6.314 1.606 1.606 6.314 1.606 12.1c0 2.44.84 4.752 2.346 6.606l.097.115.034-.123a8.315 8.315 0 0 1 2.097-3.56l.234-.225a8.33 8.33 0 0 1 2.48-1.6l.17-.067-.043-.03a5.147 5.147 0 0 1-2.066-3.882l-.006-.246c0-2.84 2.31-5.15 5.151-5.15 2.84 0 5.151 2.31 5.151 5.15 0 1.645-.78 3.161-2.07 4.126l-.045.031.164.064c.892.37 1.714.895 2.435 1.556l.265.255a8.347 8.347 0 0 1 2.115 3.577l.034.125.098-.115a10.44 10.44 0 0 0 2.341-6.26l.006-.347c0-5.786-4.708-10.494-10.494-10.494zm0 3.938a3.549 3.549 0 0 0-3.545 3.544 3.549 3.549 0 0 0 3.545 3.545 3.549 3.549 0 0 0 3.545-3.545A3.549 3.549 0 0 0 12.1 5.544z"
        transform="translate(.4 .273)"
        fill={`${props.htmlColor || '#6B737C'}`}
        fill-rule="nonzero"
      />
    </SvgIcon>
  );
}

export default OutlinedUserIcon;
