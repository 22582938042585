import React, { useState, useEffect } from 'react';
import { Box, Button, Grid, IconButton, InputAdornment } from '@material-ui/core';
import { Backdrop, BackIcon, Typography } from '@room-match/shared-ui-components';

import { useStyles } from './RegistrationForm.styles';

import { Stepper } from 'components/Stepper';
import { ProfileSelection } from './ProfileSelection';
import { ContactInformation, PersonalInformation, ProfileImageAdult } from './AdultForm';
import { AddMembers, AddNewMember, GuardianContactInformation, ProfileImage } from './MinorForm';
import { Success } from './Success';
import { ITalentUpdatePayload } from 'shared/interfaces/ITalent';

import { talentService } from 'shared/services/talentService';

import * as yup from 'yup';
import { FormikProps, useFormik } from 'formik';
import { ls } from 'shared/utils/ls';
import { IProfileCreatePayload } from 'shared/interfaces/IProfile';
import { RepresentationType } from 'shared/enums/RepresentationType';
import { profileService } from 'shared/services/profileService';
import { uploadService } from 'shared/services/uploadService';
import { ProfileClaim } from './ProfileClaim';

const { getLS, removeLS } = ls();

const { updateTalent } = talentService();
const { createProfile, setProfilePrimaryImage, getProfileMatch } = profileService();
const { uploadMedia } = uploadService();

const RegistrationForm = () => {
  const classes = useStyles();
  const { mutateAsync, isLoading } = updateTalent();
  const { mutateAsync: mutateAsyncCreateProfile, isLoading: isLoadingCreateProfile } = createProfile();
  const { mutateAsync: mutateAsyncUploadMedia, isLoading: isLoadingUploadMedia } = uploadMedia();
  const { mutateAsync: mutateAsyncSetPrimaryImage, isLoading: isSetPrimaryImageLoading } = setProfilePrimaryImage();
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const { data: profileMatchData } = getProfileMatch(true, firstName, lastName);

  const [activeStep, setActiveStep] = useState(0);
  const [steps, setSteps] = useState(4);
  const [profileType, setProfileType] = useState('adult');
  const [representatives, setRepresentatives] = useState<any[]>([]);
  const [disabled, setDisabled] = useState<boolean>(false);

  const phoneRegExp =
    /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/;

  const [uploadedImg, setUploadedImg] = useState<{
    name: string;
    type: string;
    url: string;
    file: File | undefined;
    imageAttr: { width: number; height: number };
  } | null>(null);

  const [isProfileMatch, setIsProfileMatch] = useState<boolean>(false);

  const initialValues: ITalentUpdatePayload = {
    contact_no: '',
    country: 'United States',
    country_code: 'us',
    representation: 'agency_representation',
    stage_name: '',
    first_name: '',
    middle_name: '',
    last_name: '',
    gender: '',
    identifies_as: '',
    sag_status: '',
    sag_id: '',
    union: '',
  };

  const nonRepresentedValues: IProfileCreatePayload = {
    representation_type: RepresentationType.FREELANCE,
    country: 'United States',
    note: '',
    agency_id: '',
    confirmed_agreement: true,
    profile_type: 'Actor',
  };

  const handleUploadImg = async (
    name: string,
    type: string,
    url: string,
    file?: File,
    imageAttr?: { width: number; height: number },
  ) => {
    if (file && imageAttr) {
      setUploadedImg({ name: name, type: type, url: url, file: file, imageAttr: imageAttr });
    }
  };

  const handleSubmit = async (values: ITalentUpdatePayload) => {
    await mutateAsync(values, {
      onSuccess: () => {
        if (representatives.length !== 0) {
          representatives.map((i) => {
            const agencyValue: IProfileCreatePayload = {
              representation_type: RepresentationType.AGENCY_REPRESENTATION,
              country: values.country || 'United States',
              note: '',
              agency_id: i.id,
              confirmed_agreement: true,
              profile_type: 'Actor',
            };
            mutateAsyncCreateProfile(agencyValue, {
              onSuccess: (values) => {
                if (uploadedImg) {
                  if (uploadedImg.file) {
                    const formData = new FormData();
                    formData.append('attachment', uploadedImg.file);
                    formData.append('file_name', uploadedImg.name);
                    formData.append('file_width', uploadedImg.imageAttr.width.toString());
                    formData.append('file_height', uploadedImg.imageAttr.height.toString());
                    formData.append('file_size', uploadedImg.file.size.toString());
                    formData.append('file_type', uploadedImg.type);
                    mutateAsyncSetPrimaryImage({ profileId: values.data.id, formData });
                  }
                }
              },
            });
          });
        } else {
          mutateAsyncCreateProfile(nonRepresentedValues, {
            onSuccess: (values) => {
              if (uploadedImg) {
                if (uploadedImg.file) {
                  const formData = new FormData();
                  formData.append('attachment', uploadedImg.file);
                  formData.append('file_name', uploadedImg.name);
                  formData.append('file_width', uploadedImg.imageAttr.width.toString());
                  formData.append('file_height', uploadedImg.imageAttr.height.toString());
                  formData.append('file_size', uploadedImg.file.size.toString());
                  formData.append('file_type', uploadedImg.type);
                  mutateAsyncSetPrimaryImage({ profileId: values.data.id, formData });
                }
              }
            },
          });
        }
      },
    });

    if (uploadedImg) {
      if (uploadedImg.file) {
        const formData = new FormData();
        formData.append('attachment', uploadedImg.file);
        formData.append('file_name', uploadedImg.name);
        formData.append('file_width', uploadedImg.imageAttr.width.toString());
        formData.append('file_height', uploadedImg.imageAttr.height.toString());
        formData.append('file_size', uploadedImg.file.size.toString());
        formData.append('file_type', uploadedImg.type);
        mutateAsyncUploadMedia({ formData });
      }
    }

    setActiveStep(activeStep + 1);
  };

  const validationscheme = yup.object({
    contact_no: yup.string().required('Contact number is required').min(14, 'Contact number is not valid'),
    representation: yup.string().required('Representation is required'),
    stage_name: yup.string().required('Stage name is required'),
    first_name: yup.string().required('First name is required'),
    last_name: yup.string().required('Last name is required'),
    country: yup.string().required(),
    country_code: yup.string().required(),
    middle_name: yup.string().notRequired(),
    gender: yup.string().required('Gender is required'),
    identifies_as: yup.string().notRequired(),
    sag_status: yup.string().notRequired(),
    sag_id: yup.string().notRequired(),
    union: yup.string().notRequired(),
    statistics: yup.object().notRequired(),
  });

  const form: FormikProps<ITalentUpdatePayload> = useFormik({
    initialValues,
    validationSchema: validationscheme,
    validateOnBlur: true,
    onSubmit: (values) => handleSubmit(values),
  });

  const validateContactInformation = () => {
    if (!form.errors.contact_no) setDisabled(false);
    else setDisabled(true);
  };

  const validatePersonalInformation = () => {
    if (!form.errors.stage_name || !form.errors.first_name || !form.errors.last_name || !form.errors.gender)
      setDisabled(false);
    else setDisabled(true);
  };

  useEffect(() => {
    if (activeStep === 1) {
      if (form.errors.contact_no) setDisabled(true);
    } else if (activeStep === 2) {
      if (form.errors.stage_name || form.errors.first_name || form.errors.last_name || form.errors.gender)
        setDisabled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  const next = () => {
    if (activeStep === 0) setDisabled(true);
    setActiveStep(activeStep + 1);
  };

  const prev = () => {
    setActiveStep(activeStep - 1);
    if (activeStep === 1 || activeStep === 2) {
      setDisabled(false);
    }
  };

  const matchProfile = () => {
    if (profileMatchData && profileMatchData.data.length > 0) {
      setIsProfileMatch(true);
    } else {
      setIsProfileMatch(false);
      next();
    }
  };

  return (
    <Grid className={classes.container}>
      {profileType === 'adult' && activeStep > 3 ? (
        ''
      ) : activeStep > 4 ? (
        ''
      ) : (
        <Grid item className={classes.header}>
          <Grid item>
            <Typography fontSize={18}>You have been verified, please continue the registration below</Typography>
          </Grid>
          <Grid item className={classes.stepperContainer}>
            <Stepper steps={steps} active={activeStep} />
          </Grid>
        </Grid>
      )}

      <Grid
        item
        className={`${classes.body} ${
          profileType === 'adult' && activeStep > 3 ? classes.onlyBody : activeStep > 4 ? classes.onlyBody : ''
        }`}
      >
        {isProfileMatch ? (
          <ProfileClaim
            data={profileMatchData}
            setRepresentatives={setRepresentatives}
            representatives={representatives}
            setActiveStep={setActiveStep}
            setIsProfileMatch={setIsProfileMatch}
          />
        ) : activeStep === 0 ? (
          <ProfileSelection setSteps={setSteps} setProfileType={setProfileType} profileType={profileType} />
        ) : activeStep === 1 ? (
          <>
            {profileType === 'adult' ? (
              <ContactInformation
                validate={validateContactInformation}
                form={form}
                setRepresentatives={setRepresentatives}
                representatives={representatives}
              />
            ) : (
              <GuardianContactInformation />
            )}
          </>
        ) : activeStep === 2 ? (
          <>
            {profileType === 'adult' ? (
              <PersonalInformation
                validate={validatePersonalInformation}
                setFirstName={setFirstName}
                setLastName={setLastName}
                form={form}
              />
            ) : (
              <AddMembers setActiveStep={setActiveStep} />
            )}
          </>
        ) : activeStep === 3 ? (
          <>{profileType === 'adult' ? <ProfileImageAdult onFileSelected={handleUploadImg} /> : <AddNewMember />}</>
        ) : activeStep === 4 ? (
          <>
            {profileType === 'adult' ? (
              <Success setActiveStep={setActiveStep} activeStep={activeStep} />
            ) : (
              <ProfileImage />
            )}
          </>
        ) : (
          <Success setActiveStep={setActiveStep} activeStep={activeStep} />
        )}
      </Grid>
      <Grid item className={classes.footer}>
        {!isProfileMatch ? (
          activeStep > 0 ? (
            <>
              {profileType === 'adult' && activeStep > 2 ? (
                ''
              ) : activeStep > 4 ? (
                ''
              ) : (
                <IconButton className={classes.backIconButton} onClick={prev}>
                  <BackIcon />
                </IconButton>
              )}
            </>
          ) : (
            ''
          )
        ) : (
          ''
        )}

        {isProfileMatch ? (
          ''
        ) : profileType === 'adult' && activeStep > 3 ? (
          ''
        ) : activeStep > 4 ? (
          ''
        ) : (
          <>
            {profileType === 'minor' && activeStep === 2 ? (
              <Button
                color="primary"
                variant="contained"
                disableElevation
                fullWidth
                className={classes.buttonCls}
                onClick={() => setActiveStep(5)}
              >
                Finish
              </Button>
            ) : (
              <>
                {profileType === 'minor' && activeStep === 4 ? (
                  <Button
                    color="primary"
                    variant="contained"
                    disableElevation
                    fullWidth
                    className={classes.buttonCls}
                    onClick={() => {
                      setActiveStep(2);
                    }}
                  >
                    Finish
                  </Button>
                ) : (
                  <Button
                    color="primary"
                    variant="contained"
                    disableElevation
                    fullWidth
                    className={classes.buttonCls}
                    onClick={() => {
                      if (profileType === 'adult' && activeStep === 3) {
                        form.handleSubmit();
                      } else if (profileType === 'adult' && activeStep === 2) {
                        matchProfile();
                      } else {
                        next();
                      }
                    }}
                    disabled={disabled}
                  >
                    {profileType === 'adult' && activeStep === 3 ? 'Finish' : activeStep === 4 ? 'Finish' : 'Continue'}
                  </Button>
                )}
              </>
            )}
          </>
        )}
      </Grid>
      <Backdrop
        isLoading={
          isLoading
            ? isLoading
            : isLoadingCreateProfile
            ? isLoadingCreateProfile
            : isLoadingUploadMedia
            ? isLoadingUploadMedia
            : isSetPrimaryImageLoading
            ? isSetPrimaryImageLoading
            : false
        }
      />
    </Grid>
  );
};

export default RegistrationForm;
