import React from 'react';

import { FrontLayout } from 'components';
import { RegistrationForm } from 'components/RegistrationForm';

const Registration = () => {
  return (
    <FrontLayout containerWidth={1130}>
      <RegistrationForm />
    </FrontLayout>
  );
};

export default Registration;
