import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() =>
  createStyles({
    buttonCls: {
      marginTop: 50,
      height: '60px !important',
      borderRadius: 8,
      textTransform: 'unset',
      maxWidth: 390,
      fontSize: 16,
      border: '1px solid #3e8bff',

      '&.Mui-disabled': {
        background: '#3e8bff',
        color: '#fff',
        opacity: 0.5,
      },
    },

    confirmButton: {
      background: '#0e1319',
      border: '1px solid #3e8bff',
      marginTop: 15,
    },

    bodyContainer: {
      marginTop: 30,
      textAlign: 'center',
    },
    extraText: {
      color: '#60718b',
    },

    link: {
      textDecoration: 'none',
    },

    continueBtn: {
      border: '1px solid #3e8bff',
      background: 'none',
      maxWidth: 390,
      fontSize: 16,
      height: '60px !important',
      borderRadius: 8,
    },
  }),
);
