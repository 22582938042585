import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  Button,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Chip,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import ClearIcon from '@material-ui/icons/Clear';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Done from '@material-ui/icons/Done';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import ethnicity from 'data/Ethnicities.json';
import { useStyles } from './EthnicityDialog.styles';
import { boolean } from 'yup/lib/locale';
import { forEach } from 'lodash';
import { CloseIcon, StyledDialog } from '@room-match/shared-ui-components';

type Props = {
  open: boolean;
  onClose: () => void;
  selectedChips: any[] | undefined;
  setSelectedChips: (field: any, value: any) => void;
};

const EthnicityDialog: React.FC<Props> = ({ open, onClose, selectedChips, setSelectedChips }) => {
  const [allChips, setAllChips] = React.useState(ethnicity);
  const [selected, setSelected] = React.useState(
    new Set(
      selectedChips?.map((i) => {
        return i.id;
      }),
    ),
  );

  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange = (panel: string) => (event: any, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [isLargeDialog, setIsLargeDialog] = useState<boolean>(false);
  const toggleLargeDialog = () => setIsLargeDialog((curr) => !curr);
  const classes = useStyles();

  function handleSelectionChanged(id: any) {
    const newSet = new Set(selected);
    if (newSet.has(id)) {
      newSet.delete(id);
    } else {
      newSet.add(id);
    }
    setSelected(newSet);
  }

  function onSave() {
    const arr = Array.from(selected);
    const res = allChips.filter((i) => arr.includes(i.id));
    setSelectedChips('statistics.ethnicity', res);
    onClose();
  }

  return (
    <StyledDialog
      style={{ zIndex: 9999 }}
      open={open}
      onClose={onClose}
      maxWidth={false}
      aria-labelledby="simple-dialog-title"
    >
      <Grid className={classes.chipContainer}>
        <Typography
          style={{
            fontSize: 20,
            marginBottom: 30,
            marginTop: 20,
            fontWeight: 'bold',
            alignSelf: 'center',
            justifySelf: 'center',
          }}
        >
          Select Ethnicity
        </Typography>
        <IconButton onClick={onClose} style={{ position: 'absolute', right: 10, top: 10 }}>
          <CloseIcon style={{ width: 15, height: 15 }} />
        </IconButton>
        <Accordion
          expanded={expanded === 'panel1'}
          onChange={handleChange('panel1')}
          className={classes.groupAccordion}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
            <Typography variant="subtitle1" className={classes.subHeading}>
              Caucasian
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={0} style={{ marginLeft: 10 }}>
              {allChips
                .filter((c) => c.group === 'Caucasian')
                .map((c: any) => (
                  <Chip
                    label={c.name}
                    key={c.id}
                    onClick={() => handleSelectionChanged(c.id)}
                    variant={selected.has(c.id) ? 'default' : 'outlined'}
                  />
                ))}
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel2'}
          onChange={handleChange('panel2')}
          className={classes.groupAccordion}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2bh-content" id="panel2bh-header">
            <Typography variant="subtitle1" className={classes.subHeading}>
              European
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={0} style={{ marginLeft: 10 }}>
              {allChips
                .filter((c) => c.group === 'European')
                .map((c: any) => (
                  <Chip
                    label={c.name}
                    key={c.id}
                    onClick={() => handleSelectionChanged(c.id)}
                    variant={selected.has(c.id) ? 'default' : 'outlined'}
                  />
                ))}
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel3'}
          onChange={handleChange('panel3')}
          className={classes.groupAccordion}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3bh-content" id="panel3bh-header">
            <Typography variant="subtitle1" className={classes.subHeading}>
              Asian
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={0} style={{ marginLeft: 10 }}>
              {allChips
                .filter((c) => c.group === 'Asian')
                .map((c: any) => (
                  <Chip
                    label={c.name}
                    key={c.id}
                    onClick={() => handleSelectionChanged(c.id)}
                    variant={selected.has(c.id) ? 'default' : 'outlined'}
                  />
                ))}
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel4'}
          onChange={handleChange('panel4')}
          className={classes.groupAccordion}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel4bh-content" id="panel4bh-header">
            <Typography variant="subtitle1" className={classes.subHeading}>
              Middle Eastern
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={0} style={{ marginLeft: 10 }}>
              {allChips
                .filter((c) => c.group === 'Middle Eastern')
                .map((c: any) => (
                  <Chip
                    label={c.name}
                    key={c.id}
                    onClick={() => handleSelectionChanged(c.id)}
                    variant={selected.has(c.id) ? 'default' : 'outlined'}
                  />
                ))}
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel5'}
          onChange={handleChange('panel5')}
          className={classes.groupAccordion}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel5bh-content" id="panel5bh-header">
            <Typography variant="subtitle1" className={classes.subHeading}>
              African
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={0} style={{ marginLeft: 10 }}>
              {allChips
                .filter((c) => c.group === 'African')
                .map((c: any) => (
                  <Chip
                    label={c.name}
                    key={c.id}
                    onClick={() => handleSelectionChanged(c.id)}
                    variant={selected.has(c.id) ? 'default' : 'outlined'}
                  />
                ))}
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel6'}
          onChange={handleChange('panel6')}
          className={classes.groupAccordion}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel6bh-content" id="panel6bh-header">
            <Typography variant="subtitle1" className={classes.subHeading}>
              American
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={0} style={{ marginLeft: 10 }}>
              {allChips
                .filter((c) => c.group === 'American')
                .map((c: any) => (
                  <Chip
                    label={c.name}
                    key={c.id}
                    onClick={() => handleSelectionChanged(c.id)}
                    variant={selected.has(c.id) ? 'default' : 'outlined'}
                  />
                ))}
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel7'}
          onChange={handleChange('panel7')}
          className={classes.groupAccordion}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel7bh-content" id="panel7bh-header">
            <Typography variant="subtitle1" className={classes.subHeading}>
              Pacific Islander
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={0} style={{ marginLeft: 10 }}>
              {allChips
                .filter((c) => c.group === 'Pacific Islander')
                .map((c: any) => (
                  <Chip
                    label={c.name}
                    key={c.id}
                    onClick={() => handleSelectionChanged(c.id)}
                    variant={selected.has(c.id) ? 'default' : 'outlined'}
                  />
                ))}
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel8'}
          onChange={handleChange('panel8')}
          className={classes.groupAccordion}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel8bh-content" id="panel8bh-header">
            <Typography variant="subtitle1" className={classes.subHeading}>
              Aboriginal/Torres Straight
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={0} style={{ marginLeft: 10 }}>
              {allChips
                .filter((c) => c.group === 'Aboriginal/Torres Straight')
                .map((c: any) => (
                  <Chip
                    label={c.name}
                    key={c.id}
                    onClick={() => handleSelectionChanged(c.id)}
                    variant={selected.has(c.id) ? 'default' : 'outlined'}
                  />
                ))}
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel9'}
          onChange={handleChange('panel9')}
          className={classes.groupAccordion}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel9bh-content" id="panel9bh-header">
            <Typography variant="subtitle1" className={classes.subHeading}>
              North Pole
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={0} style={{ marginLeft: 10 }}>
              {allChips
                .filter((c) => c.group === 'North Pole')
                .map((c: any) => (
                  <Chip
                    label={c.name}
                    key={c.id}
                    onClick={() => handleSelectionChanged(c.id)}
                    variant={selected.has(c.id) ? 'default' : 'outlined'}
                  />
                ))}
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Button
        onClick={onSave}
        style={{ width: 220, height: 40, backgroundColor: '#3e8bff', marginBottom: '30px', alignSelf: 'center' }}
      >
        Save Changes
      </Button>
    </StyledDialog>
  );
};

export default EthnicityDialog;
