import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function AccountIcon(props: SvgIconProps) {
  return (
    <SvgIcon width="24" height="20" viewBox="0 0 24 20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <defs>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="pkalwhvhra">
          <stop stopColor="#3E8BFF" offset="0%" />
          <stop stopColor="#3E8BFF" offset="100%" />
        </linearGradient>
      </defs>
      <path
        d="M19.814 0a2.561 2.561 0 0 1 2.558 2.558v11.163c0 .419-.102.814-.28 1.163h1.024c.386 0 .698.312.698.697v1.86A2.561 2.561 0 0 1 21.256 20H2.558A2.561 2.561 0 0 1 0 17.442v-1.86c0-.386.312-.698.698-.698h1.025a2.542 2.542 0 0 1-.281-1.163V2.558A2.561 2.561 0 0 1 4 0zm2.605 16.28H1.395v1.162c0 .641.522 1.163 1.163 1.163h18.698c.641 0 1.163-.522 1.163-1.163v-1.163zM19.814 1.394H4c-.641 0-1.163.522-1.163 1.163v11.163c0 .641.522 1.163 1.163 1.163h15.814c.641 0 1.163-.522 1.163-1.163V2.558c0-.641-.522-1.163-1.163-1.163zm-3.256 10.698a.698.698 0 0 1 0 1.395H7.256a.698.698 0 0 1 0-1.395zm-4.651-9.302a2.328 2.328 0 0 1 2.326 2.325c0 .546-.19 1.048-.505 1.445a3.49 3.49 0 0 1 1.667 2.974V10a.698.698 0 0 1-.697.698H9.116A.698.698 0 0 1 8.42 10v-.465a3.49 3.49 0 0 1 1.667-2.974 2.314 2.314 0 0 1-.505-1.445 2.328 2.328 0 0 1 2.326-2.325zm0 4.65a2.096 2.096 0 0 0-2.08 1.861h4.16a2.096 2.096 0 0 0-2.08-1.86zm0-3.255a.931.931 0 0 0 0 1.86.931.931 0 0 0 0-1.86z"
        fill="url(#pkalwhvhra)"
        fillRule="nonzero"
      />
    </SvgIcon>
  );
}

export default AccountIcon;
