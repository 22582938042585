import { QueryKey, useMutation, useQuery, UseQueryOptions } from 'react-query';
import { abstractApiDao } from 'shared/dao/abstractApiDao';
import { IAbstractApiResponsePayload } from 'shared/interfaces/utils/IAbstractApi';
import { IAxiosErrorResponse } from 'shared/interfaces/utils/IAxios';

const { validateEmail: validateEmailDao } = abstractApiDao();
export const abstractApiService = () => {
  const validateEmail = (
    email: string,
    options?: Omit<
      UseQueryOptions<IAbstractApiResponsePayload, IAxiosErrorResponse, IAbstractApiResponsePayload, QueryKey>,
      'queryKey' | 'queryFn'
    >,
  ) => {
    return useQuery<IAbstractApiResponsePayload, IAxiosErrorResponse>(
      ['validateEmail', email],
      () => validateEmailDao(email),
      options,
    );
  };

  return {
    validateEmail,
  };
};
