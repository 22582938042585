import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  Button,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Chip,
  Typography,
} from '@material-ui/core';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import ClearIcon from '@material-ui/icons/Clear';
import Done from '@material-ui/icons/Done';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import talentTypes from 'data/TalentTypes.json';
import { useStyles } from './TalentTypesDialog.styles';
import { boolean } from 'yup/lib/locale';
import { forEach } from 'lodash';
import { StyledDialog, CloseIcon } from '@room-match/shared-ui-components';

type Props = {
  key: number | undefined;
  open: boolean;
  onClose: () => void;
  selectedChips: any[] | undefined;
  setSelectedChips: (field: any, value: any) => void;
};

const TalentTypesDialog: React.FC<Props> = ({ open, onClose, selectedChips, setSelectedChips }) => {
  const [allChips, setAllChips] = React.useState(talentTypes);
  const [selected, setSelected] = React.useState(
    new Set(
      selectedChips?.map((i) => {
        return i.id;
      }),
    ),
  );

  const [isLargeDialog, setIsLargeDialog] = useState<boolean>(false);
  const toggleLargeDialog = () => setIsLargeDialog((curr) => !curr);
  const classes = useStyles();

  function handleSelectionChanged(id: any) {
    const newSet = new Set(selected);
    if (newSet.has(id)) {
      newSet.delete(id);
    } else {
      newSet.add(id);
    }
    setSelected(newSet);
  }

  function onSave() {
    const arr = Array.from(selected);
    const res = allChips.filter((i) => arr.includes(i.id));
    setSelectedChips('statistics.other_talent_types', res);
    onClose();
  }

  return (
    <StyledDialog
      style={{ zIndex: 9999 }}
      open={open}
      onClose={onClose}
      maxWidth={false}
      aria-labelledby="simple-dialog-title"
    >
      <Box style={{ padding: 30, display: 'flex', flexDirection: 'column' }}>
        <Typography
          style={{
            fontSize: 20,
            marginBottom: 40,
            fontWeight: 'bold',
            marginTop: 20,
            justifySelf: 'center',
            alignSelf: 'center',
          }}
        >
          Other Talent Types
        </Typography>
        <IconButton onClick={onClose} style={{ position: 'absolute', right: 10, top: 10 }}>
          <CloseIcon style={{ width: 15, height: 15 }} />
        </IconButton>
        <Grid className={classes.chipContainer}>
          <Grid item xs={12} md={12}>
            {allChips.map((c: any) => (
              <Chip
                label={c.value}
                key={c.id}
                onClick={() => handleSelectionChanged(c.id)}
                variant={selected.has(c.id) ? 'default' : 'outlined'}
              >
                {c.value}
              </Chip>
            ))}
          </Grid>
        </Grid>
        <Button
          onClick={() => onSave()}
          style={{ width: 220, height: 40, backgroundColor: '#3e8bff', marginTop: '20px', alignSelf: 'flex-end' }}
        >
          Save Changes
        </Button>
      </Box>
    </StyledDialog>
  );
};

export default TalentTypesDialog;
