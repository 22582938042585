import {
  CheckInIcon,
  ContactsIcon,
  DashboardIcon,
  OutlinedUserIcon,
  ProjectsIcon,
  ResumeIcon,
  SelfTapesIcon,
  UsersListIcon,
} from '@room-match/shared-ui-components';
import React from 'react';

import { ROUTES } from './ROUTES';

interface ISidebarItems {
  name: string;
  icon: React.ReactNode;
  link?: string;
}
export const SIDEBAR_ITEMS: ISidebarItems[] = [
  {
    name: 'Dashboard',
    icon: <DashboardIcon />,
    link: ROUTES.TALENT.DASHBOARD,
  },

  {
    name: 'Self-Tapes',
    icon: <SelfTapesIcon />,
    link: ROUTES.TALENT.SELFTAPES,
  },
  {
    name: 'Check-In',
    icon: <CheckInIcon />,
    link: ROUTES.TALENT.CHECKIN,
  },
  {
    name: 'Opportunities',
    icon: <UsersListIcon />,
    link: ROUTES.TALENT.DASHBOARD,
  },
  {
    name: 'Profiles',
    icon: <OutlinedUserIcon />,
    link: ROUTES.TALENT.PROFILES,
  },
  {
    name: 'Media',
    icon: <ProjectsIcon />,
    link: `${ROUTES.TALENT.MEDIA}/dashboard`,
  },
  {
    name: 'My Info',
    icon: <ResumeIcon />,
    link: `${ROUTES.TALENT.MYINFO}/resume`,
  },
];
