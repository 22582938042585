import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

export interface AppState {
  headerTitle: string;
  showHeaderBack: boolean;
}

const initialState: AppState = {
  headerTitle: '',
  showHeaderBack: false,
};

export const appSlicer = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setHeaderTitle: (state: AppState, action: PayloadAction<{ headerTitle: string }>) => {
      const { headerTitle } = action.payload;
      state.headerTitle = headerTitle;
    },
    setShowHeaderBack: (state: AppState, action: PayloadAction<{ showHeaderBack: boolean }>) => {
      const { showHeaderBack } = action.payload;
      state.showHeaderBack = showHeaderBack;
    },
  },
});

export const selectAppState = (state: RootState) => state.app;
export const { setHeaderTitle, setShowHeaderBack } = appSlicer.actions;

export default appSlicer.reducer;
