import React from 'react';
import { Completed, ProgressCont } from './ProgressBar.styles';

interface Props {
  percent: number;
  completeColor: string;
  backgroundColor: string;
  borderRadius?: number;
  width?: number;
  height?: number;
  progressText?: string;
}

const ProgressBar: React.FC<Props> = ({
  percent,
  progressText,
  backgroundColor,
  completeColor,
  width,
  height,
  borderRadius,
}: Props) => {
  return (
    <ProgressCont style={{ borderRadius, backgroundColor, width, height: height || 10, flex: 1 }}>
      <Completed style={{ width: `${percent}%`, backgroundColor: completeColor, height: height || 10 }}>
        <span style={{ alignSelf: 'center' }}>{progressText || ''}</span>
      </Completed>
    </ProgressCont>
  );
};

export default React.memo(ProgressBar, (prev, next) => prev.percent === next.percent);
