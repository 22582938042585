import { ENDPOINTS } from 'shared/constants/ENDPOINTS';
import { useAxios } from 'shared/hooks/useAxios';
import {
  IAuditionScheduleCreatePayload,
  IAuditionScheduleResponsePayload,
  ISelfTapesScheduleResponse,
} from 'shared/interfaces/IAuditionSchedule';
import { authToken } from 'shared/utils/authToken';

const { POST, PATCH } = useAxios();
const { getAuthToken } = authToken();
export const auditionScheduleDao = () => {
  const createAuditionSchedule = async (sessionId: string, payload: IAuditionScheduleCreatePayload) => {
    const response = await POST<IAuditionScheduleResponsePayload>({
      url: `${ENDPOINTS.SESSION}/${sessionId}/audition_schedules`,
      data: payload,
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
      },
    });

    return response.data;
  };

  const createAuditionScheduleSelfTapes = async (payload: IAuditionScheduleCreatePayload) => {
    const response = await POST<ISelfTapesScheduleResponse>({
      url: `${ENDPOINTS.AUDITION_SCHEDULES}/self_tapes`,
      data: payload,
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
      },
    });

    return response.data;
  };

  const markAsDoneSelfTapes = async (auditionId: string, payload: IAuditionScheduleCreatePayload) => {
    const response = await PATCH({
      url: `${ENDPOINTS.AUDITION_SCHEDULES}/${auditionId}/self_tapes`,
      data: payload,
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
      },
    });

    return response.data;
  };

  return {
    markAsDoneSelfTapes,
    createAuditionSchedule,
    createAuditionScheduleSelfTapes,
  };
};
