import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function CarsIcon(props: SvgIconProps) {
  return (
    <SvgIcon width="22" height="18" viewBox="0 0 22 18" xmlns="http://www.w3.org/2000/svg" {...props}>
      <defs>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="7wdxe36m5a">
          <stop stopColor="#3E8BFF" offset="0%" />
          <stop stopColor="#3E8BFF" offset="100%" />
        </linearGradient>
      </defs>
      <path
        d="M17.158 0a1.974 1.974 0 0 1 1.838 1.279l1.683 4.536.48.508c.535.512.836 1.217.841 1.955v6.784a1.965 1.965 0 0 1-1.965 1.955h-1.241a1.965 1.965 0 0 1-1.965-1.955v-.96H5.171v.96a1.965 1.965 0 0 1-1.965 1.955H1.965A1.965 1.965 0 0 1 0 15.062v-6.61c0-.79.348-1.542.95-2.054l.69-.64 1.66-4.48A1.967 1.967 0 0 1 5.138 0zM.94 13.83v1.232c.005.564.46 1.015 1.025 1.015h1.241c.564 0 1.02-.451 1.025-1.015v-.96H1.965c-.371 0-.743-.089-1.025-.272zm20.12 0c-.282.183-.654.273-1.025.273h-2.266v.959c.005.559.46 1.015 1.025 1.015h1.241c.564 0 1.02-.451 1.025-1.015zm-1.034-7.343H2.224l-.64.602-.019.019A1.765 1.765 0 0 0 .94 8.452v3.704c.005.565.466 1.011 1.025 1.006h18.07c.56.005 1.02-.446 1.025-1.006h-.005V8.278c0-.484-.202-.95-.555-1.283-.004-.01-.018-.014-.023-.024l-.451-.484zm-13.82 1.88c.258 0 .47.212.47.47v2.445c0 .259-.212.47-.47.47H2.678a.471.471 0 0 1-.47-.47V8.838c0-.259.212-.47.47-.47zm13.115 0c.258 0 .47.212.47.47v2.445c0 .259-.212.47-.47.47h-3.526a.471.471 0 0 1-.47-.47V8.838c0-.259.211-.47.47-.47zm-6.05 1.975c.258 0 .47.211.47.47s-.212.47-.47.47H8.729a.471.471 0 0 1-.47-.47c0-.259.212-.47.47-.47zM5.735 9.308H3.15v1.504h2.585V9.308zm13.115 0h-2.585v1.504h2.585V9.308zm-5.58-.423c.26 0 .47.211.47.47 0 .258-.21.47-.47.47H8.73a.471.471 0 0 1-.47-.47c0-.259.21-.47.47-.47zM17.164.94H5.138c-.423 0-.804.259-.954.658L2.717 5.547h9.392a3.221 3.221 0 0 1 2.717-2.717 3.22 3.22 0 0 1 3.662 2.717h1.096l-1.467-3.949a1.023 1.023 0 0 0-.954-.658zm-1.396 2.84a2.286 2.286 0 0 0-2.708 1.767h4.47a2.29 2.29 0 0 0-1.762-1.768z"
        fill="url(#7wdxe36m5a)"
        fillRule="nonzero"
      />
    </SvgIcon>
  );
}

export default CarsIcon;
