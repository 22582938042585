export const getImageMetaData = (blob: Blob): Promise<{ width: number; height: number }> => {
  const reader = new FileReader();
  const image = new Image();
  return new Promise((resolve) => {
    reader.onload = () => {
      image.onload = function () {
        const { width, height } = image;
        resolve({ height, width });
      };

      image.src = reader.result as string;
    };

    reader.readAsDataURL(blob);
  });
};
