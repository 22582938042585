import { Box, Button, Grid, InputLabel } from '@material-ui/core';

import {
  ContactInput,
  FileUpload,
  MinusCircleIcon,
  Select,
  SelectArrowDownIcon,
  TextInput,
  Typography,
} from '@room-match/shared-ui-components';
import { useRef, useState } from 'react';

import { useStyles } from './ProfileImage.styles';

const ProfileImage = () => {
  const classes = useStyles();

  const [uploadedImg, setUploadedImg] = useState<{
    name: string;
  } | null>(null);

  const [hasFile, setHasFile] = useState<boolean>(false);
  const [fileName, setFileName] = useState<string>('');
  const inputFile = useRef<HTMLInputElement>(null);

  const handleOpenFileWindow = () => {
    if (inputFile.current) {
      inputFile.current.click();
    }
  };

  console.log(inputFile);

  const handleUploadLogo = () => {
    setUploadedImg({ name: '' });
  };

  return (
    <Box component="div" className={classes.root}>
      <Grid item className={classes.header}>
        <Typography fontSize={24}>Profile Image</Typography>
      </Grid>
      <Grid item className={classes.form}>
        <Box component="div" className={classes.imgContainer}></Box>
        <Button
          className={classes.btn}
          onClick={(e) => {
            e.stopPropagation();
            handleOpenFileWindow();
          }}
        >
          Upload Image
        </Button>
      </Grid>
    </Box>
  );
};

export default ProfileImage;
