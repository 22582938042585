import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function PadlockIcon(props: SvgIconProps) {
  return (
    <SvgIcon width="18" height="24" viewBox="0 0 18 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <defs>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="m5d5td1aza">
          <stop stopColor="#3E8BFF" offset="0%" />
          <stop stopColor="#3E8BFF" offset="100%" />
        </linearGradient>
      </defs>
      <path
        d="M9 0c3.309 0 6 2.691 6 6v3h.75C16.99 9 18 10.009 18 11.25v10.5c0 1.241-1.01 2.25-2.25 2.25H2.25A2.253 2.253 0 0 1 0 21.75v-10.5C0 10.009 1.01 9 2.25 9H3V6c0-3.309 2.691-6 6-6zm6.75 10.5H2.25a.75.75 0 0 0-.75.75v10.5c0 .414.337.75.75.75h13.5a.75.75 0 0 0 .75-.75v-10.5a.75.75 0 0 0-.75-.75zM9 13c1.103 0 2 .897 2 2 0 .838-.517 1.557-1.25 1.854v2.396a.75.75 0 0 1-1.5 0v-2.396A2.003 2.003 0 0 1 7 15c0-1.103.897-2 2-2zm0 1.5a.5.5 0 1 0 .002 1.002A.5.5 0 0 0 9 14.5zm0-13A4.505 4.505 0 0 0 4.5 6v3h9V6c0-2.481-2.019-4.5-4.5-4.5z"
        fill="url(#m5d5td1aza)"
        fillRule="nonzero"
        opacity="1"
      />
    </SvgIcon>
  );
}

export default PadlockIcon;
