import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

const RulerIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <defs>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="3vp5htjvwa">
          <stop stopColor="#3E8BFF" offset="0%" />
          <stop stopColor="#3E8BFF" offset="100%" />
        </linearGradient>
      </defs>
      <path
        d="M16.981.15c.201-.2.525-.2.725 0l6.144 6.144c.2.2.2.524 0 .725L7.019 23.849c-.201.201-.525.201-.725 0L.15 17.707c-.2-.2-.2-.524 0-.725zm.365 1.09-.966.967 2.879 2.879a.512.512 0 1 1-.725.724l-2.879-2.878-1.342 1.341 1.83 1.861.006.005a.512.512 0 1 1-.73.72l-1.846-1.845-1.342 1.341 2.88 2.88a.512.512 0 1 1-.726.724L11.506 7.08l-1.341 1.342 1.845 1.84a.512.512 0 1 1-.725.725L9.44 9.147l-1.342 1.342 2.879 2.878c.2.201.2.525 0 .725a.507.507 0 0 1-.725.005L7.373 11.22 6.031 12.56l1.846 1.84.01.011c.2.2.196.53-.01.725-.2.2-.53.195-.725-.01L5.307 13.28 1.24 17.346l5.414 5.414L22.76 6.654 17.346 1.24zM4.726 17.033a1.654 1.654 0 0 1 2.334 0 1.654 1.654 0 0 1 0 2.334 1.654 1.654 0 0 1-2.334 0 1.654 1.654 0 0 1 0-2.334zm1.604.73a.63.63 0 0 0-.885 0 .63.63 0 0 0 0 .884.63.63 0 0 0 .885 0c.03.005.046-.01.056-.02.232-.252.196-.633-.056-.864z"
        fill="url(#3vp5htjvwa)"
        fillRule="nonzero"
      />
    </SvgIcon>
  );
};

export default RulerIcon;
