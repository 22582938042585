import { computeProgressEvent, IProgressEvent } from '@room-match/shared-utils';
import { ENDPOINTS } from 'shared/constants/ENDPOINTS';
import { useAxios } from 'shared/hooks/useAxios';
import {
  IProjectCreatePayload,
  IProjectCreateResponsePayload,
  IProjectGetCastingSessionRequestPayload,
  IProjectGetCastingSessionResponsePayload,
  IProjectImportResponsePayload,
  IProjectRequestPayload,
  IProjectResponsePayload,
  IProjectSearchCastingRoleRequestPayload,
  IProjectSearchCastingRoleResponsePayload,
  IProjectSearchResponsePayload,
  ISingleProjectResponsePayload,
} from 'shared/interfaces/IProject';
import { IStudioSessionGetQuestionListResponsePayload } from 'shared/interfaces/IStudioSession';
import { IOnProgress } from 'shared/interfaces/utils/IAxios';
import {
  GET_CASTING_SESSIONS_BY_PROJECT,
  SEARCH_CASTING_ROLES_BY_PROJECT,
  SEARCH_PROJECTS,
} from 'shared/queries/projects.queries';
import { authToken } from 'shared/utils/authToken';

const { GET, POST, PUT, PATCH } = useAxios();
export const projectDao = () => {
  const { getAuthToken } = authToken();

  const getProjects = async (payload?: IProjectRequestPayload) => {
    const response = await GET<IProjectResponsePayload>({
      url: ENDPOINTS.PROJECT,
      params: payload,
    });
    return response.data;
  };

  const getSingleProject = async (projectId: string) => {
    const response = await GET<ISingleProjectResponsePayload>({
      url: `${ENDPOINTS.PROJECT}/${projectId}`,
    });
    return response.data;
  };

  const createProject = async (payload: IProjectCreatePayload) => {
    const response = await POST<IProjectCreateResponsePayload>({
      url: ENDPOINTS.PROJECT,
      data: payload,
    });
    return response.data;
  };

  const updateProject = async (projectId: string, payload: IProjectCreatePayload) => {
    const response = await PATCH<IProjectCreateResponsePayload>({
      url: `${ENDPOINTS.PROJECT}/${projectId}`,
      data: payload,
    });
    return response.data;
  };

  const importProject = async (formData: FormData, onProgress?: IOnProgress) => {
    const response = await PUT<IProjectImportResponsePayload>({
      url: ENDPOINTS.PROJECT,
      data: formData,
      onUploadProgress: (progressEvent: IProgressEvent) => {
        if (onProgress) {
          onProgress(computeProgressEvent(progressEvent));
        }
      },
    });

    return response.data;
  };

  const searchProjects = async (query: string) => {
    const response = await POST<IProjectSearchResponsePayload>({
      url: ENDPOINTS.GRAPHQL,
      data: {
        query: SEARCH_PROJECTS,
        variables: {
          search: String(query),
        },
      },
    });

    if (response.data.errors) {
      throw new Error('Error');
    }

    return response.data;
  };

  const searchCastingRolesByProject = async (payload: IProjectSearchCastingRoleRequestPayload) => {
    const response = await POST<IProjectSearchCastingRoleResponsePayload>({
      url: ENDPOINTS.GRAPHQL,
      data: {
        query: SEARCH_CASTING_ROLES_BY_PROJECT,
        variables: { ...payload },
      },
    });

    return response.data;
  };

  const getCastingSessionsByProject = async (payload: IProjectGetCastingSessionRequestPayload) => {
    const response = await POST<IProjectGetCastingSessionResponsePayload>({
      url: ENDPOINTS.GRAPHQL,
      data: {
        query: GET_CASTING_SESSIONS_BY_PROJECT,
        variables: { ...payload },
      },
    });
    return response.data;
  };

  const getProjectQuestions = async (projectId: string, roleId: string) => {
    const response = await GET<IStudioSessionGetQuestionListResponsePayload>({
      url: `${ENDPOINTS.PROJECT}/${projectId}/questions?role_id=${roleId}`,
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
      },
    });
    return response.data;
  };

  return {
    getProjectQuestions,
    getProjects,
    getSingleProject,
    createProject,
    updateProject,
    importProject,
    searchProjects,
    searchCastingRolesByProject,
    getCastingSessionsByProject,
  };
};
