import { gql } from 'graphql-request';

export const SEARCH_PROJECTS = gql`
  query ($search: String) {
    projects(search: $search) {
      id
      name
      status
      project_type
      union
      sag_aftra_contract_type
      contact_email
      contact_number
      conflicts
      usage
      additional_provisions
      ad_agency
      aggregates {
        decisions {
          booked
          called_back
          on_avail
          released
          selects
          th
        }
        self_tapes {
          approved
          for_review
          requested
        }
        submissions {
          auditioned
          new
          selected
          viewed
        }
      }
      casting_roles {
        id
        name
        # description

        age_range_from
        age_range_to
        ethnic_appearance
        rate_summary
        wardrobe
      }
      casting_sessions {
        id
        name
        # description
      }
      casting_company {
        id
        name
        email
        contact_first_name
        contact_last_name
        contact_number
      }
      medium_attachments {
        primary
        medium_id
        mediumable_id
        mediumable_type
        attachment
        modified_attachment
        sort
        medium {
          file_type
          file_name
          file_size
          file_width
          file_height
          visible
        }
      }
    }
  }
`;

export const SEARCH_CASTING_ROLES_BY_PROJECT = gql`
  query ($projectId: ID!, $search: String!) {
    project_casting_roles(projectId: $projectId, search: $search) {
      id
      name
      role_type
      age_range_from
      age_range_to
      description
      ethnic_appearance
      rate_summary
      wardrobe
      requesting_submissions_from
      self_tape_aggregates
    }
  }
`;

export const GET_CASTING_SESSIONS_BY_PROJECT = gql`
  query ($projectId: ID!) {
    project_casting_sessions(projectId: $projectId) {
      id
      name
      description
      session_type
      session_date
      session_aggregates
      casting_roles {
        id
        name
        role_type
        age_range_from
        age_range_to
        description
        ethnic_appearance
        rate_summary
        wardrobe
        requesting_submissions_from
        self_tape_aggregates
      }
      schedules {
        id
        time_in
      }
    }
  }
`;
