import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',

      '& .MuiOutlinedInput-root': {
        height: '50px!important',
        fontSize: 14,
        background: '#0e1319',
        maxWidth: 390,
      },

      '& .MuiFormLabel-root': {
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: '13px',
        letterSpacing: '1px',
        alignItems: 'center',
        color: '#c2ccd8',

        '& .MuiBox-root': {
          padding: '2px 4px 1px 5px',
          border: '1px solid',
          borderRadius: '100%',
          fontSize: '12px',
        },
      },

      '& .MuiSelect-iconOutlined': {
        right: '0',
        top: '22px',
      },

      '& .MuiSelect-iconOpen': {
        top: '2px',
        right: '13px',
      },
    },
    header: {
      marginBottom: 80,
      textAlign: 'center',
    },
    body: {
      display: 'flex',
      flexDirection: 'column',
      gap: 15,
      textAlign: 'center',
    },
    img: {
      height: 400,
      borderRadius: 5,
      width: 283,
      objectFit: 'cover',
      marginBottom: 15,
    },
    imgContainer: {
      margin: 'auto',
    },
    chips: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      background: '#28313f',
      height: 50,
      borderRadius: 5,
      padding: '0 20px',

      '&:not(:first-child)': {
        marginTop: 10,
      },

      '& .MuiSvgIcon-root': {
        cursor: 'pointer',
        fontSize: 18,
        '& path': {
          fill: '#d83032',
        },
      },
    },
    autocompleteFix: {
      '& .MuiInputBase-input': {
        paddingTop: '8px!important',
      },
    },
    buttonCls: {
      height: '60px !important',
      borderRadius: 8,
      textTransform: 'unset',
      maxWidth: 390,
      fontSize: 16,
      border: '1px solid #3e8bff',
    },
    confirmButton: {
      background: '#0e1319',
      border: '1px solid #3e8bff',
      marginTop: 15,
    },
  }),
);
