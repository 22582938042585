import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() =>
  createStyles({
    boxContainer: {
      '& .MuiOutlinedInput-root': {
        height: '60px',
        borderRadius: '6px',
      },

      '& input': {
        fontSize: '14px',
      },
    },
    checkboxContainer: {
      marginTop: 5,
      '& .MuiFormControlLabel-root': {
        marginTop: -4,
      },
    },
    extraContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      margin: '10px 0 8px',
      alignItems: 'center',
      '& a, & span': {
        fontSize: '14px',
      },
      '& a': {
        color: '#3e8bff',
      },
    },
    link: {
      color: '#3e8bff',
      textDecoration: 'none',
    },
    buttonCls: {
      height: '60px !important',
      borderRadius: 8,
      textTransform: 'unset',
      maxWidth: 390,
    },
    loginFooter: {
      marginTop: 20,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      '& *': {
        fontSize: '14px',
      },
      '& span': {
        color: '#6e767f',
      },
    },
    facebookBtn: {
      background: '#1877f2',
    },
    facebookIcon: {
      position: 'absolute',
      left: 20,
      '& path': {
        fill: '#fff',
      },
    },
    googleBtn: {
      fontWeight: 'bold',
      background: '#fff',
      color: '#0d1117',

      '&:hover': {
        background: '#ccc',
      },
    },
    googleIcon: {
      fontSize: 20,
      position: 'absolute',
      left: 20,
    },
    input: {
      maxWidth: 390,
      background: '#0e1319',
      borderRadius: 8,
      fontWeight: 400,
      '& input': {
        fontSize: 14,
      },
      '& .MuiInputBase-root': {
        paddingLeft: 20,
        borderRadius: 8,
      },
    },
    startIcon: {
      fontSize: 20,
      marginRight: 5,
    },
  }),
);
