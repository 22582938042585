import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

const ResumeTopFoldIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="19" height="25" viewBox="0 0 19 25" xmlns="http://www.w3.org/2000/svg" {...props}>
      <defs>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="ia9v8pqsfa">
          <stop stopColor="#3E8BFF" offset="0%" />
          <stop stopColor="#3E8BFF" offset="100%" />
        </linearGradient>
      </defs>
      <path
        d="M11.686 0a.54.54 0 0 1 .393.164l.005.006 6.179 6.162c.1.104.157.242.158.387v14.445A2.837 2.837 0 0 1 15.585 24H2.835A2.837 2.837 0 0 1 0 21.164V2.842A2.837 2.837 0 0 1 2.836.006h8.79a.222.222 0 0 1 .06-.006zm-.545 1.085H2.836A1.75 1.75 0 0 0 1.09 2.831v18.322a1.75 1.75 0 0 0 1.745 1.745h12.75c.963 0 1.743-.781 1.744-1.745V7.275h-3.354a2.837 2.837 0 0 1-2.835-2.836V1.085zm3.375 17.14a.545.545 0 0 1 0 1.09H3.905a.545.545 0 1 1 0-1.09zM10.7 14.097a.545.545 0 0 1 0 1.09H3.905a.545.545 0 1 1 0-1.09zM6.086 8.18a2.727 2.727 0 0 1 2.726 2.727v.436a.545.545 0 1 1-1.09 0v-.436a1.636 1.636 0 1 0-3.272 0v.436c-.001.3-.245.545-.545.545a.547.547 0 0 1-.546-.545v-.436A2.727 2.727 0 0 1 6.086 8.18zm0-4.368a2.002 2.002 0 1 1 0 4.003 2.002 2.002 0 0 1 0-4.003zm0 1.096a.91.91 0 1 0 0 1.821.91.91 0 0 0 0-1.82zm6.146-3.053v2.584a1.75 1.75 0 0 0 1.744 1.745h2.596l-4.34-4.33z"
        transform="translate(0 .165)"
        fill="url(#ia9v8pqsfa)"
        fillRule="nonzero"
      />
    </SvgIcon>
  );
};

export default ResumeTopFoldIcon;
