import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider } from 'react-redux';
import { store } from 'shared/redux/store';
import SwitchThemeProvider from 'shared/theme/SwitchThemeProvider';
import { SnackbarProvider } from 'notistack';
import { ConfirmProvider } from 'material-ui-confirm';
import { BrowserRouter as Router } from 'react-router-dom';
import { Routes } from 'shared/navigation';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      retry: false,
      staleTime: 5 * 60 * 1000,
    },
  },
});
const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <Provider store={store}>
        <Router>
          <SwitchThemeProvider>
            <SnackbarProvider maxSnack={3}>
              <ConfirmProvider>
                <Routes />
              </ConfirmProvider>
            </SnackbarProvider>
          </SwitchThemeProvider>
        </Router>
      </Provider>
    </QueryClientProvider>
  );
};

export default App;
