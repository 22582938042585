import { Grid } from '@material-ui/core';
import { Typography } from '@room-match/shared-ui-components';
import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Logo, Text, DownloadContainer, StepsHeader } from './Download.styles';

const Download = () => {
  return (
    <Container>
      <Logo>
        <img src="/casting-logo.svg" alt="Casting App Logo" />
      </Logo>
      <Grid item>
        <Text fontSize={26} fontWeight={500}>
          Hello, You have been requested to use Casting App to check in for your audition today.
        </Text>
      </Grid>
      <Grid item>
        <Text fontSize={18}>
          We have an optimised experience for you to check-in in an APP available on iOS and Android. Please click the
          link below to download or open the app now.
        </Text>
      </Grid>
      <DownloadContainer>
        <Link to="#">
          <img src="/ios-download.png" alt="Download app in iOS" />
        </Link>
        <Link to="#">
          <img src="/android-download.png" alt="Download app in Android" />
        </Link>
      </DownloadContainer>
      <Grid item>
        <Text fontSize={18}>Note: This can also be done on the web by clicking the following link:</Text>
      </Grid>
      <Grid item>
        <Link to="#">
          <Text fontSize={18}>https://examplelink.com/?webview_register</Text>
        </Link>
      </Grid>
      <Grid item>
        <Text fontSize={18}>
          But we strongly advise to use the app as it includes notifications and updates should the casting director
          require anything additional from you, as well of lots of other great features and tips.
        </Text>
      </Grid>
      <Grid item>
        <StepsHeader fontSize={21}>Here are the Steps for you to complete:</StepsHeader>
      </Grid>
      <Grid item>
        <Text fontSize={21}>a) Download Talent App</Text>
      </Grid>
      <DownloadContainer>
        <Link to="#">
          <img src="/ios-download.png" alt="Download app in iOS" />
        </Link>
        <Link to="#">
          <img src="/android-download.png" alt="Download app in Android" />
        </Link>
      </DownloadContainer>
      <Grid item>
        <Text fontSize={21}>b) Register An Account if you don't have one yet for Casting App</Text>
      </Grid>
      <Grid item>
        <Text fontSize={21}>c) Once Complete Select Check-In on the dashboard</Text>
      </Grid>
      <Grid item>
        <Text fontSize={21}>d) Scan the QR code or type it to check-in for your project</Text>
      </Grid>
      <Grid item>
        <Text fontSize={21}>e) This will load the project and role information for your audition.</Text>
      </Grid>
      <Grid item>
        <Text fontSize={21}>f) Please complete the required steps to finish checking-in.</Text>
      </Grid>
    </Container>
  );
};

export default Download;
