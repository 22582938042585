import { Paper } from '@material-ui/core';
import React from 'react';

import { useStyles } from './FrontLayout.style';

type Props = {
  heading?: string;
  subHeading?: string;
  children: React.ReactNode;
  containerWidth?: number;
  type?: string;
};
const FrontLayout: React.FC<Props> = ({ heading, subHeading, children, containerWidth, type }) => {
  const classes = useStyles();
  return (
    <div className={classes.bodyContainer}>
      <div className={classes.backgroundContainer}>
        <div className={classes.bgImageOverlay} />
      </div>
      <Paper className={classes.mainContainer} style={{ width: containerWidth || 420 }}>
        {type ? (
          <div className={classes.header}>
            <div
              className={
                type === 'sendVerification'
                  ? classes.logoContainerForSendVerification
                  : heading || subHeading
                  ? classes.logoContainer
                  : classes.logoContainerWithoutHeading
              }
            >
              <img
                className={classes.logoCls}
                src={
                  type === 'login'
                    ? '/casting-logo.svg'
                    : type === 'register'
                    ? '/casting-logo-no-text.svg'
                    : type === 'sendVerification'
                    ? '/email-verification.svg'
                    : ''
                }
                alt="File Logo"
              />
            </div>
            <div>
              {heading ? <div className={classes.heading}>{heading}</div> : ''}
              {subHeading ? <div className={classes.subHeading}>{subHeading}</div> : ''}
            </div>
          </div>
        ) : (
          ''
        )}
        <div>{children}</div>
      </Paper>
    </div>
  );
};

export default FrontLayout;
