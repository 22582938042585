export const ROUTES = {
  ROOT: '/',
  LOGIN: '/login',
  SIGNUP: '/signup',
  SEND_VERIFICATION: '/send_verification',
  REGISTRATION: '/registration',
  NEW_TALENT: '/new_talent',
  CHECKIN_REDIRECT: '/go',
  COMPLETE_PROFILE: '/complete_profile',
  DOWNLOAD: '/download',
  TALENT: {
    DASHBOARD: '/talent/dashboard',
    SETTINGS: '/talent/settings',
    PROFILES: '/talent/profiles',
    CHECKIN: '/talent/check_in',
    SELFTAPES: '/talent/self_tapes',
    MYINFO: '/talent/my_info',
    MEDIA: '/talent/media',
  },
  // MEDIA: {
  //   DASHBOARD: '/media/dashboard',
  //   IMAGES: '/media/images',
  //   VIDEOS: '/media/videos',
  //   AUDIOS: '/media/audios',
  //   DOCUMENTS: '/media/documents',
  // },
};
