import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      marginTop: -5,
      display: 'flex',
      flexDirection: 'column',

      '& > div:not(:first-child)': {
        marginTop: 15,
        paddingTop: 15,
        borderTop: '1px solid #2d343e',
      },

      '& > div:not(:last-child) > div': {
        display: 'flex',
        justifyContent: 'space-between',
        '&:not(:first-child)': {
          marginTop: 5,
        },
      },

      '& > div:last-child > div': {
        marginBottom: 15,
        '& > p:first-child': {
          marginBottom: 7,
        },
      },

      '& .MuiSelect-iconOutlined': {
        right: '0',
        top: '20px',
      },

      '& .MuiSelect-iconOpen': {
        top: '0',
        right: '13px',
      },
    },
    heading: {
      position: 'relative',
      borderBottom: '1px solid #2d343e',
      alignItems: 'center',
      paddingBottom: 20,
      marginBottom: 15,

      '&::before': {
        content: '""',
        background: '#3e8bff',
        height: '1px',
        position: 'absolute',
        width: 42,
        bottom: '-1px',
      },
    },
    yellow: {
      color: '#f7bc02',
    },

    selection: {
      flexDirection: 'column',
    },
    autocomplete: {
      '& input': {
        marginTop: '-2px',
      },
    },
    buttonCls: {
      height: '50px !important',
      borderRadius: 8,
      textTransform: 'unset',
      width: '100%',
      fontSize: 15,
      margin: '10px 0 ',
      // maxWidth: 320,
    },
  }),
);
