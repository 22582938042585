import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { authDao } from 'shared/dao/authDao';
import { IRefreshTokenRequestPayload, IUserResponsePayload } from 'shared/interfaces/IUser';
import { IAxiosErrorResponse } from 'shared/interfaces/utils/IAxios';
import { ls } from 'shared/utils/ls';

const { setLS, removeLS } = ls();

const { getUserProfile: getUserProfileDao, generateRefreshToken: generateRefreshTokenDao } = authDao();
export const authService = () => {
  const getUserProfile = () => {
    return useQuery<IUserResponsePayload, IAxiosErrorResponse>(['user'], () => getUserProfileDao());
  };

  const generateRefreshToken = async (payload: IRefreshTokenRequestPayload) => {
    try {
      const response = await generateRefreshTokenDao(payload);
      setLS('auth_token', response.token);
      setLS('refersh_token', response.refresh_token);
      return response;
    } catch (err: any) {
      throw new Error(err);
    }
  };
  return {
    generateRefreshToken,
    getUserProfile,
  };
};
