import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

const BackIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="9" height="17" viewBox="0 0 9 17" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="m.234 9.071 7.195 7.193a.81.81 0 0 0 1.144 0 .806.806 0 0 0 0-1.142L1.948 8.5l6.624-6.622A.807.807 0 1 0 7.428.736L.233 7.93a.815.815 0 0 0 0 1.142z"
        fill={props.htmlColor || '#6B737C'}
        fill-rule="nonzero"
      />
    </SvgIcon>
  );
};

export default BackIcon;
