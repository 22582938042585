import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function SelectedIcon(props: SvgIconProps) {
  return (
    <SvgIcon width="20" height="24" viewBox="0 0 20 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <defs>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="q6zhel51ma">
          <stop stopColor="#3E8BFF" offset="0%" />
          <stop stopColor="#3E8BFF" offset="100%" />
        </linearGradient>
      </defs>
      <path
        d="M17.25 0A2.752 2.752 0 0 1 20 2.75v18.5A2.752 2.752 0 0 1 17.25 24H2.75A2.752 2.752 0 0 1 0 21.25V2.75A2.752 2.752 0 0 1 2.75 0h14.5zm1.25 18h-17v3.25c0 .689.561 1.25 1.25 1.25h14.5c.689 0 1.25-.561 1.25-1.25V18zM17.25 1.5H2.75c-.689 0-1.25.561-1.25 1.25V16.5H5v-1.25C5 13.367 7.103 12 10 12s5 1.367 5 3.25v1.25h3.5V2.75c0-.689-.561-1.25-1.25-1.25zM10 13.5c-2.162 0-3.5.908-3.5 1.75v1.25h7v-1.25c0-.842-1.338-1.75-3.5-1.75zM10 5c1.654 0 3 1.346 3 3s-1.346 3-3 3-3-1.346-3-3 1.346-3 3-3zm0 1.5c-.827 0-1.5.673-1.5 1.5s.673 1.5 1.5 1.5 1.5-.673 1.5-1.5-.673-1.5-1.5-1.5z"
        fill="url(#q6zhel51ma)"
        fillRule="nonzero"
      />
    </SvgIcon>
  );
}

export default SelectedIcon;
