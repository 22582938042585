import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function SignOutIconTwoTone(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M5.833 0a.833.833 0 0 1 0 1.667H4.167a2.5 2.5 0 0 0-2.5 2.5v11.666a2.5 2.5 0 0 0 2.5 2.5h1.666a.833.833 0 0 1 0 1.667H4.167A4.172 4.172 0 0 1 0 15.833V4.167A4.172 4.172 0 0 1 4.167 0zm8.447 4.421a.833.833 0 0 1 1.168-.01l3.823 3.822a2.506 2.506 0 0 1 0 3.534l-3.823 3.822a.833.833 0 1 1-1.178-1.178l3.578-3.578H5a.833.833 0 1 1 0-1.666h12.847L14.27 5.589a.833.833 0 0 1 .01-1.168z"
          fill="#F13737"
          fillRule="nonzero"
        />
      </svg>
    </SvgIcon>
  );
}

export default SignOutIconTwoTone;
