import { Box, Button, Grid, Typography } from '@material-ui/core';
import { FrontLayout } from 'components';
import React from 'react';
import { useParams } from 'react-router-dom';
import { projectService } from 'shared/services/projectService';
import appstore from './appstore.png';
import playstore from './playstore.png';
import b from './ScreenShots/b.jpg';
import c from './ScreenShots/c.jpg';
import d from './ScreenShots/d.jpg';

import e from './ScreenShots/e.jpg';
import f from './ScreenShots/f.jpg';

const { getSingleProject } = projectService();

function NewTalent() {
  const { projectId } = useParams() as { projectId: string };
  const { data, isLoading } = getSingleProject(projectId);

  console.log(data);

  return (
    <FrontLayout type="login">
      <Typography style={{ fontSize: 20, textAlign: 'center', fontWeight: 500 }}>
        Hello, You have been requested to use Casting App to submit your self tape by AHC Casting.
      </Typography>
      <Typography style={{ fontSize: 14, textAlign: 'center', marginTop: 29 }}>
        We have an optimised experience for you submitting your self tapes in an APP available on iOS and Android.
        Please click the link below to download or open the app now.
      </Typography>
      {/* <Box style={{ display: 'flex', width: '100%', justifyContent: 'center', marginTop: 31 }}>
        <Button style={{ backgroundColor: 'transparent' }}>
          <img src={appstore} style={{ width: 200 }} />
        </Button>
        <Button style={{ backgroundColor: 'transparent' }}>
          <img src={playstore} style={{ width: 200 }} />
        </Button>
      </Box> */}
      {/* <Typography style={{ fontSize: 14, textAlign: 'center', marginTop: 29 }}>
        Note: This can also be done on the web by clicking the following link:
        <br />
        <br />
        <a style={{ color: 'white' }} href=" https://examplelink.com/?webview_register">
          https://examplelink.com/?webview_register
        </a>
        <br />
        <br />
        But we strongly advise to use the app as it includes notifications and updates should the casting director
        require anything additional from you, as well of lots of other great features and tips to producing the best
        self tape possible.
      </Typography> */}
      <Typography style={{ fontSize: 16, textAlign: 'center', fontWeight: 500, marginTop: 30 }}>
        Here are the Steps for you to complete:
      </Typography>

      <Typography style={{ fontSize: 16, textAlign: 'center', fontWeight: 600, marginTop: 30 }}>
        {`a) Download Talent App`}
      </Typography>
      <Box style={{ display: 'flex', width: '100%', justifyContent: 'center', marginTop: 31 }}>
        <Grid container style={{ width: '100%', justifyContent: 'center' }}>
          <Grid item>
            <Button style={{ backgroundColor: 'transparent' }}>
              <img src={appstore} style={{ width: 200 }} />
            </Button>
          </Grid>
          <Grid item>
            <Button style={{ backgroundColor: 'transparent' }}>
              <img src={playstore} style={{ width: 200 }} />
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Typography style={{ fontSize: 16, textAlign: 'center', fontWeight: 600, marginTop: 30 }}>
        {`b) Register An Account if you don't have one yet for Casting App`}
      </Typography>
      <Box style={{ display: 'flex', width: '100%', justifyContent: 'center', marginTop: 31 }}>
        <img src={b} style={{ width: 200, borderRadius: 12 }} />
      </Box>
      <Typography style={{ fontSize: 16, textAlign: 'center', fontWeight: 600, marginTop: 30 }}>
        {`c) Once Complete Select Self Tape Submit on the dashboard`}
      </Typography>
      <Box style={{ display: 'flex', width: '100%', justifyContent: 'center', marginTop: 31 }}>
        <img src={c} style={{ width: 200, borderRadius: 12 }} />
      </Box>
      <Typography style={{ fontSize: 16, textAlign: 'center', fontWeight: 600, marginTop: 30 }}>
        {`d) Enter code to submit for this project`}
      </Typography>
      <Box style={{ display: 'flex', width: '100%', justifyContent: 'center', marginTop: 31 }}>
        <img src={d} style={{ width: 200, borderRadius: 12 }} />
      </Box>
      <Typography style={{ fontSize: 16, textAlign: 'center', fontWeight: 600, marginTop: 13 }}>
        {`The Project is ${data?.data.attributes?.name}`}
      </Typography>
      <Typography style={{ fontSize: 25, textAlign: 'center', fontWeight: 600, marginTop: 0 }}>
        {`Code: ${data?.data.attributes.slug}`}
      </Typography>
      <Typography style={{ fontSize: 16, textAlign: 'center', fontWeight: 600, marginTop: 30 }}>
        {`e) This will load the project and you need to select the role you are Applying for.`}
      </Typography>
      <Box style={{ display: 'flex', width: '100%', justifyContent: 'center', marginTop: 31 }}>
        <img src={e} style={{ width: 200, borderRadius: 12 }} />
      </Box>
      <Typography style={{ fontSize: 16, textAlign: 'center', fontWeight: 600, marginTop: 30 }}>
        {`f) Begin self tape submission and upload/capture the required information to submit.`}
      </Typography>
      <Box style={{ display: 'flex', width: '100%', justifyContent: 'center', marginTop: 31 }}>
        <img src={f} style={{ width: 200, borderRadius: 12 }} />
      </Box>
    </FrontLayout>
  );
}

export default NewTalent;
