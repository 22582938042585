import { computeProgressEvent, IProgressEvent } from '@room-match/shared-utils';
import { ENDPOINTS } from 'shared/constants/ENDPOINTS';
import { useAxios } from 'shared/hooks/useAxios';
import { IGetAnswersRequestPayload, ISaveAnswersRequestPayload } from 'shared/interfaces/ISession';
import {
  IStudioSessionAttachQuestionPayload,
  IStudioSessionCreatePayload,
  IStudioSessionCreateResponsePayload,
  IStudioSessionImportResponsePayload,
  IStudioSessionMediumAttachmentRequestPayload,
  IStudioSessionMediumAttachmentResponsePayload,
  IStudioSessionAttachQuestionResponsePayload,
  IStudioSessionResponsePayload,
} from 'shared/interfaces/IStudioSession';
import { IOnProgress } from 'shared/interfaces/utils/IAxios';
import { GET_SESSION_MEDIUM_ATTACHMENTS } from 'shared/queries/studioSession.queries';
import { authToken } from 'shared/utils/authToken';

const { POST, PUT, GET } = useAxios();
export const studioSessionDao = () => {
  const { getAuthToken } = authToken();

  const getStudioSessions = async (projectId: string) => {
    const response = await GET<IStudioSessionResponsePayload>({
      url: `${ENDPOINTS.PROJECT}/${projectId}/sessions`,
    });
    return response.data;
  };

  const getAnswers = async (auditionScheduleId: string) => {
    const response = await GET<IGetAnswersRequestPayload>({
      url: `${ENDPOINTS.AUDITION_SCHEDULES}/${auditionScheduleId}/answers`,
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
      },
    });
    return response.data;
  };

  const createStudioSession = async (projectId: string, payload: IStudioSessionCreatePayload) => {
    const response = await POST<IStudioSessionCreateResponsePayload>({
      url: `${ENDPOINTS.PROJECT}/${projectId}/sessions`,
      data: payload,
    });
    return response.data;
  };

  const importStudioSession = async (formData: FormData, onProgress?: IOnProgress) => {
    const response = await PUT<IStudioSessionImportResponsePayload>({
      url: ENDPOINTS.PROJECT,
      data: formData,
      onUploadProgress: (progressEvent: IProgressEvent) => {
        if (onProgress) {
          onProgress(computeProgressEvent(progressEvent));
        }
      },
    });

    return response.data;
  };

  const getSessionMediumAttachments = async (payload: IStudioSessionMediumAttachmentRequestPayload) => {
    const response = await POST<IStudioSessionMediumAttachmentResponsePayload>({
      url: ENDPOINTS.GRAPHQL,
      data: {
        query: GET_SESSION_MEDIUM_ATTACHMENTS,
        variables: { ...payload },
      },
    });

    return response.data;
  };

  const attachStudioSessionQuestion = async (
    projectId: string,
    sessionId: string,
    payload: IStudioSessionAttachQuestionPayload,
  ) => {
    const response = await POST<IStudioSessionAttachQuestionResponsePayload>({
      url: `${ENDPOINTS.PROJECT}/${projectId}/sessions/${sessionId}/question_attachments`,
      data: payload,
    });
    return response.data;
  };

  const checkIn = async (sessionId: string) => {
    const response = await POST<IStudioSessionCreateResponsePayload>({
      url: `${ENDPOINTS.SESSION}/${sessionId}/check_in`,
    });
    return response.data;
  };

  const saveAnswers = async (auditionScheduleId: string, payload: ISaveAnswersRequestPayload) => {
    const response = await POST<ISaveAnswersRequestPayload>({
      url: `${ENDPOINTS.AUDITION_SCHEDULES}/${auditionScheduleId}/answers`,
      data: payload,
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
      },
    });
    return response.data;
  };

  return {
    getStudioSessions,
    createStudioSession,
    importStudioSession,
    getSessionMediumAttachments,
    attachStudioSessionQuestion,
    checkIn,
    saveAnswers,
    getAnswers,
  };
};
