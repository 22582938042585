import { useMutation, useQuery } from 'react-query';
import { studioSessionDao } from 'shared/dao/studioSessionDao';
import { IGetAnswersRequestPayload, ISaveAnswersRequestPayload } from 'shared/interfaces/ISession';
import {
  IStudioSessionCreatePayload,
  IStudioSessionCreateResponsePayload,
  IStudioSessionImportResponsePayload,
  IStudioSessionMediumAttachmentRequestPayload,
  IStudioSessionMediumAttachmentResponsePayload,
  IStudioSessionAttachQuestionPayload,
  IStudioSessionAttachQuestionResponsePayload,
  IStudioSessionResponsePayload,
} from 'shared/interfaces/IStudioSession';
import { IAxiosErrorResponse, IOnProgress } from 'shared/interfaces/utils/IAxios';

const {
  getStudioSessions: getStudioSessionsDao,
  createStudioSession: createStudioSessionDao,
  importStudioSession: importStudioSessionDao,
  getSessionMediumAttachments: getSessionMediumAttachmentsDao,
  attachStudioSessionQuestion: attachStudioSessionQuestionDao,
  checkIn: checkInDao,
  saveAnswers: saveAnswersDao,
  getAnswers: getAnswersDao,
} = studioSessionDao();
export const studioSessionService = () => {
  const getStudioSessions = (projectId: string) => {
    return useQuery<IStudioSessionResponsePayload, IAxiosErrorResponse>(['studioSession', projectId], () =>
      getStudioSessionsDao(projectId),
    );
  };

  const getAnswers = (auditionScheduleId: string) => {
    return useQuery<IGetAnswersRequestPayload, IAxiosErrorResponse>(['audition_answers'], () =>
      getAnswersDao(auditionScheduleId),
    );
  };

  const createStudioSession = () => {
    return useMutation<
      IStudioSessionCreateResponsePayload,
      IAxiosErrorResponse,
      { projectId: string; payload: IStudioSessionCreatePayload }
    >(({ projectId, payload }) => createStudioSessionDao(projectId, payload));
  };
  const importStudioSession = () => {
    return useMutation<
      IStudioSessionImportResponsePayload,
      IAxiosErrorResponse,
      { formData: FormData; onProgress?: IOnProgress }
    >(({ formData, onProgress }) => importStudioSessionDao(formData, onProgress));
  };

  const getSessionMediumAttachments = (payload: IStudioSessionMediumAttachmentRequestPayload) => {
    return useQuery<
      IStudioSessionMediumAttachmentResponsePayload,
      IAxiosErrorResponse,
      IStudioSessionMediumAttachmentRequestPayload
    >(['studioSession/mediumAttachments', payload], () => getSessionMediumAttachmentsDao(payload));
  };

  const attachStudioSessionQuestion = () => {
    return useMutation<
      IStudioSessionAttachQuestionResponsePayload,
      IAxiosErrorResponse,
      { projectId: string; sessionId: string; payload: IStudioSessionAttachQuestionPayload }
    >(({ projectId, sessionId, payload }) => attachStudioSessionQuestionDao(projectId, sessionId, payload));
  };

  // const checkIn = (projectId: string) => {
  //   return useQuery<IStudioSessionResponsePayload, IAxiosErrorResponse>(['studioSession', projectId], () =>
  //     checkInDao(projectId),
  //   );
  // };

  const saveAnswers = () => {
    return useMutation<
      ISaveAnswersRequestPayload,
      IAxiosErrorResponse,
      { auditionScheduleId: string; payload: ISaveAnswersRequestPayload }
    >(({ auditionScheduleId, payload }) => saveAnswersDao(auditionScheduleId, payload));
  };

  return {
    getStudioSessions,
    createStudioSession,
    importStudioSession,
    getSessionMediumAttachments,
    attachStudioSessionQuestion,
    saveAnswers,
    getAnswers,
  };
};
