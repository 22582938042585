import {
  Box,
  Button,
  Card,
  Chip,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  Link,
  TextField,
  Typography,
} from '@material-ui/core';

import { Backdrop, Select, SelectArrowDownIcon, TextInput } from '@room-match/shared-ui-components';

import { useEffect, useState } from 'react';
import { CardContent, useStyles } from './Personal.styles';

import { talentService } from 'shared/services/talentService';
import { authService } from 'shared/services/authService';
import { ls } from 'shared/utils/ls';
import Autocomplete from '@material-ui/lab/Autocomplete';

import age from 'data/Age.json';
import gender from 'data/Gender.json';
import region from 'data/Region.json';
import metric from 'data/Metric.json';
import hairColor from 'data/HairColor.json';
import hairLength from 'data/HairLength.json';
import eyeColor from 'data/EyeColor.json';
import height from 'data/Height.json';
import waist from 'data/Waist.json';
import weight from 'data/Weight.json';
import hipSize from 'data/HipSize.json';
import ethnicity from 'data/Ethnicities.json';
import talentTypes from 'data/TalentTypes.json';
import tshirtSize from 'data/TShirtSize.json';
import suitSize from 'data/SuitSize.json';
import chestSize from 'data/ChestSize.json';
import bustSize from 'data/BustSize.json';
import braSize from 'data/BraSize.json';
import femaleSuitSize from 'data/FemaleSuitSizes.json';
import kidClothing from 'data/KidClothesSize.json';
import maleShoeSize from 'data/MaleShoeSize.json';
import femaleShoeSize from 'data/FemaleShoeSize.json';
import kidShoeSize from 'data/KidShoeSize.json';

import { usePersonalForm } from './PersonalForm';
import { IStatisticsValues, ITalentUpdatePayload } from 'shared/interfaces/ITalent';
import { useQueryClient } from 'react-query';
import { accountService } from 'shared/services/accountService';
import { AiOutlineMinusCircle } from 'react-icons/ai';
import { ColorGauge } from 'shared/enums/Color';
import EthnicityDialog from 'pages/MyInfo/SizeCard/EthnicityDialog/EthnicityDialog';
import TalentTypesDialog from 'pages/MyInfo/SizeCard/TalentTypesDialog/TalentTypesDialog';
import useUnsavedChangesWarning from 'shared/hooks/useUnsaveChangesWarning';

const { updateTalent, getStatistics } = talentService();
const { getUserProfile } = authService();
const { getAccount } = accountService();

type Props = {
  setActiveStep(steps: number): void;
  activeStep: number;
  data: any;
  personalDone: VoidFunction;
};

const Personal: React.FC<Props> = ({ setActiveStep, activeStep, data, personalDone }) => {
  const classes = useStyles();

  const { mutate, isLoading: isUpdateLoading } = updateTalent();
  const { data: sData, isLoading } = getStatistics();
  const { data: talentData } = getAccount();
  const [changes, setchanges] = useState(false);

  const queryClient = useQueryClient();

  const { routerPrompt, onDirty, onPristine } = useUnsavedChangesWarning(
    'You have unsave changes, this will be discarded on exit. Do you wish to continue?',
  );

  const [regionValue, setRegionValue] = useState('US');
  const [metricValue, setMetricValue] = useState(
    sData && sData.data.attributes.statistics ? sData.data.attributes.statistics.metric_system : 'Imperial',
  );

  const ethnicityOptions = ethnicity.map((i) => i);
  const talentTypesOptions = talentTypes.map((i) => i);

  const heightOptions = height.map((i) => (metricValue === 'AL' ? i.AL : metricValue === 'CM' ? i.CM : i.IN));
  const filteredHeightOptions: any[] = [];
  heightOptions.forEach((e) => {
    if (!filteredHeightOptions.includes(e)) {
      filteredHeightOptions.push(e);
    }
  });

  const waistOptions = waist.map((i) => (metricValue === 'AL' ? i.AL : metricValue === 'CM' ? i.CM : i.IN));
  const filteredWaistOptions: any[] = [];
  waistOptions.forEach((e) => {
    if (!filteredWaistOptions.includes(e)) {
      filteredWaistOptions.push(e);
    }
  });

  const chestOptions = chestSize.map((i) => (metricValue === 'AL' ? i.AL : metricValue === 'CM' ? i.CM : i.IN));
  const filteredChestOptions: any[] = [];
  chestOptions.forEach((e) => {
    if (!filteredChestOptions.includes(e)) {
      filteredChestOptions.push(e);
    }
  });

  const bustOptions = bustSize.map((i) => (metricValue === 'AL' ? i.AL : metricValue === 'CM' ? i.CM : i.IN));
  const filteredBustOptions: any[] = [];
  bustOptions.forEach((e) => {
    if (!filteredBustOptions.includes(e)) {
      filteredBustOptions.push(e);
    }
  });

  const [isEthnicityDialogOpen, setIsEthnicityDialogOpen] = useState<boolean>(false);
  const handleOpenEthnicityDialog = () => setIsEthnicityDialogOpen(true);
  const handleCloseEthnicityDialog = () => setIsEthnicityDialogOpen(false);

  const [isTalentTypesDialogOpen, setIsTalentTypesDialogOpen] = useState<boolean>(false);
  const handleOpenTalentTypesDialog = () => setIsTalentTypesDialogOpen(true);
  const handleCloseTalentTypesDialog = () => setIsTalentTypesDialogOpen(false);

  const handleSubmit = async (values: ITalentUpdatePayload) => {
    mutate(values, {
      onSuccess: () => {
        queryClient.invalidateQueries('talents/statistics');
        // setActiveStep(activeStep + 1);
        personalDone();
      },
    });
  };

  const { form } = usePersonalForm({
    data: sData?.data,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (sData) {
      if (sData?.data.attributes.statistics && sData?.data.attributes.statistics.region) {
        if (sData.data.attributes.statistics.region === 'United States') setRegionValue('US');
        else if (sData.data.attributes.statistics.region === 'Australian') setRegionValue('AU');
        else if (sData.data.attributes.statistics.region === 'New Zealand') setRegionValue('NZ');
        else if (sData.data.attributes.statistics.region === 'Europe') setRegionValue('EU');
        else setRegionValue('UK');

        if (sData.data.attributes.statistics.metric_system === 'Metric/Imperial') setMetricValue('AL');
        else if (sData.data.attributes.statistics.metric_system === 'Imperial') setMetricValue('IN');
        else setMetricValue('CM');
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sData]);

  const getMetricSystem = (e: any) => {
    form.setFieldValue('statistics.height', '');
    form.setFieldValue('statistics.weight', '');
    form.setFieldValue('statistics.waist', '');
    form.setFieldValue('statistics.inside_leg', '');
    form.setFieldValue('statistics.outside_leg', '');
    form.setFieldValue('statistics.chest_size', '');
    if (e === 'Metric/Imperial') setMetricValue('AL');
    else if (e === 'Imperial') setMetricValue('IN');
    else setMetricValue('CM');
  };

  function clearRegionDependentFields() {
    form.setFieldValue('statistics.coat_size', '');
    form.setFieldValue('statistics.dress_size', '');
    form.setFieldValue('statistics.shoe_size', '');
    form.setFieldValue('statistics.pants_size', '');
  }

  useEffect(() => {
    if (JSON.stringify(form.initialValues.statistics) !== JSON.stringify(form.values.statistics)) {
      onDirty();
    } else {
      onPristine();
    }
    setchanges(JSON.stringify(form.initialValues.statistics) !== JSON.stringify(form.values.statistics));
  }, [form, onDirty, onPristine]);

  return (
    <>
      <Box style={{ height: '62vh', overflowY: 'auto', overflowX: 'hidden', padding: '0 0 20px 20px' }}>
        <Grid container spacing={2}>
          <Grid xs={12} md={12} item>
            <Card>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid xs={12} md={6} lg={4} item>
                    <FormControl margin={'normal'} fullWidth>
                      <InputLabel id="labelCountry" shrink>
                        Region
                      </InputLabel>
                      <div className={classes.autocompleteContainer}>
                        <Autocomplete
                          onChange={(e, newValue) => {
                            form.setFieldValue('statistics.region', newValue);
                            form.handleChange(newValue);
                            clearRegionDependentFields();
                            if (newValue === 'United States') setRegionValue('US');
                            else if (newValue === 'Australian') setRegionValue('AU');
                            else if (newValue === 'New Zealand') setRegionValue('NZ');
                            else if (newValue === 'Europe') setRegionValue('EU');
                            else setRegionValue('UK');
                          }}
                          value={form.values.statistics?.region}
                          options={region.map((i) => i.value)}
                          getOptionSelected={(option, value) => option === value}
                          fullWidth
                          renderInput={(params) => (
                            <TextField {...params} placeholder={'Select Region'} variant="outlined" margin="normal" />
                          )}
                        />
                      </div>
                    </FormControl>
                  </Grid>
                  <Grid xs={12} md={6} lg={4} item>
                    <FormControl margin={'normal'} fullWidth>
                      <InputLabel id="labelAgeType" shrink>
                        Adult/Minor
                      </InputLabel>
                      <div className={classes.autocompleteContainer}>
                        <Autocomplete
                          onChange={(e, newValue) => {
                            form.setFieldValue('statistics.adult_minor', newValue);
                            form.handleChange(newValue);
                          }}
                          value={form.values.statistics?.adult_minor}
                          options={age.map((i) => i.value)}
                          getOptionSelected={(option, value) => option === value}
                          fullWidth
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder={'Select Adult/Minor'}
                              variant="outlined"
                              margin="normal"
                            />
                          )}
                        />
                      </div>
                    </FormControl>
                  </Grid>
                  <Grid xs={12} md={6} lg={4} item>
                    <FormControl margin={'normal'} fullWidth>
                      <InputLabel id="labelSizeUnit" shrink>
                        Metric/Imperial
                      </InputLabel>
                      <div className={classes.autocompleteContainer}>
                        <Autocomplete
                          onChange={(e, newValue) => {
                            form.setFieldValue('statistics.metric_system', newValue);
                            form.handleChange(newValue);
                            getMetricSystem(newValue);
                          }}
                          value={form.values.statistics?.metric_system}
                          options={metric.map((i) => i.value)}
                          getOptionSelected={(option, value) => option === value}
                          fullWidth
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder={'Select Metric System'}
                              variant="outlined"
                              margin="normal"
                            />
                          )}
                        />
                      </div>
                    </FormControl>
                  </Grid>
                  <Grid xs={12} md={6} lg={4} item>
                    <InputLabel id="representationStatus">Union</InputLabel>
                    <Select
                      variant="outlined"
                      fullWidth
                      data={
                        talentData?.data.attributes.unions.map((u) => {
                          return { key: u, value: u };
                        }) || []
                      }
                      value={form.values.union}
                      onChange={(e) => {
                        form.handleChange(e);
                        form.setFieldValue('union', e.target.value);
                      }}
                      onBlur={form.handleBlur}
                      IconComponent={SelectArrowDownIcon}
                      placeHolder="Select"
                      disabled={!!!talentData?.data.attributes.unions.length}
                    />
                  </Grid>
                  {form.values.union && (
                    <Grid item>
                      <TextInput
                        label={'Sag ID'}
                        placeholder="Enter number…"
                        value={form.values.sag_id || talentData?.data.attributes.sag_id}
                        onChange={(e) => {
                          form.handleChange(e);
                          form.setFieldValue('sag_id', e.target.value);
                        }}
                        onBlur={form.handleBlur}
                        disabled={data !== null ? false : true}
                      />
                    </Grid>
                  )}
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid xs={12} md={12} item>
            <Card>
              <CardContent>
                {form.values.statistics?.adult_minor === 'Minor' ? (
                  <Grid>
                    <Grid container className={classes.profileItemsContainer} spacing={2}>
                      <Grid xs={12} md={6} lg={4} item>
                        <FormControl margin={'normal'} fullWidth>
                          <InputLabel id="labelCountry" shrink>
                            Height
                          </InputLabel>
                          <div className={classes.autocompleteContainer}>
                            <Autocomplete
                              onChange={(e, newValue) => {
                                form.setFieldValue('statistics.height', newValue);
                                form.handleChange(newValue);
                              }}
                              value={form.values.statistics?.height}
                              options={filteredHeightOptions}
                              getOptionSelected={(option, value) => option === value}
                              fullWidth
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder={'Select Height'}
                                  variant="outlined"
                                  margin="normal"
                                />
                              )}
                            />
                          </div>
                        </FormControl>
                      </Grid>
                      <Grid xs={12} md={6} lg={4} item>
                        <FormControl margin={'normal'} fullWidth>
                          <InputLabel id="labelCountry" shrink>
                            Weight
                          </InputLabel>
                          <div className={classes.autocompleteContainer}>
                            <Autocomplete
                              onChange={(e, newValue) => {
                                form.setFieldValue('statistics.weight', newValue);
                                form.handleChange(newValue);
                              }}
                              value={form.values.statistics?.weight}
                              options={weight.map((i) =>
                                metricValue === 'AL' ? i.AL : metricValue === 'CM' ? i.CM : i.IN,
                              )}
                              getOptionSelected={(option, value) => option === value}
                              fullWidth
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder={'Select Weight'}
                                  variant="outlined"
                                  margin="normal"
                                />
                              )}
                            />
                          </div>
                        </FormControl>
                      </Grid>
                      <Grid xs={12} md={6} lg={4} item>
                        <FormControl margin={'normal'} fullWidth>
                          <InputLabel id="labelCountry" shrink>
                            Hair Color
                          </InputLabel>
                          <div className={classes.autocompleteContainer}>
                            <Autocomplete
                              onChange={(e, newValue) => {
                                form.setFieldValue('statistics.hair_color', newValue);
                                form.handleChange(newValue);
                              }}
                              value={form.values.statistics?.hair_color}
                              options={hairColor.map((i) => i.value)}
                              getOptionSelected={(option, value) => option === value}
                              fullWidth
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder={'Select Hair Color'}
                                  variant="outlined"
                                  margin="normal"
                                />
                              )}
                            />
                          </div>
                        </FormControl>
                      </Grid>
                      <Grid xs={12} md={6} lg={4} item>
                        <FormControl margin={'normal'} fullWidth>
                          <InputLabel id="labelCountry" shrink>
                            Hair Length
                          </InputLabel>
                          <div className={classes.autocompleteContainer}>
                            <Autocomplete
                              onChange={(e, newValue) => {
                                form.setFieldValue('statistics.hair_length', newValue);
                                form.handleChange(newValue);
                              }}
                              value={form.values.statistics?.hair_length}
                              options={hairLength.map((i) => i.name)}
                              getOptionSelected={(option, value) => option === value}
                              fullWidth
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder={'Select Hair Length'}
                                  variant="outlined"
                                  margin="normal"
                                />
                              )}
                            />
                          </div>
                        </FormControl>
                      </Grid>

                      <Grid xs={12} md={6} lg={4} item>
                        <FormControl margin={'normal'} fullWidth>
                          <InputLabel id="labelCountry" shrink>
                            Eye Color
                          </InputLabel>
                          <div className={classes.autocompleteContainer}>
                            <Autocomplete
                              onChange={(e, newValue) => {
                                form.setFieldValue('statistics.eye_color', newValue);
                                form.handleChange(newValue);
                              }}
                              value={form.values.statistics?.eye_color}
                              options={eyeColor.map((i) => i.value)}
                              getOptionSelected={(option, value) => option === value}
                              fullWidth
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder={'Select Eye Color'}
                                  variant="outlined"
                                  margin="normal"
                                />
                              )}
                            />
                          </div>
                        </FormControl>
                      </Grid>

                      <Grid xs={12} md={6} lg={4} item>
                        <FormControl margin={'normal'} fullWidth>
                          <InputLabel id="labelCountry" shrink>
                            Child Clothing Size
                          </InputLabel>
                          <div className={classes.autocompleteContainer}>
                            <Autocomplete
                              onChange={(e, newValue) => {
                                form.setFieldValue('statistics.suit_size', newValue);
                                form.handleChange(newValue);
                              }}
                              value={form.values.statistics?.coat_size}
                              options={kidClothing.filter((i) => i.region === regionValue).map((j) => j.value)}
                              getOptionSelected={(option, value) => option === value}
                              fullWidth
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder={'Select Child Clothing Size'}
                                  variant="outlined"
                                  margin="normal"
                                />
                              )}
                            />
                          </div>
                        </FormControl>
                      </Grid>
                      {talentData?.data.attributes.gender === 'Male' ||
                      talentData?.data.attributes.gender === 'Trans Man' ? (
                        <Grid xs={12} md={6} lg={4} item>
                          <FormControl margin={'normal'} fullWidth>
                            <InputLabel id="labelCountry" shrink>
                              Chest Size
                            </InputLabel>
                            <div className={classes.autocompleteContainer}>
                              <Autocomplete
                                onChange={(e, newValue) => {
                                  form.setFieldValue('statistics.chest_size', newValue);
                                  form.handleChange(newValue);
                                }}
                                value={form.values.statistics?.chest_size}
                                options={filteredChestOptions}
                                getOptionSelected={(option, value) => option === value}
                                fullWidth
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    placeholder={'Select Chest Size'}
                                    variant="outlined"
                                    margin="normal"
                                  />
                                )}
                              />
                            </div>
                          </FormControl>
                        </Grid>
                      ) : talentData?.data.attributes.gender === 'Female' ||
                        talentData?.data.attributes.gender === 'Trans Woman' ? (
                        <>
                          <Grid xs={12} md={6} lg={4} item>
                            <FormControl margin={'normal'} fullWidth>
                              <InputLabel id="labelCountry" shrink>
                                Bust Size
                              </InputLabel>
                              <div className={classes.autocompleteContainer}>
                                <Autocomplete
                                  onChange={(e, newValue) => {
                                    form.setFieldValue('statistics.bust_size', newValue);
                                    form.handleChange(newValue);
                                  }}
                                  value={form.values.statistics?.bust_size}
                                  options={bustSize.map((i) =>
                                    metricValue === 'AL' ? i.AL : metricValue === 'CM' ? i.CM : i.IN,
                                  )}
                                  getOptionSelected={(option, value) => option === value}
                                  fullWidth
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      placeholder={'Select Bust Size'}
                                      variant="outlined"
                                      margin="normal"
                                    />
                                  )}
                                />
                              </div>
                            </FormControl>
                          </Grid>
                          <Grid xs={12} md={6} lg={4} item>
                            <FormControl margin={'normal'} fullWidth>
                              <InputLabel id="labelCountry" shrink>
                                Bra Size
                              </InputLabel>
                              <div className={classes.autocompleteContainer}>
                                <Autocomplete
                                  onChange={(e, newValue) => {
                                    form.setFieldValue('statistics.bra_size', newValue);
                                    form.handleChange(newValue);
                                  }}
                                  value={form.values.statistics?.bra_size}
                                  options={filteredBustOptions}
                                  getOptionSelected={(option, value) => option === value}
                                  fullWidth
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      placeholder={'Select Bra Size'}
                                      variant="outlined"
                                      margin="normal"
                                    />
                                  )}
                                />
                              </div>
                            </FormControl>
                          </Grid>
                        </>
                      ) : (
                        <>
                          <Grid xs={12} md={6} lg={4} item>
                            <FormControl margin={'normal'} fullWidth>
                              <InputLabel id="labelCountry" shrink>
                                Chest Size
                              </InputLabel>
                              <div className={classes.autocompleteContainer}>
                                <Autocomplete
                                  onChange={(e, newValue) => {
                                    form.setFieldValue('statistics.chest_size', newValue);
                                    form.handleChange(newValue);
                                  }}
                                  value={form.values.statistics?.chest_size}
                                  options={filteredChestOptions}
                                  getOptionSelected={(option, value) => option === value}
                                  fullWidth
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      placeholder={'Select Chest Size'}
                                      variant="outlined"
                                      margin="normal"
                                    />
                                  )}
                                />
                              </div>
                            </FormControl>
                          </Grid>
                          <Grid xs={12} md={6} lg={4} item>
                            <FormControl margin={'normal'} fullWidth>
                              <InputLabel id="labelCountry" shrink>
                                Bust Size
                              </InputLabel>
                              <div className={classes.autocompleteContainer}>
                                <Autocomplete
                                  onChange={(e, newValue) => {
                                    form.setFieldValue('statistics.bust_size', newValue);
                                    form.handleChange(newValue);
                                  }}
                                  value={form.values.statistics?.bust_size}
                                  options={filteredBustOptions}
                                  getOptionSelected={(option, value) => option === value}
                                  fullWidth
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      placeholder={'Select Bust Size'}
                                      variant="outlined"
                                      margin="normal"
                                    />
                                  )}
                                />
                              </div>
                            </FormControl>
                          </Grid>
                          <Grid xs={12} md={6} lg={4} item>
                            <FormControl margin={'normal'} fullWidth>
                              <InputLabel id="labelCountry" shrink>
                                Bra Size
                              </InputLabel>
                              <div className={classes.autocompleteContainer}>
                                <Autocomplete
                                  onChange={(e, newValue) => {
                                    form.setFieldValue('statistics.bra_size', newValue);
                                    form.handleChange(newValue);
                                  }}
                                  value={form.values.statistics?.bra_size}
                                  options={bustSize.map((i) =>
                                    metricValue === 'AL' ? i.AL : metricValue === 'CM' ? i.CM : i.IN,
                                  )}
                                  getOptionSelected={(option, value) => option === value}
                                  fullWidth
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      placeholder={'Select Bra Size'}
                                      variant="outlined"
                                      margin="normal"
                                    />
                                  )}
                                />
                              </div>
                            </FormControl>
                          </Grid>
                        </>
                      )}
                      <Grid xs={12} md={6} lg={4} item>
                        <FormControl margin={'normal'} fullWidth>
                          <InputLabel id="labelCountry" shrink>
                            Waist
                          </InputLabel>
                          <div className={classes.autocompleteContainer}>
                            <Autocomplete
                              onChange={(e, newValue) => {
                                form.setFieldValue('statistics.waist', newValue);
                                form.handleChange(newValue);
                              }}
                              value={form.values.statistics?.waist}
                              options={filteredWaistOptions}
                              getOptionSelected={(option, value) => option === value}
                              fullWidth
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder={'Select Waist'}
                                  variant="outlined"
                                  margin="normal"
                                />
                              )}
                            />
                          </div>
                        </FormControl>
                      </Grid>
                      {talentData?.data.attributes.gender === 'Male' ||
                      talentData?.data.attributes.gender === 'Trans Man' ? (
                        ''
                      ) : (
                        <Grid xs={12} md={6} lg={4} item>
                          <FormControl margin={'normal'} fullWidth>
                            <InputLabel id="labelCountry" shrink>
                              Hip Size
                            </InputLabel>
                            <div className={classes.autocompleteContainer}>
                              <Autocomplete
                                onChange={(e, newValue) => {
                                  form.setFieldValue('statistics.hip_size', newValue);
                                  form.handleChange(newValue);
                                }}
                                value={form.values.statistics?.hip_size}
                                options={hipSize.map((i) =>
                                  metricValue === 'AL' ? i.AL : metricValue === 'CM' ? i.CM : i.IN,
                                )}
                                getOptionSelected={(option, value) => option === value}
                                fullWidth
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    placeholder={'Select Hip Size'}
                                    variant="outlined"
                                    margin="normal"
                                  />
                                )}
                              />
                            </div>
                          </FormControl>
                        </Grid>
                      )}
                      <Grid xs={12} md={6} lg={4} item>
                        <FormControl margin={'normal'} fullWidth>
                          <InputLabel id="labelCountry" shrink>
                            Child Shoe Size
                          </InputLabel>
                          <div className={classes.autocompleteContainer}>
                            <Autocomplete
                              onChange={(e, newValue) => {
                                form.setFieldValue('statistics.shoe_size', newValue);
                                form.handleChange(newValue);
                              }}
                              value={form.values.statistics?.shoe_size}
                              options={maleShoeSize.map((i) => i.value)}
                              getOptionSelected={(option, value) => option === value}
                              fullWidth
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder={'Select Child Shoe Size'}
                                  variant="outlined"
                                  margin="normal"
                                />
                              )}
                            />
                          </div>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid>
                    <Grid container className={classes.profileItemsContainer} spacing={2}>
                      <Grid xs={12} md={6} lg={4} item>
                        <FormControl margin={'normal'} fullWidth>
                          <InputLabel id="labelCountry" shrink>
                            Height
                          </InputLabel>
                          <div className={classes.autocompleteContainer}>
                            <Autocomplete
                              onChange={(e, newValue) => {
                                form.setFieldValue('statistics.height', newValue);
                                form.handleChange(newValue);
                              }}
                              value={form.values.statistics?.height}
                              options={filteredHeightOptions}
                              getOptionSelected={(option, value) => option === value}
                              fullWidth
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder={'Select Height'}
                                  variant="outlined"
                                  margin="normal"
                                />
                              )}
                            />
                          </div>
                        </FormControl>
                      </Grid>
                      <Grid xs={12} md={6} lg={4} item>
                        <FormControl margin={'normal'} fullWidth>
                          <InputLabel id="labelCountry" shrink>
                            Weight
                          </InputLabel>
                          <div className={classes.autocompleteContainer}>
                            <Autocomplete
                              onChange={(e, newValue) => {
                                form.setFieldValue('statistics.weight', newValue);
                                form.handleChange(newValue);
                              }}
                              value={form.values.statistics?.weight}
                              options={weight.map((i) =>
                                metricValue === 'AL' ? i.AL : metricValue === 'CM' ? i.CM : i.IN,
                              )}
                              getOptionSelected={(option, value) => option === value}
                              fullWidth
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder={'Select Weight'}
                                  variant="outlined"
                                  margin="normal"
                                />
                              )}
                            />
                          </div>
                        </FormControl>
                      </Grid>
                      <Grid xs={12} md={6} lg={4} item>
                        <FormControl margin={'normal'} fullWidth>
                          <InputLabel id="labelCountry" shrink>
                            Hair Color
                          </InputLabel>
                          <div className={classes.autocompleteContainer}>
                            <Autocomplete
                              onChange={(e, newValue) => {
                                form.setFieldValue('statistics.hair_color', newValue);
                                form.handleChange(newValue);
                              }}
                              value={form.values.statistics?.hair_color}
                              options={hairColor.map((i) => i.value)}
                              getOptionSelected={(option, value) => option === value}
                              fullWidth
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder={'Select Hair Color'}
                                  variant="outlined"
                                  margin="normal"
                                />
                              )}
                            />
                          </div>
                        </FormControl>
                      </Grid>
                      <Grid xs={12} md={6} lg={4} item>
                        <FormControl margin={'normal'} fullWidth>
                          <InputLabel id="labelCountry" shrink>
                            Hair Length
                          </InputLabel>
                          <div className={classes.autocompleteContainer}>
                            <Autocomplete
                              onChange={(e, newValue) => {
                                form.setFieldValue('statistics.hair_length', newValue);
                                form.handleChange(newValue);
                              }}
                              value={form.values.statistics?.hair_length}
                              options={hairLength.map((i) => i.name)}
                              getOptionSelected={(option, value) => option === value}
                              fullWidth
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder={'Select Hair Length'}
                                  variant="outlined"
                                  margin="normal"
                                />
                              )}
                            />
                          </div>
                        </FormControl>
                      </Grid>
                      <Grid xs={12} md={6} lg={4} item>
                        <FormControl margin={'normal'} fullWidth>
                          <InputLabel id="labelCountry" shrink>
                            Eye Color
                          </InputLabel>
                          <div className={classes.autocompleteContainer}>
                            <Autocomplete
                              onChange={(e, newValue) => {
                                form.setFieldValue('statistics.eye_color', newValue);
                                form.handleChange(newValue);
                              }}
                              value={form.values.statistics?.eye_color}
                              options={eyeColor.map((i) => i.value)}
                              getOptionSelected={(option, value) => option === value}
                              fullWidth
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder={'Select Eye Color'}
                                  variant="outlined"
                                  margin="normal"
                                />
                              )}
                            />
                          </div>
                        </FormControl>
                      </Grid>

                      {talentData?.data.attributes.gender === 'Male' ||
                      talentData?.data.attributes.gender === 'Trans Man' ? (
                        <Grid xs={12} md={6} lg={4} item>
                          <FormControl margin={'normal'} fullWidth>
                            <InputLabel id="labelCountry" shrink>
                              Coat Size
                            </InputLabel>
                            <div className={classes.autocompleteContainer}>
                              <Autocomplete
                                onChange={(e, newValue) => {
                                  form.setFieldValue('statistics.coat_size', newValue);
                                  form.handleChange(newValue);
                                }}
                                value={form.values.statistics?.coat_size}
                                options={suitSize.filter((i) => i.region === regionValue).map((j) => j.value)}
                                getOptionSelected={(option, value) => option === value}
                                fullWidth
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    placeholder={'Select Suit Size'}
                                    variant="outlined"
                                    margin="normal"
                                  />
                                )}
                              />
                            </div>
                          </FormControl>
                        </Grid>
                      ) : talentData?.data.attributes.gender === 'Female' ||
                        talentData?.data.attributes.gender === 'Trans Woman' ? (
                        <Grid xs={12} md={6} lg={4} item>
                          <FormControl margin={'normal'} fullWidth>
                            <InputLabel id="labelCountry" shrink>
                              Dress Size
                            </InputLabel>
                            <div className={classes.autocompleteContainer}>
                              <Autocomplete
                                onChange={(e, newValue) => {
                                  form.setFieldValue('statistics.dress_size', newValue);
                                  form.handleChange(newValue);
                                }}
                                value={form.values.statistics?.dress_size}
                                options={suitSize.filter((i) => i.region === regionValue).map((j) => j.value)}
                                getOptionSelected={(option, value) => option === value}
                                fullWidth
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    placeholder={'Select Dress Size'}
                                    variant="outlined"
                                    margin="normal"
                                  />
                                )}
                              />
                            </div>
                          </FormControl>
                        </Grid>
                      ) : (
                        <>
                          <Grid xs={12} md={6} lg={4} item>
                            <FormControl margin={'normal'} fullWidth>
                              <InputLabel id="labelCountry" shrink>
                                Coat Size
                              </InputLabel>
                              <div className={classes.autocompleteContainer}>
                                <Autocomplete
                                  onChange={(e, newValue) => {
                                    form.setFieldValue('statistics.coat_size', newValue);
                                    form.handleChange(newValue);
                                  }}
                                  value={form.values.statistics?.coat_size}
                                  options={suitSize.filter((i) => i.region === regionValue).map((j) => j.value)}
                                  getOptionSelected={(option, value) => option === value}
                                  fullWidth
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      placeholder={'Select Suit Size'}
                                      variant="outlined"
                                      margin="normal"
                                    />
                                  )}
                                />
                              </div>
                            </FormControl>
                          </Grid>
                          <Grid xs={12} md={6} lg={4} item>
                            <FormControl margin={'normal'} fullWidth>
                              <InputLabel id="labelCountry" shrink>
                                Dress Size
                              </InputLabel>
                              <div className={classes.autocompleteContainer}>
                                <Autocomplete
                                  onChange={(e, newValue) => {
                                    form.setFieldValue('statistics.dress_size', newValue);
                                    form.handleChange(newValue);
                                  }}
                                  value={form.values.statistics?.dress_size}
                                  options={suitSize.filter((i) => i.region === regionValue).map((j) => j.value)}
                                  getOptionSelected={(option, value) => option === value}
                                  fullWidth
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      placeholder={'Select Clothing Size'}
                                      variant="outlined"
                                      margin="normal"
                                    />
                                  )}
                                />
                              </div>
                            </FormControl>
                          </Grid>
                        </>
                      )}
                      <Grid xs={12} md={6} lg={4} item>
                        <FormControl margin={'normal'} fullWidth>
                          <InputLabel id="labelCountry" shrink>
                            Pants
                          </InputLabel>
                          <div className={classes.autocompleteContainer}>
                            <Autocomplete
                              onChange={(e, newValue) => {
                                form.setFieldValue('statistics.pants_size', newValue);
                                form.handleChange(newValue);
                              }}
                              value={form.values.statistics?.pants_size}
                              options={suitSize.map((i) => i.value)}
                              getOptionSelected={(option, value) => option === value}
                              fullWidth
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder={'Select Pants Size'}
                                  variant="outlined"
                                  margin="normal"
                                />
                              )}
                            />
                          </div>
                        </FormControl>
                      </Grid>
                      {talentData?.data.attributes.gender === 'Male' ||
                      talentData?.data.attributes.gender === 'Trans Man' ? (
                        <Grid xs={12} md={6} lg={4} item>
                          <FormControl margin={'normal'} fullWidth>
                            <InputLabel id="labelCountry" shrink>
                              Chest Size
                            </InputLabel>
                            <div className={classes.autocompleteContainer}>
                              <Autocomplete
                                onChange={(e, newValue) => {
                                  form.setFieldValue('statistics.chest_size', newValue);
                                  form.handleChange(newValue);
                                }}
                                value={form.values.statistics?.chest_size}
                                options={filteredChestOptions}
                                getOptionSelected={(option, value) => option === value}
                                fullWidth
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    placeholder={'Select Chest Size'}
                                    variant="outlined"
                                    margin="normal"
                                  />
                                )}
                              />
                            </div>
                          </FormControl>
                        </Grid>
                      ) : talentData?.data.attributes.gender === 'Female' ||
                        talentData?.data.attributes.gender === 'Trans Woman' ? (
                        <>
                          <Grid xs={12} md={6} lg={4} item>
                            <FormControl margin={'normal'} fullWidth>
                              <InputLabel id="labelCountry" shrink>
                                Bust Size
                              </InputLabel>
                              <div className={classes.autocompleteContainer}>
                                <Autocomplete
                                  onChange={(e, newValue) => {
                                    form.setFieldValue('statistics.bust_size', newValue);
                                    form.handleChange(newValue);
                                  }}
                                  value={form.values.statistics?.bust_size}
                                  options={filteredBustOptions}
                                  getOptionSelected={(option, value) => option === value}
                                  fullWidth
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      placeholder={'Select Bust Size'}
                                      variant="outlined"
                                      margin="normal"
                                    />
                                  )}
                                />
                              </div>
                            </FormControl>
                          </Grid>
                          <Grid xs={12} md={6} lg={4} item>
                            <FormControl margin={'normal'} fullWidth>
                              <InputLabel id="labelCountry" shrink>
                                Bra Size
                              </InputLabel>
                              <div className={classes.autocompleteContainer}>
                                <Autocomplete
                                  onChange={(e, newValue) => {
                                    form.setFieldValue('statistics.bra_size', newValue);
                                    form.handleChange(newValue);
                                  }}
                                  value={form.values.statistics?.bra_size}
                                  options={filteredBustOptions}
                                  getOptionSelected={(option, value) => option === value}
                                  fullWidth
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      placeholder={'Select Bra Size'}
                                      variant="outlined"
                                      margin="normal"
                                    />
                                  )}
                                />
                              </div>
                            </FormControl>
                          </Grid>
                        </>
                      ) : (
                        <>
                          <Grid xs={12} md={6} lg={4} item>
                            <FormControl margin={'normal'} fullWidth>
                              <InputLabel id="labelCountry" shrink>
                                Chest Size
                              </InputLabel>
                              <div className={classes.autocompleteContainer}>
                                <Autocomplete
                                  onChange={(e, newValue) => {
                                    form.setFieldValue('statistics.chest_size', newValue);
                                    form.handleChange(newValue);
                                  }}
                                  value={form.values.statistics?.chest_size}
                                  options={filteredChestOptions}
                                  getOptionSelected={(option, value) => option === value}
                                  fullWidth
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      placeholder={'Select Chest Size'}
                                      variant="outlined"
                                      margin="normal"
                                    />
                                  )}
                                />
                              </div>
                            </FormControl>
                          </Grid>
                          <Grid xs={12} md={6} lg={4} item>
                            <FormControl margin={'normal'} fullWidth>
                              <InputLabel id="labelCountry" shrink>
                                Bust Size
                              </InputLabel>
                              <div className={classes.autocompleteContainer}>
                                <Autocomplete
                                  onChange={(e, newValue) => {
                                    form.setFieldValue('statistics.bust_size', newValue);
                                    form.handleChange(newValue);
                                  }}
                                  value={form.values.statistics?.bust_size}
                                  options={filteredBustOptions}
                                  getOptionSelected={(option, value) => option === value}
                                  fullWidth
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      placeholder={'Select Bust Size'}
                                      variant="outlined"
                                      margin="normal"
                                    />
                                  )}
                                />
                              </div>
                            </FormControl>
                          </Grid>
                          <Grid xs={12} md={6} lg={4} item>
                            <FormControl margin={'normal'} fullWidth>
                              <InputLabel id="labelCountry" shrink>
                                Bra Size
                              </InputLabel>
                              <div className={classes.autocompleteContainer}>
                                <Autocomplete
                                  onChange={(e, newValue) => {
                                    form.setFieldValue('statistics.bra_size', newValue);
                                    form.handleChange(newValue);
                                  }}
                                  value={form.values.statistics?.bra_size}
                                  options={filteredBustOptions}
                                  getOptionSelected={(option, value) => option === value}
                                  fullWidth
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      placeholder={'Select Bra Size'}
                                      variant="outlined"
                                      margin="normal"
                                    />
                                  )}
                                />
                              </div>
                            </FormControl>
                          </Grid>
                        </>
                      )}
                      <Grid xs={12} md={6} lg={4} item>
                        <FormControl margin={'normal'} fullWidth>
                          <InputLabel id="labelCountry" shrink>
                            Waist
                          </InputLabel>
                          <div className={classes.autocompleteContainer}>
                            <Autocomplete
                              onChange={(e, newValue) => {
                                form.setFieldValue('statistics.waist', newValue);
                                form.handleChange(newValue);
                              }}
                              value={form.values.statistics?.waist}
                              options={filteredWaistOptions}
                              getOptionSelected={(option, value) => option === value}
                              fullWidth
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder={'Select Waist'}
                                  variant="outlined"
                                  margin="normal"
                                />
                              )}
                            />
                          </div>
                        </FormControl>
                      </Grid>
                      {talentData?.data.attributes.gender === 'Male' ||
                      talentData?.data.attributes.gender === 'Trans Man' ? (
                        <>
                          <Grid xs={12} md={6} lg={4} item>
                            <FormControl margin={'normal'} fullWidth>
                              <InputLabel id="labelCountry" shrink>
                                Inseam
                              </InputLabel>
                              <div className={classes.autocompleteContainer}>
                                <Autocomplete
                                  onChange={(e, newValue) => {
                                    form.setFieldValue('statistics.inside_leg', newValue);
                                    form.handleChange(newValue);
                                  }}
                                  value={form.values.statistics?.inside_leg}
                                  options={filteredWaistOptions}
                                  getOptionSelected={(option, value) => option === value}
                                  fullWidth
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      placeholder={'Select Inside Leg'}
                                      variant="outlined"
                                      margin="normal"
                                    />
                                  )}
                                />
                              </div>
                            </FormControl>
                          </Grid>
                          <Grid xs={12} md={6} lg={4} item>
                            <FormControl margin={'normal'} fullWidth>
                              <InputLabel id="labelCountry" shrink>
                                Outseam
                              </InputLabel>
                              <div className={classes.autocompleteContainer}>
                                <Autocomplete
                                  onChange={(e, newValue) => {
                                    form.setFieldValue('statistics.outside_leg', newValue);
                                    form.handleChange(newValue);
                                  }}
                                  value={form.values.statistics?.outside_leg}
                                  options={filteredWaistOptions}
                                  getOptionSelected={(option, value) => option === value}
                                  fullWidth
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      placeholder={'Select Outside Leg'}
                                      variant="outlined"
                                      margin="normal"
                                    />
                                  )}
                                />
                              </div>
                            </FormControl>
                          </Grid>
                          <Grid xs={12} md={6} lg={4} item>
                            <FormControl margin={'normal'} fullWidth>
                              <InputLabel id="labelCountry" shrink>
                                Neck Size
                              </InputLabel>
                              <div className={classes.autocompleteContainer}>
                                <Autocomplete
                                  onChange={(e, newValue) => {
                                    form.setFieldValue('statistics.neck_size', newValue);
                                    form.handleChange(newValue);
                                  }}
                                  value={form.values.statistics?.neck_size}
                                  options={[
                                    '1',
                                    '2',
                                    '3',
                                    '4',
                                    '5',
                                    '6',
                                    '7',
                                    '8',
                                    '9',
                                    '10',
                                    '11',
                                    '12',
                                    '13',
                                    '14',
                                    '15',
                                  ]}
                                  getOptionSelected={(option, value) => option === value}
                                  fullWidth
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      placeholder={'Select Neck Size'}
                                      variant="outlined"
                                      margin="normal"
                                    />
                                  )}
                                />
                              </div>
                            </FormControl>
                          </Grid>
                          <Grid xs={12} md={6} lg={4} item>
                            <FormControl margin={'normal'} fullWidth>
                              <InputLabel id="labelCountry" shrink>
                                Sleeve Size
                              </InputLabel>
                              <div className={classes.autocompleteContainer}>
                                <Autocomplete
                                  onChange={(e, newValue) => {
                                    form.setFieldValue('statistics.sleeves', newValue);
                                    form.handleChange(newValue);
                                  }}
                                  value={form.values.statistics?.sleeves}
                                  options={[
                                    '1',
                                    '2',
                                    '3',
                                    '4',
                                    '5',
                                    '6',
                                    '7',
                                    '8',
                                    '9',
                                    '10',
                                    '11',
                                    '12',
                                    '13',
                                    '14',
                                    '15',
                                  ]}
                                  getOptionSelected={(option, value) => option === value}
                                  fullWidth
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      placeholder={'Select Sleeve Size'}
                                      variant="outlined"
                                      margin="normal"
                                    />
                                  )}
                                />
                              </div>
                            </FormControl>
                          </Grid>
                          <Grid xs={12} md={6} lg={4} item>
                            <FormControl margin={'normal'} fullWidth>
                              <InputLabel id="labelCountry" shrink>
                                Hat Size
                              </InputLabel>
                              <div className={classes.autocompleteContainer}>
                                <Autocomplete
                                  onChange={(e, newValue) => {
                                    form.setFieldValue('statistics.hat', newValue);
                                    form.handleChange(newValue);
                                  }}
                                  value={form.values.statistics?.hat}
                                  options={filteredWaistOptions}
                                  getOptionSelected={(option, value) => option === value}
                                  fullWidth
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      placeholder={'Select Hat Size'}
                                      variant="outlined"
                                      margin="normal"
                                    />
                                  )}
                                />
                              </div>
                            </FormControl>
                          </Grid>
                          <Grid xs={12} md={6} lg={4} item>
                            <FormControl margin={'normal'} fullWidth>
                              <InputLabel id="labelCountry" shrink>
                                Glove Size
                              </InputLabel>
                              <div className={classes.autocompleteContainer}>
                                <Autocomplete
                                  onChange={(e, newValue) => {
                                    form.setFieldValue('statistics.gloves', newValue);
                                    form.handleChange(newValue);
                                  }}
                                  value={form.values.statistics?.gloves}
                                  options={[
                                    '1',
                                    '2',
                                    '3',
                                    '4',
                                    '5',
                                    '6',
                                    '7',
                                    '8',
                                    '9',
                                    '10',
                                    '11',
                                    '12',
                                    '13',
                                    '14',
                                    '15',
                                  ]}
                                  getOptionSelected={(option, value) => option === value}
                                  fullWidth
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      placeholder={'Select Glove Size'}
                                      variant="outlined"
                                      margin="normal"
                                    />
                                  )}
                                />
                              </div>
                            </FormControl>
                          </Grid>
                        </>
                      ) : talentData?.data.attributes.gender === 'Female' ||
                        talentData?.data.attributes.gender === 'Trans Woman' ? (
                        <Grid xs={12} md={6} lg={4} item>
                          <FormControl margin={'normal'} fullWidth>
                            <InputLabel id="labelCountry" shrink>
                              Hip Size
                            </InputLabel>
                            <div className={classes.autocompleteContainer}>
                              <Autocomplete
                                onChange={(e, newValue) => {
                                  form.setFieldValue('statistics.hip_size', newValue);
                                  form.handleChange(newValue);
                                }}
                                value={form.values.statistics?.hip_size}
                                options={hipSize.map((i) =>
                                  metricValue === 'AL' ? i.AL : metricValue === 'CM' ? i.CM : i.IN,
                                )}
                                getOptionSelected={(option, value) => option === value}
                                fullWidth
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    placeholder={'Select Hip Size'}
                                    variant="outlined"
                                    margin="normal"
                                  />
                                )}
                              />
                            </div>
                          </FormControl>
                        </Grid>
                      ) : (
                        <>
                          <Grid xs={12} md={6} lg={4} item>
                            <FormControl margin={'normal'} fullWidth>
                              <InputLabel id="labelCountry" shrink>
                                Inseam
                              </InputLabel>
                              <div className={classes.autocompleteContainer}>
                                <Autocomplete
                                  onChange={(e, newValue) => {
                                    form.setFieldValue('statistics.inside_leg', newValue);
                                    form.handleChange(newValue);
                                  }}
                                  value={form.values.statistics?.inside_leg}
                                  options={filteredWaistOptions}
                                  getOptionSelected={(option, value) => option === value}
                                  fullWidth
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      placeholder={'Select Inside Leg'}
                                      variant="outlined"
                                      margin="normal"
                                    />
                                  )}
                                />
                              </div>
                            </FormControl>
                          </Grid>
                          <Grid xs={12} md={6} lg={4} item>
                            <FormControl margin={'normal'} fullWidth>
                              <InputLabel id="labelCountry" shrink>
                                Outseam
                              </InputLabel>
                              <div className={classes.autocompleteContainer}>
                                <Autocomplete
                                  onChange={(e, newValue) => {
                                    form.setFieldValue('statistics.outside_leg', newValue);
                                    form.handleChange(newValue);
                                  }}
                                  value={form.values.statistics?.outside_leg}
                                  options={filteredWaistOptions}
                                  getOptionSelected={(option, value) => option === value}
                                  fullWidth
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      placeholder={'Select Outside Leg'}
                                      variant="outlined"
                                      margin="normal"
                                    />
                                  )}
                                />
                              </div>
                            </FormControl>
                          </Grid>
                          <Grid xs={12} md={6} lg={4} item>
                            <FormControl margin={'normal'} fullWidth>
                              <InputLabel id="labelCountry" shrink>
                                Neck Size
                              </InputLabel>
                              <div className={classes.autocompleteContainer}>
                                <Autocomplete
                                  onChange={(e, newValue) => {
                                    form.setFieldValue('statistics.neck_size', newValue);
                                    form.handleChange(newValue);
                                  }}
                                  value={form.values.statistics?.neck_size}
                                  options={[
                                    '1',
                                    '2',
                                    '3',
                                    '4',
                                    '5',
                                    '6',
                                    '7',
                                    '8',
                                    '9',
                                    '10',
                                    '11',
                                    '12',
                                    '13',
                                    '14',
                                    '15',
                                  ]}
                                  getOptionSelected={(option, value) => option === value}
                                  fullWidth
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      placeholder={'Select Neck Size'}
                                      variant="outlined"
                                      margin="normal"
                                    />
                                  )}
                                />
                              </div>
                            </FormControl>
                          </Grid>
                          <Grid xs={12} md={6} lg={4} item>
                            <FormControl margin={'normal'} fullWidth>
                              <InputLabel id="labelCountry" shrink>
                                Sleeve Size
                              </InputLabel>
                              <div className={classes.autocompleteContainer}>
                                <Autocomplete
                                  onChange={(e, newValue) => {
                                    form.setFieldValue('statistics.sleeves', newValue);
                                    form.handleChange(newValue);
                                  }}
                                  value={form.values.statistics?.sleeves}
                                  options={[
                                    '1',
                                    '2',
                                    '3',
                                    '4',
                                    '5',
                                    '6',
                                    '7',
                                    '8',
                                    '9',
                                    '10',
                                    '11',
                                    '12',
                                    '13',
                                    '14',
                                    '15',
                                  ]}
                                  getOptionSelected={(option, value) => option === value}
                                  fullWidth
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      placeholder={'Select Sleeve Size'}
                                      variant="outlined"
                                      margin="normal"
                                    />
                                  )}
                                />
                              </div>
                            </FormControl>
                          </Grid>
                          <Grid xs={12} md={6} lg={4} item>
                            <FormControl margin={'normal'} fullWidth>
                              <InputLabel id="labelCountry" shrink>
                                Hat Size
                              </InputLabel>
                              <div className={classes.autocompleteContainer}>
                                <Autocomplete
                                  onChange={(e, newValue) => {
                                    form.setFieldValue('statistics.hat', newValue);
                                    form.handleChange(newValue);
                                  }}
                                  value={form.values.statistics?.hat}
                                  options={[
                                    '1',
                                    '2',
                                    '3',
                                    '4',
                                    '5',
                                    '6',
                                    '7',
                                    '8',
                                    '9',
                                    '10',
                                    '11',
                                    '12',
                                    '13',
                                    '14',
                                    '15',
                                  ]}
                                  getOptionSelected={(option, value) => option === value}
                                  fullWidth
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      placeholder={'Select Hat Size'}
                                      variant="outlined"
                                      margin="normal"
                                    />
                                  )}
                                />
                              </div>
                            </FormControl>
                          </Grid>
                          <Grid xs={12} md={6} lg={4} item>
                            <FormControl margin={'normal'} fullWidth>
                              <InputLabel id="labelCountry" shrink>
                                Glove Size
                              </InputLabel>
                              <div className={classes.autocompleteContainer}>
                                <Autocomplete
                                  onChange={(e, newValue) => {
                                    form.setFieldValue('statistics.gloves', newValue);
                                    form.handleChange(newValue);
                                  }}
                                  value={form.values.statistics?.gloves}
                                  options={[
                                    '1',
                                    '2',
                                    '3',
                                    '4',
                                    '5',
                                    '6',
                                    '7',
                                    '8',
                                    '9',
                                    '10',
                                    '11',
                                    '12',
                                    '13',
                                    '14',
                                    '15',
                                  ]}
                                  getOptionSelected={(option, value) => option === value}
                                  fullWidth
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      placeholder={'Select Glove Size'}
                                      variant="outlined"
                                      margin="normal"
                                    />
                                  )}
                                />
                              </div>
                            </FormControl>
                          </Grid>
                          <Grid xs={12} md={6} lg={4} item>
                            <FormControl margin={'normal'} fullWidth>
                              <InputLabel id="labelCountry" shrink>
                                Hip Size
                              </InputLabel>
                              <div className={classes.autocompleteContainer}>
                                <Autocomplete
                                  onChange={(e, newValue) => {
                                    form.setFieldValue('statistics.hip_size', newValue);
                                    form.handleChange(newValue);
                                  }}
                                  value={form.values.statistics?.hip_size}
                                  options={hipSize.map((i) =>
                                    metricValue === 'AL' ? i.AL : metricValue === 'CM' ? i.CM : i.IN,
                                  )}
                                  getOptionSelected={(option, value) => option === value}
                                  fullWidth
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      placeholder={'Select Hip Size'}
                                      variant="outlined"
                                      margin="normal"
                                    />
                                  )}
                                />
                              </div>
                            </FormControl>
                          </Grid>
                        </>
                      )}
                      <Grid xs={12} md={6} lg={4} item>
                        <FormControl margin={'normal'} fullWidth>
                          <InputLabel id="labelCountry" shrink>
                            Shoe Size
                          </InputLabel>
                          <div className={classes.autocompleteContainer}>
                            <Autocomplete
                              onChange={(e, newValue) => {
                                form.setFieldValue('statistics.shoe_size', newValue);
                                form.handleChange(newValue);
                              }}
                              value={form.values.statistics?.shoe_size}
                              options={maleShoeSize.map((i) => i.value)}
                              getOptionSelected={(option, value) => option === value}
                              fullWidth
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder={'Select Shoe Size'}
                                  variant="outlined"
                                  margin="normal"
                                />
                              )}
                            />
                          </div>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                <Divider style={{ margin: '30px 0 40px 0px' }} />
                <Grid spacing={2} container>
                  <Grid xs={12} md={6} lg={6} item>
                    <InputLabel id="labelAgeType" shrink>
                      Ethnicity
                    </InputLabel>
                    <div className={classes.autocompleteContainer}>
                      <Autocomplete
                        multiple
                        options={ethnicityOptions}
                        getOptionLabel={(ethnicityOptions) => ethnicityOptions.name}
                        id="multiple-tags"
                        value={form.values.statistics?.ethnicity}
                        getOptionSelected={(option, value) => option.name === value.name}
                        onChange={(e, newValue) => {
                          form.setFieldValue('statistics.ethnicity', newValue);
                          form.handleChange(e);
                        }}
                        renderTags={() => null}
                        renderInput={(params) => (
                          <Grid className={classes.autoCompleteGrid}>
                            <Link onClick={handleOpenEthnicityDialog} style={{ color: '#3e8bff' }}>
                              View All
                            </Link>
                            <TextField
                              {...params}
                              variant="outlined"
                              onChange={form.handleChange}
                              placeholder="Ethnicities"
                            />
                          </Grid>
                        )}
                      />
                    </div>

                    <Grid className={classes.chipContainer}>
                      {form.values.statistics?.ethnicity
                        ? form.values.statistics?.ethnicity?.map((v) => (
                            <Chip
                              deleteIcon={<AiOutlineMinusCircle color={ColorGauge.FAIL} />}
                              style={{ backgroundColor: '#151b24', borderRadius: 5, padding: 5 }}
                              key={v.id}
                              label={v.name}
                              onDelete={() => {
                                const filteredEthnicity = form.values.statistics?.ethnicity?.filter((eth) => eth !== v);
                                form.setFieldValue('statistics.ethnicity', filteredEthnicity);
                              }}
                            />
                          ))
                        : ''}
                    </Grid>
                  </Grid>
                  <Grid xs={12} md={6} lg={6} item>
                    <InputLabel id="labelAgeType" shrink>
                      Other Talent Types
                    </InputLabel>
                    <div className={classes.autocompleteContainer}>
                      <Autocomplete
                        multiple
                        options={talentTypesOptions}
                        getOptionSelected={(option, value) => option.value === value.value}
                        getOptionLabel={(talentTypesOptions) => talentTypesOptions.value}
                        id="multiple-tags"
                        value={form.values.statistics?.other_talent_types}
                        onChange={(e, newValue) => {
                          form.setFieldValue('statistics.other_talent_types', newValue);
                          form.handleChange(e);
                        }}
                        renderTags={() => null}
                        renderInput={(params) => (
                          <Grid className={classes.autoCompleteGrid}>
                            <Link onClick={handleOpenTalentTypesDialog} style={{ color: '#3e8bff' }}>
                              View All
                            </Link>
                            <TextField
                              {...params}
                              variant="outlined"
                              onChange={form.handleChange}
                              placeholder="Other Talent Types"
                            />
                          </Grid>
                        )}
                      />
                    </div>
                    <Grid className={classes.chipContainer}>
                      {form.values.statistics?.other_talent_types?.map((v) => (
                        <Chip
                          deleteIcon={<AiOutlineMinusCircle color={ColorGauge.FAIL} />}
                          style={{ backgroundColor: '#151b24', borderRadius: 5, padding: 5 }}
                          key={v.id}
                          label={v.value}
                          onDelete={() => {
                            const filteredTalentType = form.values.statistics?.other_talent_types?.filter(
                              (tt) => tt !== v,
                            );
                            form.setFieldValue('statistics.other_talent_types', filteredTalentType);
                          }}
                        />
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <Box style={{ width: '100%', display: 'flex', padding: 10, alignItems: 'center' }}>
        {/* {!data.data && (
          <Typography style={{ color: '#e34343', marginLeft: 'auto' }}>Please confirm Represention and Role</Typography>
        )} */}
        <Button
          disabled={isUpdateLoading}
          onClick={() => form.handleSubmit()}
          style={{ width: 220, height: 40, backgroundColor: '#3e8bff', marginLeft: 'auto' }}
        >
          {isUpdateLoading ? (
            <>
              <CircularProgress color="inherit" style={{ width: 20, height: 20, marginRight: 5 }} /> Saving...
            </>
          ) : (
            'Next'
          )}
        </Button>
      </Box>
      <EthnicityDialog
        key={form.values.statistics?.ethnicity?.length}
        open={isEthnicityDialogOpen}
        onClose={handleCloseEthnicityDialog}
        selectedChips={form.values.statistics?.ethnicity}
        setSelectedChips={(field: any, value: any) => form.setFieldValue(field, value)}
      />
      <TalentTypesDialog
        key={form.values.statistics?.other_talent_types?.length}
        open={isTalentTypesDialogOpen}
        onClose={handleCloseTalentTypesDialog}
        selectedChips={form.values.statistics?.other_talent_types}
        setSelectedChips={(field: any, value: any) => form.setFieldValue(field, value)}
      />
      <Backdrop isLoading={isLoading} />
      {routerPrompt}
      <Backdrop isLoading={isLoading} />
    </>
  );
};

export default Personal;
