import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function BriefcaseIcon(props: SvgIconProps) {
  return (
    <SvgIcon width="23" height="20" viewBox="0 0 23 20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <defs>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="uifk4d5xsa">
          <stop stopColor="#3E8BFF" offset="0%" />
          <stop stopColor="#3E8BFF" offset="100%" />
        </linearGradient>
      </defs>
      <path
        d="M22.093 2.667H16.044V2c0-1.103-.897-2-2-2H8.711c-1.103 0-2 .897-2 2v.667H.667A.668.668 0 0 0 0 3.333V18c0 1.103.897 2 2 2h18.756c1.102 0 2-.897 2-2V3.345c-.026-.438-.295-.676-.663-.678zM8.044 2c0-.368.3-.667.667-.667h5.333c.368 0 .667.3.667.667v.667H8.044V2zm13.12 2-2.07 6.21a.666.666 0 0 1-.633.457h-3.75V10a.667.667 0 0 0-.667-.667H8.711a.667.667 0 0 0-.667.667v.667h-3.75a.666.666 0 0 1-.632-.456L1.592 4h19.572zm-7.786 6.667V12h-4v-1.333h4zM21.422 18c0 .368-.299.667-.666.667H2A.668.668 0 0 1 1.333 18V7.441l1.064 3.191A1.997 1.997 0 0 0 4.294 12h3.75v.667c0 .368.299.666.667.666h5.333a.667.667 0 0 0 .667-.666V12h3.75c.862 0 1.625-.55 1.898-1.368l1.063-3.19V18z"
        fill="url(#uifk4d5xsa)"
        fillRule="nonzero"
        opacity="1"
      />
    </SvgIcon>
  );
}

export default BriefcaseIcon;
