import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() =>
  createStyles({
    buttonCls: {
      marginTop: 25,
      height: '60px !important',
      borderRadius: 8,
      textTransform: 'unset',
      textDecoration: 'none!important',
      maxWidth: 390,
      fontSize: 16,
    },
    link: {
      textDecoration: 'none',
    },
    loginFooter: {
      marginTop: '15px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      '& *': {
        fontSize: '14px',
      },
      '& span': {
        color: '#6e767f',
      },
    },
    input: {
      maxWidth: 390,
      background: 'none',
      borderRadius: 8,
      fontWeight: 400,
      '& input': {
        fontSize: 14,
      },
      '& .MuiInputBase-root': {
        paddingLeft: 20,
        borderRadius: 8,
      },
    },
    startIcon: {
      fontSize: 20,
      marginRight: 5,
    },
    formContainer: {
      margin: '50px auto 0',
      maxWidth: 390,
    },
    helpText: {
      color: '#60718b',
      textAlign: 'left',
      maxWidth: 390,
    },

    errorMessageFix: {
      '& .MuiFormHelperText-root': {
        background: 'none',
        marginLeft: 0,
      },
    },
  }),
);
