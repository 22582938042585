import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function OutlinedUserIcon(props: SvgIconProps) {
  return (
    <SvgIcon width="40" height="40" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg" {...props}>
      <defs>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="whadbjewua">
          <stop stop-color="#3E8BFF" offset="0%" />
          <stop stop-color="#3E8BFF" offset="100%" />
        </linearGradient>
      </defs>
      <path
        d="M20 0c5.859 0 10.625 4.766 10.625 10.625 0 3.473-1.675 6.562-4.26 8.502.798.264 1.583.579 2.351.944a1.562 1.562 0 1 1-1.341 2.822 17.02 17.02 0 0 0-6.957-1.651 10.774 10.774 0 0 1-.832 0c-7.934.192-14.804 5.881-16.425 13.67-.102.487.019.986.33 1.369.181.222.584.594 1.25.594h19.243a1.562 1.562 0 1 1 0 3.125H4.742a4.717 4.717 0 0 1-3.675-1.748 4.745 4.745 0 0 1-.966-3.977 20.385 20.385 0 0 1 6.995-11.542 20.305 20.305 0 0 1 6.547-3.6 10.616 10.616 0 0 1-4.268-8.508C9.375 4.766 14.141 0 20 0zm12.578 25.156c.863 0 1.563.7 1.563 1.563v4.297h4.296a1.562 1.562 0 1 1 0 3.125h-4.296v4.297a1.562 1.562 0 1 1-3.125 0V34.14h-4.297a1.562 1.562 0 1 1 0-3.125h4.297v-4.297c0-.863.7-1.563 1.562-1.563zM20 3.125c-4.136 0-7.5 3.364-7.5 7.5 0 4.009 3.161 7.293 7.122 7.49a20.325 20.325 0 0 1 .76 0c3.958-.199 7.118-3.482 7.118-7.49 0-4.136-3.364-7.5-7.5-7.5z"
        fill="url(#whadbjewua)"
        fill-rule="nonzero"
      />
    </SvgIcon>
  );
}

export default OutlinedUserIcon;
