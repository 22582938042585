import { Box, Button, Grid, InputLabel, useMediaQuery, useTheme } from '@material-ui/core';

import {
  Backdrop,
  Checkbox,
  CheckBox,
  Select,
  SelectArrowDownIcon,
  TextInput,
  Typography,
} from '@room-match/shared-ui-components';
import { Stepper } from 'components';
import { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { ROUTES } from 'shared/constants/ROUTES';
import { IProfile } from 'shared/interfaces/IProfile';

import { useStyles } from './Complete.styles';

type Props = {
  info?: IProfile;
  setActiveStep(step: number): void;
  activeStep: number;
};

const Complete: React.FC<Props> = ({ info, setActiveStep, activeStep }) => {
  const classes = useStyles();
  const skills = 0;
  const credits = 0;
  const history = useHistory();

  const prev = () => {
    setActiveStep(activeStep - 1);
  };

  return (
    <>
      <Grid item className={classes.root}>
        <Grid item>
          <Box component="div" className={classes.imgContainer}>
            <img src={` ${info?.attributes.primary_image_url || '/assets/no-image.svg'}`} className={classes.img} />
          </Box>
          <Typography fontSize={18} style={{ marginBottom: 5 }}>
            {info?.attributes.talent.data.attributes.full_name}
          </Typography>
          <Typography color="primary">{info?.attributes.agency_name}</Typography>
        </Grid>
        <Grid item>
          <Grid item>
            <Typography fontSize={30} color="primary">
              Check-In Complete
            </Typography>
            <Typography fontSize={18}>
              Thank you for checking in, please wait to be called in for your audition.
            </Typography>
          </Grid>
          <Grid item className={classes.dataContainer}>
            <Typography color="primary" className={classes.dataHeading}>
              Current information appearing alongside your audition
            </Typography>
            <Grid item>
              <Typography fontSize={15}>Media</Typography>
              <Typography fontSize={15} className={classes.slashes}>
                {info?.attributes.images.data.length || 0 > 0 ? (
                  <Box component="span" className={classes.primary}>
                    {info?.attributes.images.data.length} Image
                  </Box>
                ) : (
                  <Box component="span" className={classes.yellow}>
                    0 Image
                  </Box>
                )}{' '}
                /{' '}
                {info?.attributes.videos.data.length || 0 > 0 ? (
                  <Box component="span" className={classes.primary}>
                    {info?.attributes.videos.data.length} Video
                  </Box>
                ) : (
                  <Box component="span" className={classes.yellow}>
                    0 Video
                  </Box>
                )}{' '}
                /{' '}
                {info?.attributes.audios.data.length || 0 > 0 ? (
                  <Box component="span" className={classes.primary}>
                    {info?.attributes.audios.data.length} Audio
                  </Box>
                ) : (
                  <Box component="span" className={classes.yellow}>
                    0 Audio
                  </Box>
                )}
              </Typography>
            </Grid>
            <Grid item>
              <Typography fontSize={15}>Resume</Typography>
              <Typography fontSize={15}>
                {credits > 0 ? (
                  <Box component="span" className={classes.primary}>
                    {credits} Credits
                  </Box>
                ) : (
                  <Box component="span" className={classes.yellow}>
                    0 Credits
                  </Box>
                )}
              </Typography>
            </Grid>
            <Grid item>
              <Typography fontSize={15}>Skills</Typography>
              <Typography fontSize={15}>
                {skills > 0 ? (
                  <Box component="span" className={classes.primary}>
                    {skills} Skills
                  </Box>
                ) : (
                  <Box component="span" className={classes.yellow}>
                    0 Skills
                  </Box>
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid item className={classes.btnContainer}>
            <Button
              variant="outlined"
              fullWidth
              className={classes.primaryBtn}
              onClick={() => history.push(`${ROUTES.TALENT.PROFILES}/${info?.id}`)}
            >
              Add Media
            </Button>
            <Button
              variant="outlined"
              fullWidth
              className={classes.secondaryBtn}
              onClick={() => history.push(`${ROUTES.TALENT.MYINFO}/skills`)}
            >
              Add Skills
            </Button>
            <Button
              variant="outlined"
              fullWidth
              className={classes.secondaryBtn}
              onClick={() => history.push(`${ROUTES.TALENT.MYINFO}/resume`)}
            >
              Add Resume
            </Button>
          </Grid>
          <Grid item className={classes.dashboard}>
            <Link to="dashboard" className={classes.link}>
              <Button variant="contained" color="primary" fullWidth>
                Go To Dashboard
              </Button>
            </Link>
            {/* <Button
              disableElevation
              fullWidth
              className={classes.clearButton}
              onClick={() => setActiveStep(activeStep - 1)}
            >
              Go Back
            </Button> */}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Complete;
