import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

const BackArrowIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 22 10" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M20.339 4.194H3.259l2.83-2.816A.806.806 0 1 0 4.951.235L.737 4.428v.001a.807.807 0 0 0 0 1.142l4.214 4.194a.806.806 0 0 0 1.138-1.143l-2.83-2.816h17.08a.806.806 0 1 0 0-1.612z"
        fill={props.fill}
        fill-rule="nonzero"
      />
    </SvgIcon>
  );
};

export default BackArrowIcon;
