import { Box, Grid, InputLabel } from '@material-ui/core';

import { Select, SelectArrowDownIcon, TextInput, Typography } from '@room-match/shared-ui-components';

import { useStyles } from './PersonalInformation.styles';

import Gender from 'data/Gender.json';
import Pronouns from 'data/Pronouns.json';
import { getErrorMessage } from '@room-match/shared-utils';
import { useState } from 'react';

type Props = {
  form: any;
  setFirstName(firstName: string): void;
  setLastName(lastName: string): void;
  validate: () => void;
};

const PersonalInformation: React.FC<Props> = ({ form, setFirstName, setLastName, validate }) => {
  const classes = useStyles();
  const [show, setShow] = useState(false);

  return (
    <Box component="div" className={classes.root}>
      <Grid item className={classes.header}>
        <Typography fontSize={24}>Personal Information</Typography>
      </Grid>
      <Grid item className={classes.form}>
        <Grid item style={{ marginBottom: 5 }}>
          <TextInput
            label={'Stage Name'}
            placeholder="Enter stage name…"
            name="stage_name"
            onBlur={(e) => {
              validate();
              form.handleBlur(e);
            }}
            value={form.values.stage_name}
            onChange={(e) => {
              if (form.errors.stage_name && !form.touched.stage_name) {
                form.setFieldTouched('stage_name');
                form.validateField('stage_name');
              }
              form.handleChange(e);
            }}
            errorMessage={getErrorMessage(form.touched.stage_name, form.errors.stage_name)}
          />
        </Grid>
        <Grid item style={{ marginBottom: 5 }}>
          <TextInput
            label={'Legal Name'}
            placeholder="First name…"
            name="first_name"
            value={form.values.first_name}
            onBlur={(e) => {
              validate();
              form.handleBlur(e);
            }}
            onChange={(e) => {
              setFirstName(e.target.value);
              if (form.errors.first_name && !form.touched.first_name) {
                form.setFieldTouched('first_name');
                form.validateField('first_name');
              }
              validate();
              form.handleChange(e);
            }}
            errorMessage={getErrorMessage(form.touched.first_name, form.errors.first_name)}
          />
          <TextInput
            className={classes.noLabelFix}
            placeholder="Middle name…"
            name="middle_name"
            onBlur={form.handleBlur}
            value={form.values.middle_name}
            onChange={form.handleChange}
          />
          <TextInput
            className={classes.noLabelFix}
            placeholder="Last name…"
            name="last_name"
            value={form.values.last_name}
            onBlur={(e) => {
              validate();
              form.handleBlur(e);
            }}
            onChange={(e) => {
              setLastName(e.target.value);
              if (form.errors.last_name && !form.touched.last_name) {
                form.setFieldTouched('last_name');
                form.validateField('last_name');
              }
              form.handleChange(e);
            }}
            errorMessage={getErrorMessage(form.touched.last_name, form.errors.last_name)}
          />
        </Grid>
        <Grid item>
          <InputLabel id="representationStatus">Gender / Identity</InputLabel>
          <Select
            variant="outlined"
            fullWidth
            data={Gender}
            value={form.values.gender}
            IconComponent={SelectArrowDownIcon}
            name="gender"
            onBlur={(e) => {
              validate();
              form.handleBlur(e);
            }}
            onChange={(e) => {
              if (form.errors.gender && !form.touched.gender) {
                form.setFieldTouched('gender');
                form.validateField('gender');
              }
              form.handleChange(e);
            }}
            placeHolder="Select Gender"
            errorMessage={getErrorMessage(form.touched.gender, form.errors.gender)}
          />
          <Select
            className={classes.selectNoLabelFix}
            variant="outlined"
            fullWidth
            data={Pronouns}
            onBlur={form.handleBlur}
            value={form.values.identifies_as}
            IconComponent={SelectArrowDownIcon}
            name="identifies_as"
            onChange={form.handleChange}
            placeHolder="Identifies As"
          />
        </Grid>
        <Grid item>
          <InputLabel id="representationStatus">Your SAG Status</InputLabel>
          <Select
            variant="outlined"
            fullWidth
            onBlur={form.handleBlur}
            data={[
              { key: 'NON MEMBER', value: 'NON MEMBER' },
              { key: 'SAG / AFTRA', value: 'SAG / AFTRA' },
              { key: 'SAG ELIGIBLE', value: 'SAG ELIGIBLE' },
              { key: 'SAG CORE', value: 'SAG CORE' },
            ]}
            value={form.values.sag_status}
            IconComponent={SelectArrowDownIcon}
            onChange={(e) => {
              if (e.target.value !== null) {
                setShow(true);
              }
              return form.handleChange(e);
            }}
            name="sag_status"
            placeHolder="Select"
          />
          {show ? (
            <TextInput
              className={classes.noLabelFix}
              placeholder="SAG ID name…"
              onBlur={form.handleBlur}
              name="sag_id"
              value={form.values.sag_id}
              onChange={form.handleChange}
            />
          ) : (
            ''
          )}
        </Grid>
        <Grid item>
          <InputLabel id="representationStatus">Select Other Unions You Belong To</InputLabel>
          <Select
            variant="outlined"
            fullWidth
            data={[
              { key: 'MEAA', value: 'MEAA' },
              { key: 'EQUITY', value: 'EQUITY' },
            ]}
            value={form.values.union}
            IconComponent={SelectArrowDownIcon}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            name="union"
            placeHolder="Select"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default PersonalInformation;
