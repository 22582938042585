import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function InvoiceIcon(props: SvgIconProps) {
  return (
    <SvgIcon width="19" height="22" viewBox="0 0 19 22" xmlns="http://www.w3.org/2000/svg" {...props}>
      <defs>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="bc9vj4kxia">
          <stop stopColor="#3E8BFF" offset="0%" />
          <stop stopColor="#3E8BFF" offset="100%" />
        </linearGradient>
      </defs>
      <path
        d="m16.233 0 .065.002c.977.04 1.746.828 1.78 1.788v16.187a.738.738 0 0 1-.407.713.458.458 0 0 1-.255.05.738.738 0 0 1-.443-.128l-.092-.075-1.553-1.4-.305.229v3.724a.764.764 0 0 1-.382.604.611.611 0 0 1-.698-.083l-.066-.07-1.528-1.4-1.859 1.655a.764.764 0 0 1-.927.07l-.091-.07-1.833-1.655-1.834 1.655a.815.815 0 0 1-.509.204.764.764 0 0 1-.421-.133l-.088-.07-1.859-1.656-1.553 1.4a.79.79 0 0 1-.866.153.891.891 0 0 1-.49-.598L0 20.982V4.915A2.215 2.215 0 0 1 2.007 2.81l.157-.01h.891v-.865c0-1.07.952-1.86 2.012-1.93L5.22 0h11.013zm-3.12 3.82H2.164a1.197 1.197 0 0 0-1.13.976l-.016.119V20.42l1.324-1.17a.84.84 0 0 1 .995-.097l.1.07 1.86 1.656 1.832-1.655a.815.815 0 0 1 .915-.071l.104.07 1.808 1.656 1.858-1.655a.713.713 0 0 1 .908-.05l.085.075 1.197 1.171V4.915c0-.56-.33-1.095-.891-1.095zm3.161-2.801H5.22c-.523 0-1.068.31-1.138.808l-.008.109v.866h9.039c1.07 0 1.837.904 1.905 1.961l.005.152v11.152c.463-.07.547-.033.711.127l.053.052 1.273 1.17V1.937a.84.84 0 0 0-.683-.904l-.103-.013zm-5.478 13.139a.51.51 0 0 1 0 1.018H4.48a.51.51 0 1 1 0-1.018zm0-3.31a.51.51 0 0 1 0 1.018H4.48a.51.51 0 1 1 0-1.018zM7.74 7.538a.51.51 0 0 1 .092 1.01l-.092.008H4.481a.51.51 0 0 1-.091-1.01l.091-.008h3.26z"
        fill="url(#bc9vj4kxia)"
        fillRule="nonzero"
        opacity="1"
      />
    </SvgIcon>
  );
}

export default InvoiceIcon;
