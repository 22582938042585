import React, { useState, useEffect } from 'react';
import { Box, Button, Grid, InputAdornment } from '@material-ui/core';
import { FrontLayout } from 'components';
import {
  Input,
  InputPassword,
  EmailIconTwoTone,
  PasswordIconTwoTone,
  Typography,
} from '@room-match/shared-ui-components';

import { useStyles } from './SendVerification.styles';
import { PasswordPrinciple, validatePassword } from '@room-match/shared-utils';
import { Link } from 'react-router-dom';

const SendVerification = () => {
  const classes = useStyles();
  const [count, setCount] = useState('(25)');
  const [disabled, setDisabled] = useState(true);
  const goCount = () => {
    setDisabled(true);
    let i = 25;
    const stop = 0;
    const increment = () => {
      i = i - 1;
      if (i <= stop) {
        setDisabled(false);
        setCount('');
        i = 25;
      } else {
        setCount(`(${i})`);
      }
    };
    const interval = setInterval(increment, 1000);
  };
  useEffect(() => {
    goCount();
  }, []);

  return (
    <FrontLayout
      heading="A verification link has been sent to your email address."
      containerWidth={800}
      type="sendVerification"
    >
      <Grid className={classes.bodyContainer}>
        <Grid item>
          <Typography fontSize={16}>
            Please click on the link inside the email to continue the process of registration.
          </Typography>
        </Grid>
        <Grid item style={{ marginTop: 20 }}>
          <Typography fontSize={16} className={classes.extraText}>
            Please also check the Spam folder on your email.
          </Typography>
        </Grid>
        <Grid item>
          <Button
            color="primary"
            variant="contained"
            disableElevation
            fullWidth
            className={classes.buttonCls}
            disabled={disabled}
            onClick={() => goCount()}
          >
            Re-Send Verification Email {count}
          </Button>
          <Link to="/registration" className={classes.link}>
            <Button
              color="primary"
              variant="contained"
              disableElevation
              fullWidth
              style={{ marginTop: 10 }}
              className={`${classes.buttonCls} ${classes.confirmButton}`}
            >
              Continue
            </Button>
          </Link>
        </Grid>
      </Grid>
    </FrontLayout>
  );
};

export default SendVerification;
