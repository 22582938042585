import { CONFIG } from 'shared/config';

export const ENDPOINTS = {
  USERS: `${CONFIG.AUTH_API_URL}/users`,
  REFRESH_TOKEN: `${CONFIG.AUTH_API_URL}/refresh_token`,
  RESET_PASSWORD: `${CONFIG.AUTH_API_URL}/reset_password`,
  CHANGE_PASSWORD: `${CONFIG.AUTH_API_URL}/change_password`,
  PROVIDER_AUTHENTICATION: `${CONFIG.AUTH_API_URL}/provider_authentication`,
  ME: `${CONFIG.TALENT_API_URL}/me`,
  PROFILE: `${CONFIG.TALENT_API_URL}/profiles`,
  AGENCY: `${CONFIG.TALENT_API_URL}/agencies`,
  MEDIA: `${CONFIG.UPLOAD_API_URL}/media`,
  PRESIGNED_URL: `${CONFIG.UPLOAD_API_URL}/presigned_url`,
  MEDIA_URLS: `${CONFIG.UPLOAD_API_URL}/media_urls`,
  TALENTS: `${CONFIG.TALENT_API_URL}/talents`,
  COMPANY: `${CONFIG.TALENT_API_URL}/company`,
  QUESTION: `${CONFIG.TALENT_API_URL}/questions`,
  CASTING_LOCATION: `${CONFIG.TALENT_API_URL}/casting_locations`,
  SCHEDULES_SESSIONS: `${CONFIG.TALENT_API_URL}/schedules_sessions`,
  PROJECT: `${CONFIG.TALENT_API_URL}/projects`,
  GRAPHQL: CONFIG.GRAPHQL_API_URL,
  UPLOAD: `${CONFIG.UPLOAD_API_URL}/media`,
  SESSION: `${CONFIG.TALENT_API_URL}/sessions`,
  AUDITION_SCHEDULES: `${CONFIG.TALENT_API_URL}/audition_schedules`,
};
