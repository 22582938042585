import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function SocialIcon(props: SvgIconProps) {
  return (
    <SvgIcon width="22" height="22" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg" {...props}>
      <defs>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="0iyiarpwia">
          <stop stopColor="#3E8BFF" offset="0%" />
          <stop stopColor="#3E8BFF" offset="100%" />
        </linearGradient>
      </defs>
      <path
        d="M19.63 17.26c-.42 0-.815.11-1.157.301l-2.682-2.682A6.138 6.138 0 0 0 17.167 11a6.138 6.138 0 0 0-1.376-3.88l1.49-1.489c.478.31 1.047.49 1.658.49A3.064 3.064 0 0 0 22 3.062 3.064 3.064 0 0 0 18.94 0a3.064 3.064 0 0 0-3.062 3.06c0 .612.181 1.181.49 1.66L14.88 6.21A6.138 6.138 0 0 0 11 4.832a6.138 6.138 0 0 0-3.88 1.376L4.439 3.527c.193-.342.303-.737.303-1.157A2.373 2.373 0 0 0 2.37 0 2.373 2.373 0 0 0 0 2.37a2.373 2.373 0 0 0 2.37 2.37c.42 0 .815-.11 1.157-.302l2.682 2.683A6.138 6.138 0 0 0 4.833 11c0 1.469.516 2.819 1.376 3.88l-1.49 1.489a3.042 3.042 0 0 0-1.658-.49A3.064 3.064 0 0 0 0 18.938 3.064 3.064 0 0 0 3.06 22a3.064 3.064 0 0 0 3.062-3.06c0-.612-.181-1.181-.491-1.66l1.49-1.49A6.138 6.138 0 0 0 11 17.168a6.138 6.138 0 0 0 3.88-1.376l2.682 2.682a2.355 2.355 0 0 0-.303 1.157A2.373 2.373 0 0 0 19.63 22 2.373 2.373 0 0 0 22 19.63a2.373 2.373 0 0 0-2.37-2.37zM11 11.735c-.787 0-1.427-.64-1.427-1.426 0-.787.64-1.427 1.427-1.427s1.427.64 1.427 1.427c0 .786-.64 1.426-1.427 1.426zm0 1.29c1.757 0 2.512 1.386 2.7 2.036a4.85 4.85 0 0 1-2.7.816 4.85 4.85 0 0 1-2.699-.816c.188-.655.95-2.037 2.7-2.037zm7.94-11.737c.976 0 1.77.795 1.77 1.772s-.794 1.772-1.77 1.772a1.774 1.774 0 0 1-1.773-1.772c0-.977.795-1.772 1.772-1.772zM1.29 2.37c0-.596.484-1.08 1.08-1.08a1.083 1.083 0 0 1 0 2.162A1.083 1.083 0 0 1 1.29 2.37zM11 6.122A4.884 4.884 0 0 1 15.878 11a4.856 4.856 0 0 1-1.149 3.141 4.104 4.104 0 0 0-1.798-1.924c.485-.49.785-1.164.785-1.907A2.719 2.719 0 0 0 11 7.594a2.719 2.719 0 0 0-2.716 2.716c0 .742.3 1.416.785 1.907a4.104 4.104 0 0 0-1.798 1.924A4.856 4.856 0 0 1 6.12 11 4.884 4.884 0 0 1 11 6.122zM3.06 20.71a1.774 1.774 0 0 1-1.77-1.772c0-.977.794-1.772 1.77-1.772.978 0 1.773.795 1.773 1.772s-.795 1.772-1.772 1.772zm16.57 0a1.083 1.083 0 0 1 0-2.163 1.083 1.083 0 0 1 0 2.163z"
        fill="url(#0iyiarpwia)"
        fillRule="nonzero"
      />
    </SvgIcon>
  );
}

export default SocialIcon;
