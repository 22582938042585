import React from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { AppBar, Toolbar, Typography } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { userLogout } from 'shared/redux/slicers/user.slicer';

import { BackButton, CustomIconButton, UserContainerButton, useStyles } from './Header.style';
import { ROUTES } from 'shared/constants/ROUTES';
import {
  BackArrowIcon,
  NotificationsAlertWhiteIcon,
  SettingsIconTwoTone,
  SignOutIconTwoTone,
} from '@room-match/shared-ui-components';
import { useAppSelector } from 'shared/redux/store';

type Props = {
  isDrawerCollapse?: boolean;
  handleDrawerToggle: () => void;
};

const Header: React.FC<Props> = ({ isDrawerCollapse, handleDrawerToggle }) => {
  const { user: userState, app: appState } = useAppSelector((state) => state);
  // const { user } = userState;
  const { headerTitle, showHeaderBack } = appState;
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { user } = userState;

  const handleLogOut = async () => {
    dispatch(userLogout());
    queryClient.clear();
    window.location.href = '/login';
  };

  const handleBack = () => {
    history.push(`/${location.pathname.split('/').slice(1, 3).join('/')}`);
  };

  return (
    <AppBar
      position="fixed"
      className={clsx(classes.appBar, {
        [classes.appBarShift]: !isDrawerCollapse,
      })}
    >
      <Toolbar style={{ minHeight: '60px', padding: '0px 10px 0px 0px' }}>
        <CustomIconButton
          aria-label="open drawer"
          onClick={handleDrawerToggle}
          edge="start"
          className={clsx(classes.menuButton, {})}
        >
          <MenuIcon />
        </CustomIconButton>
        {showHeaderBack && (
          <BackButton onClick={handleBack}>
            <BackArrowIcon />
          </BackButton>
        )}

        <Typography variant="body1" style={{ marginLeft: 15 }}>
          {headerTitle}
        </Typography>
        <div style={{ flexGrow: 1 }} />
        <div className={classes.sectionDesktop} style={{ minHeight: '60px' }}>
          <UserContainerButton onClick={() => history.push(ROUTES.TALENT.DASHBOARD)}>
            <Typography variant="body2">
              {user?.attributes.first_name} {user?.attributes.last_name}
              {/* David Lucas */}
            </Typography>
            <div />
          </UserContainerButton>
          <CustomIconButton>
            <NotificationsAlertWhiteIcon />
          </CustomIconButton>
          <CustomIconButton onClick={() => history.push(`${ROUTES.TALENT.SETTINGS}/contact_information`)}>
            <SettingsIconTwoTone />
          </CustomIconButton>
          <CustomIconButton
            edge="end"
            aria-label="account of current user"
            aria-controls="primary-search-account-menu"
            aria-haspopup="true"
            onClick={handleLogOut}
          >
            <SignOutIconTwoTone />
          </CustomIconButton>
        </div>
        <div className={classes.sectionMobile} style={{ minHeight: '60px' }}>
          <CustomIconButton
            aria-label="show more"
            aria-controls="primary-search-account-menu-mobile"
            aria-haspopup="true"
            onClick={handleLogOut}
          >
            <SignOutIconTwoTone />
          </CustomIconButton>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
